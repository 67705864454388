import { Injectable } from '@angular/core';
import { IncidentalAbsence } from '../models/incidental-absence';
import { IncidentalAbsenceReason } from '../models/incidental-absence-reason';
import { CalendarEvent } from 'src/app/shared/models/CalendarEvent';
import { AccessibleSupervisors } from '../models/AccessibleSupervisors';
import { Http } from 'src/app/shared/services/http.service';
import { IncidentalAbsenceSearch } from '../models/incidental-absence-search';

@Injectable({
  providedIn: 'root',
})
export class AbsenceService {
  constructor(private http: Http) {}

  async getMyAbsence() {
    return await this.http.get<IncidentalAbsence[]>(
      `/api/absence/GetIncidentalAbsence`
    );
  }

  removeDuplicates(absencelist: IncidentalAbsence[]) {
    let finalAbsenceList: IncidentalAbsence[] = [];
    absencelist.forEach((value, index) => {
      let isExists: boolean = false;
      finalAbsenceList.forEach((valinner, indinner) => {
        if (
          value.empUnum == valinner.empUnum &&
          value.absenceStartDate == valinner.absenceStartDate &&
          value.absenceEndDate == valinner.absenceEndDate &&
          value.absenceReason == valinner.absenceReason &&
          isExists == false
        ) {
          isExists = true;
        }
      });
      if (isExists == false) {
        finalAbsenceList.push(value);
        isExists = false;
      }
    });
    return [...finalAbsenceList];
  }

  async getAbsenceReasons() {
    return await this.http.get<IncidentalAbsenceReason[]>(
      '/api/absence/reasons'
    );
  }

  generateEventsFromAbsence(
    absencelist: IncidentalAbsence[],
    isManager: boolean
  ) {
    var anotherFinal = this.removeDuplicates(absencelist);

    var eventslst = anotherFinal.map((x) => {
      let eventItem: CalendarEvent = new CalendarEvent();

      eventItem.start = this.convertstringdatetoDate(x.absenceStartDate);
      eventItem.end = this.convertstringdatetoDate(
        new Date(x.absenceEndDate).setDate(
          new Date(x.absenceEndDate).getDate() + 1
        )
      );
      eventItem.allDay = true;
      eventItem.title = x.firstName + ' ' + x.lastName + '-' + x.absenceReason;
      eventItem.backgroundColor = '#007DBC';

      eventItem.extendedProps = x;
      return eventItem;
    });

    return eventslst;
  }

  convertstringdatetoDate(requestDate: any) {
    var tempDate = new Date(requestDate);
    return new Date(tempDate.toDateString());
  }

  getOffWorkAbsence(searchParameters: any) {
    return this.http.post<IncidentalAbsence[]>(
      `/api/absence/GetAbsenceOffWork`,
      searchParameters
    );
  }

  getEmployeeAbsences(EmpUnum: any) {
    return this.http.post<IncidentalAbsence[]>(
      `/api/absence/${EmpUnum}/employeeAbsenceDetail`,
      null
    );
  }

  async GetAccessibleSupervisors() {
    return await this.http.get<AccessibleSupervisors[]>(
      `/api/absence/GetAccessibleSupervisors`
    );
  }

  async getSearchAbsences(
    searchRequest: IncidentalAbsenceSearch
  ): Promise<any> {
    let path = `/api/manager/absences`;
    let response = await this.http.post<IncidentalAbsence[]>(
      path + '/search-absences',
      searchRequest
    );
    return this.mergeDateToString(response);
  }

  async editRemoveAbsence(editAbsence: IncidentalAbsence): Promise<any> {
    let path = `/api/manager/absences`;
    return await this.http.post<boolean>(path + '/edit-absence', editAbsence);
  }

  /*async removeAbsence(absence: IncidentalAbsence): Promise<any>{
    let path = `/api/manager/absences`;
    return await this.http.post<boolean>(path + '/edit-absence', absence);
  }*/

  mergeDateToString(arr: IncidentalAbsence[]) {
    arr.forEach((val, ind) => {
      if (val.absenceEndDate != null && val.absenceEndDate != undefined) {
        val.absenceEndDateStr =
          new Date(val.absenceEndDate).getMonth() +
          1 +
          '/' +
          new Date(val.absenceEndDate).getDate() +
          '/' +
          new Date(val.absenceEndDate).getFullYear();
      }
      if (val.absenceStartDate != null && val.absenceStartDate != undefined) {
        val.absenceStartDateStr =
          new Date(val.absenceStartDate).getMonth() +
          1 +
          '/' +
          new Date(val.absenceStartDate).getDate() +
          '/' +
          new Date(val.absenceStartDate).getFullYear();
      }
      if (val.estimatedRTWDate != null && val.estimatedRTWDate != undefined) {
        val.estimatedRTWDateStr =
          new Date(val.estimatedRTWDate).getMonth() +
          1 +
          '/' +
          new Date(val.estimatedRTWDate).getDate() +
          '/' +
          new Date(val.estimatedRTWDate).getFullYear();
      }
    });
    return arr;
  }
}
