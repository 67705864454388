import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  template: `
    <a routerLink="" class="tw-inline-block md:tw-hidden tw-font-bold">
      {{ label }}
    </a>

    <span class="tw-cursor-pointer tw-hidden md:tw-inline-block tw-pt-1.5">
      <mat-icon
        [style.width]="size + 'px'"
        [style.height]="size + 'px'"
        [style.font-size]="size + 'px'"
        [style.color]="color"
        >{{ icon }}</mat-icon
      >
    </span>
  `,
  styles: [``],
})
export class IconButtonComponent {
  @Input()
  icon: string;

  @Input()
  label: string;

  @Input()
  ariaLabelText: string = 'Actions Menu';

  /**
   * Size of icon in pixels
   */
  @Input()
  size: number = 26;

  @Input()
  color: string = 'var(--color-teal)';
}
