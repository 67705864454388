import { Component, Input } from '@angular/core';
import { BaseControlComponent } from './base-control.component';

@Component({
  selector: 'app-text-control',
  template: `
    <div class="app-pr1 app-pb2d">
      <mat-form-field
        [hintLabel]="hintLabel"
        [formGroup]="formGroup"
        class="fulltxtcnt"
      >
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          #input
          [attr.maxlength]="maxLength"
          [placeholder]="placeHolder"
          [formControlName]="formControlName"
          autocomplete="off"
          [mask]="mask"
          [type]="password ? 'password' : type"
          [min]="minTypeNumberValue"
          [max]="maxTypeNumberValue"
          (blur)="blur($event)"
        />
        @if(passwordToggle){
        <mat-icon
          matSuffix
          tabindex="0"
          (click)="password = !password"
          (keydown.enter)="password = !password"
          class="mat-datepicker-toggle"
          >{{ password ? 'visibility' : 'visibility_off' }}</mat-icon
        >
        }
        <mat-hint align="end" *ngIf="maxLength > 0 && IsShowLimit"
          >{{ input.value?.length || 0 }}/{{ maxLength }}</mat-hint
        >

        <mat-hint align="start" *ngIf="textHint">{{ textHint }}</mat-hint>

        <mat-error
          *ngIf="formControl.invalid"
          aria-atomic="true"
          aria-live="polite"
          [attr.aria-label]="label + ' ' + errorMessage"
          >{{ errorMessage }}</mat-error
        >
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      .fulltxtcnt {
        width: 100%;
      }
    `,
  ],
})
export class TextControlComponent extends BaseControlComponent {
  @Input()
  maxLength: number;

  @Input()
  mask: string;

  @Input()
  password: boolean = false;

  @Input()
  passwordToggle: boolean = false;

  @Input()
  type: string = 'text';

  @Input()
  textHint: string;

  @Input()
  IsShowLimit: boolean = true;

  @Input()
  minTypeNumberValue: number;

  @Input()
  maxTypeNumberValue: number;

  blur(event: any) {
    event.target.value = event.target.value?.trim();
  }
}
