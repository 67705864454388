import { AbstractControl, ValidationErrors } from '@angular/forms';

export function alphanumdashValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (/^[A-Za-z0-9-]*$/.test(value)) {
    return null;
  } else {
    return { alpha: 'Letters digits and dash only' };
  }
}
