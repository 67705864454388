import { A11yModule } from '@angular/cdk/a11y';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  withXsrfConfiguration,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {
  CalendarComponent,
  ErrorComponent,
  FormatPipe,
  OverflowDirective,
  OverlayContainerComponent,
} from '.';
import { ActionRowComponent } from './components/controls/action-row.component';
import { BreadcrumbItemComponent } from './components/controls/breadcrumb/breadcrumb-item.component';
import { BreadcrumbComponent } from './components/controls/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/controls/buttons/button.component';
import { IconButtonComponent } from './components/controls/buttons/icon-button.component';
import { PinButtonComponent } from './components/controls/buttons/pin-button.component';
import { DialogContainerComponent } from './components/controls/dialog/dialog-container.component';
import { DialogComponent } from './components/controls/dialog/dialog.component';
import { ExternalLinkDialogComponent } from './components/controls/dialog/external-link-dialog.component';
import { EmailComponent } from './components/controls/email.component';
import {
  AutocompleteControlComponent,
  DisplayLabelPipe,
} from './components/controls/forms/autocomplete-control.component';
import { BaseControlComponent } from './components/controls/forms/base-control.component';
import { ButtonToggleControlComponent } from './components/controls/forms/button-toggle-control.component';
import { CheckboxControlComponent } from './components/controls/forms/checkbox-control.component';
import { ContainerControlComponent } from './components/controls/forms/container-control.component';
import { DateControlComponent } from './components/controls/forms/date-control.component';
import { RadioButtonControlComponent } from './components/controls/forms/radio-button-control.component';
import { SelectControlComponent } from './components/controls/forms/select-control.component';
import { TextAreaControlComponent } from './components/controls/forms/text-area-control.component';
import { TextControlComponent } from './components/controls/forms/text-control.component';
import { ToggleControlComponent } from './components/controls/forms/toggle-control.component';
import { HttpErrorComponent } from './components/controls/http-error.component';
import { EmptyStateLabelComponent } from './components/controls/labels/empty-state-label.component';
import { LoadingLabelComponent } from './components/controls/labels/loading-label.component';
import { ListHeaderComponent } from './components/controls/list/list-header.component';
import { ListComponent } from './components/controls/list/list.component';
import { OverlayComponent } from './components/controls/overlay.component';
import { PanelComponent } from './components/controls/panel.component';
import { PhoneComponent } from './components/controls/phone.component';
import { LoadingPanelComponent } from './components/controls/progress/loading-panel.component';
import { ProgressDialogComponent } from './components/controls/progress/progress-dialog.component';
import { ProgressComponent } from './components/controls/progress/progress.component';
import { SectionComponent } from './components/controls/section.component';
import { StickyMenuComponent } from './components/controls/sticky-menu/sticky-menu.component';
import { TabGroupComponent } from './components/controls/tab-group/tab-group.component';
import { TabComponent } from './components/controls/tab-group/tab.component';
import { TimelineItemComponent } from './components/controls/timeline/timeline-item.component';
import { TimelineComponent } from './components/controls/timeline/timeline.component';
import { ToastComponent } from './components/controls/toast.component';
import { ValueComponent } from './components/controls/value.component';
import { AddressPipe } from './pipes/address.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { NameCasePipe } from './pipes/name-case.pipe';
import { NoDashPipe } from './pipes/no-dash.pipe';
import { NumberToWordPipe } from './pipes/number-to-word.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PipeToBulletPoints } from './pipes/pipetobulletpoints.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { TimeSincePipe } from './pipes/time-since.pipe';

@NgModule({
  declarations: [
    AutocompleteControlComponent,
    DisplayLabelPipe,
    ActionRowComponent,
    ButtonComponent,
    PinButtonComponent,
    DialogComponent,
    ExternalLinkDialogComponent,
    BaseControlComponent,
    CheckboxControlComponent,
    ContainerControlComponent,
    DateControlComponent,
    RadioButtonControlComponent,
    SelectControlComponent,
    TextAreaControlComponent,
    TextControlComponent,
    HttpErrorComponent,
    OverlayComponent,
    PanelComponent,
    ProgressDialogComponent,
    ProgressComponent,
    SectionComponent,
    ToastComponent,
    ValueComponent,
    AddressPipe,
    JoinPipe,
    PhonePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    NameCasePipe,
    TimelineItemComponent,
    TimelineComponent,
    TimeSincePipe,
    ListComponent,
    ListHeaderComponent,
    TabGroupComponent,
    TabComponent,
    FormatPipe,
    PipeToBulletPoints,
    IconButtonComponent,
    CalendarComponent,
    LoadingPanelComponent,
    EmptyStateLabelComponent,
    StickyMenuComponent,
    EmailComponent,
    PhoneComponent,
    SentenceCasePipe,
    ToggleControlComponent,
    ButtonToggleControlComponent,
    DialogContainerComponent,
    NoDashPipe,
    LoadingLabelComponent,
    OverlayContainerComponent,
    ErrorComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    OverflowDirective,
    NumberToWordPipe,
  ],
  exports: [
    A11yModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
    OverlayModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    AutocompleteControlComponent,
    DisplayLabelPipe,
    ActionRowComponent,
    ButtonComponent,
    PinButtonComponent,
    DialogComponent,
    BaseControlComponent,
    CheckboxControlComponent,
    ContainerControlComponent,
    DateControlComponent,
    RadioButtonControlComponent,
    SelectControlComponent,
    TextAreaControlComponent,
    TextControlComponent,
    HttpErrorComponent,
    OverlayComponent,
    PanelComponent,
    ProgressDialogComponent,
    ProgressComponent,
    SectionComponent,
    ToastComponent,
    ValueComponent,
    AddressPipe,
    JoinPipe,
    PhonePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    NameCasePipe,
    TimelineItemComponent,
    TimelineComponent,
    TimeSincePipe,
    ListComponent,
    ListHeaderComponent,
    TabGroupComponent,
    TabComponent,
    FormatPipe,
    PipeToBulletPoints,
    IconButtonComponent,
    FullCalendarModule,
    CalendarComponent,
    MatButtonToggleModule,
    LoadingPanelComponent,
    EmptyStateLabelComponent,
    StickyMenuComponent,
    EmailComponent,
    PhoneComponent,
    SentenceCasePipe,
    ToggleControlComponent,
    ButtonToggleControlComponent,
    CdkMenuModule,
    DialogContainerComponent,
    NoDashPipe,
    LoadingLabelComponent,
    OverlayContainerComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    OverflowDirective,
    NumberToWordPipe,
  ],
  schemas: [],
  imports: [
    A11yModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
    OverlayModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    FullCalendarModule,
    MatButtonToggleModule,
    CdkMenuModule,
  ],
  providers: [
    TitleCasePipe,
    provideNgxMask(),
    CookieService,
    provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({})),
  ],
})
export class SharedModule {}
