import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AbsenceService, IncidentalAbsence, Session } from '../..';
import { AbsencePageComponent } from '../absence/absence-page.component';

@Component({
  selector: 'app-my-absence',
  template: `
    <app-panel title="My Absences" [largeHeader]="true">
      <app-action-row class="tw-w-full" *ngIf="hasReportClaim">
        <app-button right1 emphasis="High" (click)="reportClaim()"
          >Submit an absence</app-button
        >
      </app-action-row>
      <div class="tw-w-full app-pt1">
        <app-loading-panel [state]="searching ? 'Loading' : 'Loaded'">
          <ng-template #content>
            <app-my-absence-grid
              [myAbsenceData]="absenceData"
            ></app-my-absence-grid>
          </ng-template>
        </app-loading-panel>
      </div>

      <ng-template #dialogReportNewClaim>
        <app-dialog
          title="Confirmation"
          ariaLabel="Confirmation dialog"
          [width]="700"
          [showActions]="false"
          [showHeader]="false"
          ariaLabelMessage="Close announcement popup"
        >
          <app-report-new-claim></app-report-new-claim>
        </app-dialog>
      </ng-template>
    </app-panel>
  `,
  styles: [],
})
export class MyAbsenceComponent implements OnInit {
  public abpagescom: AbsencePageComponent;
  searching: boolean = true;
  absenceData: IncidentalAbsence[] = [];

  hasReportClaim = false;

  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;

  constructor(
    private session: Session,
    public dialog: MatDialog,
    private absenceService: AbsenceService
  ) {}

  async ngOnInit() {
    this.searching = true;
    var listAbsenceOffWork = await this.absenceService.getMyAbsence();
    this.absenceData =
      this.absenceService.mergeDateToString(listAbsenceOffWork);
    //this.absenceData= await this.absenceService.getMyAbsence();
    this.searching = false;
    this.hasReportClaim =
      !this.session.user.hasIntake || this.session.user.hasIntake === undefined
        ? false
        : true;
  }

  reportClaim() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
    };
    this.dialog.open(this.dialogRefReportNewClaim, dialogConfig);
  }
}
