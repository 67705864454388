import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigurationService, LogService, Session } from 'src/app/my-sedgwick';
import { BaseComponent, Random } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { Announcements } from '../../models/announcements';
import { UserLogout } from '../../models/user-logout';
import { UserDetails } from '../../services/userdetails.service';

@Component({
  selector: 'app-preview-mode-header',
  template: `
    <div class="preview-mode">
      <div class="tw-block md:tw-hidden">
        <mat-toolbar class="header-xs" style="z-index:999">
          <img
            *ngIf="logoImage"
            src="data:image/JPEG;base64,{{ logoImage }}"
            height="62"
            alt="Logo"
          />
        </mat-toolbar>
      </div>

      <mat-toolbar
        color="primary"
        class="header fixtoolbar last-head-bar"
        [class.mat-elevation-z4]="scrolled"
        style="z-index:999"
      >
        <img
          *ngIf="logoImage"
          src="data:image/JPEG;base64,{{ logoImage }}"
          alt="mySedgwick Logo"
          class="tw-hidden md:tw-block"
        />
        <div class="" *ngIf="isAuditor">Client : {{ clientHeader }}</div>
        <app-preview-mode
          *ngIf="session.user.emulatorContext.role === 'AUDITOR'"
        ></app-preview-mode>
        <span class="example-spacer"></span>

        <mat-icon color="primary" class="persondisplay">person</mat-icon>
        <div class="app-pr3 app-pl1 welcome">
          {{ session.user.emulatorContext.firstName }}
        </div>
        <div *ngIf="showAccountSetting" class="md:tw-block">
          <button
            title="Home"
            aria-label="Home"
            mat-icon-button
            (click)="redirectToHome()"
          >
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="md:tw-block">
          <ng-container *ngIf="showSearch">
            <app-overlay [origin]="searchOverlayOrigin" #searchOverlay>
              <app-quick-search
                *ngIf="searchOverlay.opened"
                [showTitle]="false"
                class="overlay"
              ></app-quick-search>
            </app-overlay>
            <button
              title="Search"
              aria-label="Search Popup"
              mat-icon-button
              cdkOverlayOrigin
              #searchOverlayOrigin="cdkOverlayOrigin"
              (click)="searchOverlay.open()"
            >
              <mat-icon>search</mat-icon>
            </button>
          </ng-container>
        </div>
        <div *ngIf="showAccountSetting" class="md:tw-block">
          <button
            *ngIf="!isPreference"
            aria-label="Account settings"
            role="link"
            mat-icon-button
            (click)="this.auditorAccountSettings()"
            (keydown.enter)="this.auditorAccountSettings()"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <div class="md:tw-block">
          <button
            mat-button
            class="logout"
            aria-label="Logout and open the login page"
            (click)="logOut()"
            (keydown.enter)="logOut()"
          >
            <b>Log out</b>
          </button>
        </div>
      </mat-toolbar>
    </div>
  `,
  animations: [
    trigger('fadeIn', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('1s 1s', style({ opacity: 1 })),
      ]),
    ]),
  ],
  styles: [
    `
      .overlay h2 {
        padding-top: 20px;
      }

      .outlet {
        --max-width: 1280px;
      }
      .mat-mdc-toolbar.mat-mdc-primary {
        position: sticky;
        top: 0;
        z-index: 1;
        min-height: 64px;
      }

      .logo-image {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 20px;
      }

      @media (max-width: 760px) {
      }
      .example-spacer {
        flex: 1 1 auto;
      }

      .mat-mdc-toolbar {
        position: relative;
      }
      .overlay {
        width: 600px;
      }
      .header {
        background-color: #ffffff;
        color: #67707a;
        border-bottom: 1px solid #e0e0e0;
      }
      .header-xs {
        background-color: #ffffff;
      }

      .last-head-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .mat-toolbar-row,
      .mat-toolbar-single-row {
        min-height: 64px;
        height: auto !important;
      }

      @media only screen and (max-device-width: 480px) {
        .persondisplay {
          overflow: visible !important;
        }

        .persondisplay {
          overflow: visible !important;
        }

        .fixtoolbar {
          position: sticky;
          top: 0;
          z-index: 999999999;
          min-height: 64px;
        }
      }
      .preview-mode {
        z-index: 9999;
      }
    `,
  ],
})
export class PreviewModeHeaderComponent
  extends BaseComponent
  implements OnInit
{
  development = false;

  scrolled = false;
  isPreference = false;
  hasReportClaim = false;
  isIncidentalClient = false;
  isClaimant = false;
  isEmployee = false;
  showHistory = true;
  showWatchlist = true;
  showSearch = true;
  showMyClaims = false;
  showAccountSetting: boolean = true;
  showReportClaim: boolean = true;
  showHeaderIcons = false;
  isAuditor = false;

  clientHeader: string;

  @ViewChild('dialogAnnouncements') dialogRefAnnouncements: TemplateRef<any>;

  logoImage: string;
  userLogout: UserLogout;

  announcements: Announcements[];
  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public session: Session,
    public userdetails: UserDetails,
    public dialog: MatDialog,
    public random: Random,
    private config: ConfigurationService,
    public logService: LogService,
    private router: Router
  ) {
    super();
    this.development = !environment.production;
  }

  async ngOnInit() {
    !this.session.user ? await this.session.loadUser() : null;
    this.refreshValues();

    const dataLogo = await this.session.GetLogoDetails();
    if (dataLogo != undefined) {
      this.logoImage = dataLogo.clientLogoImg;
    }

    this.clientHeader =
      this.session.user.clientName + ' (' + this.session.user.contNum + ')';
  }

  async refreshValues() {
    this.isClaimant =
      this.session.user.role.toUpperCase() === 'HRM' &&
      (this.session.user.authenticationType === 'CLM' ||
        this.session.user.authenticationType === 'EMULATEE')
        ? true
        : false;
    this.isEmployee =
      this.session.user.role.toUpperCase() === 'HRM' &&
      (this.session.user.authenticationType === 'HRM' ||
        this.session.user.authenticationType === 'EMULATEE')
        ? true
        : false;
    this.isAuditor = this.session.user.role.toUpperCase() === 'AUDITOR';
    await this.hasIncidental();

    //setup visibility for nav menu and header items
    this.showMyClaims = this.isClaimant || this.isEmployee ? true : false;
    this.showHistory =
      this.isClaimant || this.isEmployee || this.isIncidentalClient
        ? false
        : true;
    this.showSearch = false;
    this.showWatchlist =
      this.isClaimant || this.isEmployee || this.isIncidentalClient
        ? false
        : true;
    this.hasReportClaim =
      !this.session.user.hasIntake || this.session.user.hasIntake === undefined
        ? false
        : true;
    this.showReportClaim =
      this.isClaimant ||
      this.isEmployee ||
      this.isIncidentalClient ||
      !this.hasReportClaim
        ? false
        : true;
    this.showHeaderIcons =
      !this.isIncidentalClient && !this.isClaimant && !this.isEmployee
        ? true
        : false;
  }

  tryClose(drawer: MatDrawer) {
    if (drawer?.mode === 'over') {
      drawer.close();
    }
  }

  async hasIncidental() {
    const features = await this.config.getAdditionalUserInformation();
    this.isIncidentalClient = this.config.getConfigFeatureAccess(
      'IncidentalAbsences5'
    );
    const isManagerForIncident = this.config.getConfigFeatureAccess(
      'ManagerTextingPreferences'
    );
    if (this.isIncidentalClient) {
      this.showAccountSetting = isManagerForIncident;
    }
  }

  async auditorAccountSettings() {
    if (this.session.isEmulating$) {
      await this.session.stopEmulation('/PreferencesPage');
    } else {
      this.router.navigate(['/']);
    }
  }

  async logOut() {
    await this.session.logoff();
  }
  async redirectToHome() {
    if (this.session.user.isEmulating) {
      await this.session.stopEmulation('/');
    } else {
      this.router.navigate(['/']);
    }
  }
}
