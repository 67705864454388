import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Session } from '../../services/session.service';

import { Router } from '@angular/router';
import {
  BaseComponent,
  ConfigurationSetting,
  MyClaim,
  TabGroupComponent,
  UserActionService,
} from 'src/app/shared';
import { ClaimInfo, ManagerResult } from 'src/app/shared/models/search-result';
import { DisplayService, UserRoleService } from '../..';
import { NotificationItem, scrollToElement } from '../../../shared';
import { ActivityRequest } from '../../../shared/models/activity';
import { UserActions } from '../../../shared/models/user';
import { AccommodationJobFunction } from '../../models/accommodation';
import { AccommodationsService } from '../../services/accommodations-service';
import { ActivityService } from '../../services/activity.service';
import { ConfigurationService } from '../../services/configuration.service';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-manager-dashboard',
  template: `
    <app-section *ngIf="!userRole.auditor">
      <app-claim-breadcrumb class="tw-w-[80%]"></app-claim-breadcrumb>
      <div class="tw-w-[20%] tw-right">
        <button
          mat-icon-button
          style="color: var(--color-input-grey);"
          alt="New Notifications"
          class="nopadding"
          role="button"
          aria-label="View new notification/s"
          tabindex="0"
          *ngIf="countLoaded"
          (click)="setNotificationFocus('Notifications')"
        >
          <mat-icon [matBadge]="notificationCount" matBadgeColor="accent"
            >notifications_none</mat-icon
          >
        </button>
      </div>
      <form
        [formGroup]="formGroup"
        class="tw-w-full tw-flex tw-flex-wrap tw-items-center tw-px-2 tw-pb-1"
      >
        <div class="tw-grow">
          <app-manager-selector
            formControlName="supervisor"
            [formGroup]="formGroup"
            label="Filter by manager"
            ngDefaultControl
            [managerLookupSupervisor]="true"
            [managerLookupOmni]="false"
            (supervisorSelected)="onSupervisorSelected($event)"
          >
          </app-manager-selector>
        </div>
        <div class="tw-font-bold">
          <app-toggle-control
            formControlName="includeIndirect"
            [formGroup]="formGroup"
            label="Include indirect reports"
            labelClass="tw-font-bold app-color-slate"
            ngDefaultControl
          >
          </app-toggle-control>
        </div>
      </form>
      <div
        class="tw-w-full"
        *ngIf="this.welcomeMessageInfo && this.welcomeMessageInfo.length > 0"
      >
        <app-welcome [welcomeMessageInfo]="welcomeMessageInfo"></app-welcome>
      </div>

      <app-panel
        class="tw-w-full"
        [showTitle]="true"
        [title]="headerLabel"
        [largeHeader]="true"
        [labelTemplate]="labelTemplate"
        [sentenceCase]="false"
        [canExpand]="false"
      >
        <ng-template #labelTemplate>
          <div
            class="tw-grow tw-items-center tw-justify-between tw-flex tw-flex-wrap tw-relative"
          >
            <div *ngIf="employeeId"></div>
            <div class="tw-w-[10%] ">
              <a
                tabindex="0"
                (keydown.enter)="redirectToMgrOwnEmpDashboard()"
                class="app-pl2 app-header-link"
                *ngIf="!employeeId && !session.isDelegating()"
                (click)="redirectToMgrOwnEmpDashboard()"
                >My claims</a
              >
            </div>

            <div
              class="tw-hidden md:tw-block app-pr2 tw-text-[14px] app-color-slate tw-font-bold tw-absolute tw-right-0"
            >
              {{ employeeLabel | titlecase }}
            </div>
            <div
              class="md:tw-hidden tw-block tw-w-[60%] app-pr2 app-pt3 tw-text-[14px] app-color-slate tw-font-bold tw-absolute tw-right-0"
            >
              {{ employeeLabel | titlecase }}
            </div>
          </div>
        </ng-template>

        <div class="tw-pt-4"></div>
        <app-loading-panel
          [state]="loaded ? 'Loaded' : 'Loading'"
          class="tw-h-full"
          loadingStyle="Image"
          loadingImageSrc="/assets/images/loader/manager-view-ghost.svg"
        >
          <ng-template #content>
            <div class="tw-min-h-[800px]">
              <app-manager-reminders
                [managerActivities]="managerActivities"
                [commCenterMsgCount]="unreadConversationMessagesCount"
                [accommodationsCount]="accommodationsCount"
                (focusTab)="handleFocus($event)"
                (filterApplied)="handleApplyFilter()"
                class="tw-w-full"
              ></app-manager-reminders>
              <app-activity
                id="mgrActivityPnl"
                [claimInfo$]="claimInfo$"
                [isHomePage]="true"
                [mgrAction]="mgrAction"
                [activities]="managerActivities"
                [isFilterApplied]="isFilterApplied"
                (clearFilter)="handleClearFilter()"
                [focusTab]="focusTab"
                [showTitle]="false"
                [flat]="true"
                [isBlueLabel]="true"
                [accommodationsData]="accommodationsJobfunctions"
              ></app-activity>
            </div>
          </ng-template>
        </app-loading-panel>
      </app-panel>
      <div class="tw-w-full md:tw-w-[60%] comfloat-left">
        <div class="tw-flex tw-flex-wrap">
          <app-comm-center2-card />
        </div>
      </div>
      <div
        class="tw-w-full md:tw-w-[40%] comfloat-right"
        *ngIf="hasLearningCenter"
      >
        <div class="tw-flex tw-flex-wrap">
          <div class="tw-w-full">
            <app-visit-learning-center
              class="tw-w-full"
              [originator]="'managerDashboard'"
            ></app-visit-learning-center>
          </div>
        </div>
      </div>
    </app-section>
  `,
  styles: [
    `
      .nopadding {
        padding-left: 0px !important;
        width: 50px !important;
      }
      .tw-right {
        text-align: right;
      }
    `,
  ],
})
export class ManagerDashboardComponent extends BaseComponent implements OnInit {
  @ViewChild(TabGroupComponent) tabGroup: TabGroupComponent;
  userActions = UserActions;
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  headerLabel: string = 'Manager view';
  welcomeMessageInfo: any;
  summary: any;
  employeeId: string;
  employeeLabel: string;
  managerActivities: any;
  loaded: boolean = false;
  unreadConversationMessagesCount: number = 0;
  accommodationsCount: number = 0;
  maxBadgeCount = 99;
  hasLearningCenter: boolean = false;
  initialNotifications: NotificationItem[] = [];
  countLoaded: boolean = true;
  notificationCount: number = 0;
  activityRequest: ActivityRequest;
  isFilterApplied = false;
  mgrAction: UserActions | null;
  includeIndirectsInSession: string;
  filteredManagerInSession: any;
  claims: MyClaim[];

  @Output()
  focusTab = new EventEmitter<string>();

  @Output()
  valueChanged = new EventEmitter<boolean>();
  ClientLevelConfig: ConfigurationSetting;

  accommodationsJobfunctions: AccommodationJobFunction[] = [];

  formGroup = this.fb.group({
    supervisor: [],
    includeIndirect: [false],
  });

  hasSummary = true;

  @Output() reloadCommCenter = new EventEmitter<{
    supervisor: ManagerResult;
    includeIndirect: boolean;
  }>();

  constructor(
    public configurationService: ConfigurationService,
    public session: Session,
    private fb: FormBuilder,
    private activityService: ActivityService,
    public config: ConfigurationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private userAction: UserActionService,
    public userRole: UserRoleService,
    public accommodationsService: AccommodationsService,
    private display: DisplayService
  ) {
    super();
    this.subs.sink = this.activityService.notificationCount.subscribe(
      (count: number) => {
        this.notificationCount = count > this.maxBadgeCount ? 99 : count;
        this.countLoaded = true;
        this.cd.detectChanges();
      }
    );
  }

  async ngOnInit() {
    this.mgrAction = UserActions.ViewOwnMgrDb;
    sessionStorage.setItem('highlightSideNav', 'false');
    if (!this.checkSessionStorage()) {
      this.setOwnLabel();
    }

    !this.session.user ? await this.session.loadUser() : null;
    await this.userAction.setAction(this.mgrAction);
    this.ClientLevelConfig =
      await this.configurationService.getAdditionalUserInformation();

    this.hasLearningCenter = true; // is not feature driven
    this.welcomeMessageInfo = await this.session.GetWelcomeMessage();
    if (!this.userRole.auditor) {
      await this.fetchActivities(
        this.formGroup.controls.includeIndirect.value,
        this.formGroup.controls.supervisor.value
          ? this.formGroup.controls.supervisor.value.empUnum
          : null,
        this.filteredManagerInSession
          ? UserActions.FilterByManager
          : this.mgrAction
      );

      this.subs.sink =
        this.formGroup.controls.includeIndirect.valueChanges.subscribe(
          async (value: boolean) => {
            this.loaded = false;
            sessionStorage.setItem(
              this.session.user.sessionId + 'includeIndirects',
              value.toString()
            );

            this.reloadCommCenter.emit({
              supervisor: this.formGroup.controls.supervisor.value,
              includeIndirect: value,
            });
            await this.fetchActivities(
              value,
              this.formGroup.controls.supervisor.value
                ? this.formGroup.controls.supervisor.value.empUnum
                : null,
              UserActions.FilterByManager
            );
            // MOVED BELOW CODE TO A FEW LINES ABOVE SO VALUE WILL BE SET BEFORE RELOADING NEW DATA
            /*sessionStorage.setItem(
            this.session.user.sessionId + 'includeIndirects',
            value.toString()
          );*/
          }
        );

      this.subs.sink =
        this.formGroup.controls.supervisor.valueChanges.subscribe(
          (value: {
            employeeId: string;
            firstName: any;
            lastName: any;
            name: any;
            empUnum: any;
          }) => {}
        );

      this.initialNotifications = this.managerActivities.notifications;
      this.subs.sink = this.activityService.focusNotification.subscribe(
        (x: string) => {
          this.handleFocus(x);
        }
      );
    }

    // this.claims = await (<any>this.claimService.getClaims());
  }

  async onSupervisorSelected(supervisor: ManagerResult) {
    if (supervisor && supervisor.empUnum != null) {
      this.reloadCommCenter.emit({
        supervisor: this.formGroup.controls.supervisor.value
          ? this.formGroup.controls.supervisor.value
          : null,
        includeIndirect: this.formGroup.controls.includeIndirect.value,
      });
      sessionStorage.setItem(
        this.session.user.sessionId + 'filteredManager',
        JSON.stringify(supervisor)
      );
      this.headerLabel = `${supervisor.name}  - manager view`;
      this.employeeLabel = `${supervisor.name} - ${supervisor.employeeId}`;
      this.employeeId = supervisor.employeeId;
      this.userAction.setBustCache(true);
      await this.fetchActivities(
        this.formGroup.controls.includeIndirect.value,
        supervisor.empUnum,
        UserActions.FilterByManager
      );
      srSpeak('Results filtered by ' + supervisor.name, 'polite');
    } else if (supervisor === null) {
      this.removeFromSessionStorage(
        this.session.user.sessionId + 'filteredManager'
      );
      this.reloadCommCenter.emit({
        supervisor: null,
        includeIndirect: this.formGroup.controls.includeIndirect.value,
      });
      this.userAction.setBustCache(true);
      this.setOwnLabel();

      await this.fetchActivities(
        this.formGroup.controls.includeIndirect.value,
        null,
        UserActions.ViewOwnMgrDb
      );
      srSpeak('Your own manager dashboard loaded', 'polite');
    }
  }

  unreadMessagesWithMax = (): number => {
    return this.unreadConversationMessagesCount > 99
      ? this.maxBadgeCount
      : this.unreadConversationMessagesCount;
  };

  async fetchActivities(
    inclIndirects: boolean,
    mgrEmpUnum: string,
    userAction: UserActions
  ) {
    this.loaded = false;
    const path = '/api/manager/get-activities';
    this.activityRequest = {
      includeIndirects: inclIndirects,
      userActions: userAction,
      claimKeys: [],
      userOwns: false,
      empUnum: mgrEmpUnum,
      delegateeEmpUnum: this.session.isDelegating()
        ? this.session.user.delegateeContext?.empUnum
        : null,
    };
    try {
      const [activities] = await Promise.all([
        this.activityService.getActivities(path, this.activityRequest),
        this.fetchAccommodations(inclIndirects, mgrEmpUnum, userAction),
      ]);

      this.managerActivities = activities;
    } catch (error) {
      console.error('Error getting activities:', error);
    } finally {
      this.notificationCount = this.managerActivities.notifications.length;
      this.loaded = true;
      this.userAction.setBustCache(false); //revert after the getActivities call which might be set after dismissing notifications from subpage
    }
  }

  async fetchAccommodations(
    inclIndirects: boolean,
    mgrEmpUnum: string,
    userAction: UserActions
  ) {
    if (!this.display.accommodations) {
      this.accommodationsJobfunctions = [];
      this.accommodationsCount = 0;
      return Promise.resolve();
    }

    this.accommodationsJobfunctions =
      await this.accommodationsService.getManagerAccommodations(
        inclIndirects,
        userAction,
        false,
        mgrEmpUnum
      );

    this.accommodationsCount = this.accommodationsJobfunctions.filter(
      (x) =>
        x.status === 'Open' &&
        x.requestSubStatusTypeId === '24' &&
        (x.decisionId === '1' || x.jobFunctionStatusId === '1')
    ).length;
  }

  handleFocus(tabName: string) {
    this.focusTab.emit(tabName);
  }

  handleApplyFilter() {
    this.isFilterApplied = true;
    this.loaded = true;
  }

  handleClearFilter() {
    this.isFilterApplied = false;
    this.loaded = true;
  }

  unreadMessageCountCallback = (evt: any) => {
    const cnt = evt as number;
    this.unreadConversationMessagesCount = cnt;
  };

  setNotificationFocus(type: any) {
    const notification = document.getElementById('activitypnl');
    if (notification != null) {
      this.activityService.setFocusToNotification(type);
      scrollToElement('activitypnl', false, 65);
    }
  }

  setCommCenterFocus() {
    const commcenter = document.getElementById('commCenterPnl');
    if (commcenter != null) {
      commcenter.scrollIntoView();
    }
  }

  redirectToMgrOwnEmpDashboard() {
    this.userAction.setAction(UserActions.ViewOwnEmpDb);
    this.router.navigate(['dashboard'], {});
  }

  checkSessionStorage(): boolean {
    let filteredManagerInSession = false;
    this.includeIndirectsInSession = sessionStorage.getItem(
      this.session.user.sessionId + 'includeIndirects'
    );
    this.filteredManagerInSession = sessionStorage.getItem(
      this.session.user.sessionId + 'filteredManager'
    );
    if (this.includeIndirectsInSession) {
      this.formGroup.controls.includeIndirect.setValue(
        this.includeIndirectsInSession === 'true'
      );
    } else {
      this.formGroup.controls.includeIndirect.setValue(false);
    }
    if (this.filteredManagerInSession) {
      this.formGroup.controls.supervisor.setValue(
        JSON.parse(this.filteredManagerInSession)
      );
      this.setFilteredManagerLabel();
      filteredManagerInSession = true;
    }
    return filteredManagerInSession;
  }

  async removeFromSessionStorage(itemToRemove: any) {
    sessionStorage.removeItem(itemToRemove);
  }

  setOwnLabel() {
    this.employeeLabel =
      this.session.user?.firstName +
      ' ' +
      this.session.user?.lastName +
      ' - ' +
      this.session.user?.employeeId;
    this.employeeId = null;
    this.headerLabel = 'Manager view';
  }

  setFilteredManagerLabel() {
    this.employeeLabel = this.formGroup.controls.supervisor.value.name;
    ' - ' + this.formGroup.controls.supervisor.value.employeeId;
    this.employeeId = this.formGroup.controls.supervisor.value.employeeId;
    this.headerLabel =
      this.formGroup.controls.supervisor.value.name + ' - manager view';
  }
}
