// NOTE THIS FILE IS NOT YET READY

import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MyClaim, getUrlParams } from 'src/app/shared';
import { UserActions } from '../../../shared/models/user';
import { UserActionService } from '../../../shared/services/user-action.service';
import { DisplayService } from '../../services/display-service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { ConfigurationService } from '../../services/configuration.service';
@Component({
  selector: 'app-sidenav',
  template: `
    <div class="app-pt3 app-pb2"></div>

    <mat-nav-list *ngIf="!hasEulaAgreement">
      <a
        mat-list-item
        role="button"
        tabindex="0"
        class="app-mat-list-item"
        *ngIf="
          !hasEulaAgreement &&
          display.reportClaimSignal() &&
          !isIncidentalClient
        "
        routerLinkActive="active-link"
        (click)="reportClaim(drawer)"
        (keydown.enter)="reportClaim(drawer)"
        [routerLinkActiveOptions]="{ exact: true }"
        ><span class="app-mat-list-item-span">Report a new claim</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="
          !hasEulaAgreement &&
          display.reportClaimSignal() &&
          !isIncidentalClient
        "
      ></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="session.user.roles.controller"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/controller"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="tryClose(drawer)"
        ><span class="app-mat-list-item-span">Controller View</span></a
      >
      <mat-divider
        *ngIf="session.user.roles.controller"
        aria-hidden="true"
      ></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="
          (session.user.roles.omni || session.user.roles.overseer) &&
          !isIncidentalClient
        "
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/summary"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="tryClose(drawer)"
        ><span class="app-mat-list-item-span">Summary View</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="session.user.roles.omni || session.user.roles.overseer"
      ></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="this.session.user.roles.supervisor && !isIncidentalClient"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/supervisor"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="redirectToOwnMgrDashboard(); tryClose(drawer)"
        ><span class="app-mat-list-item-span">Manager View</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="this.session.user.roles.supervisor && !isIncidentalClient"
      ></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="isManager && !isIncidentalClient && !session.isDelegating()"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/dashboard"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="redirectToMgrOwnEmpDashboard(); tryClose(drawer)"
        ><span class="app-mat-list-item-span">My claims</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="isManager && !isIncidentalClient && !session.isDelegating()"
      ></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="!isManager && !isIncidentalClient && !session.isDelegating()"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/dashboard"
        (click)="tryClose(drawer)"
        [routerLinkActiveOptions]="{ exact: true }"
        ><span class="app-mat-list-item-span">My claims</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="!isManager && !isIncidentalClient && !session.isDelegating()"
      ></mat-divider>

      <a
        mat-list-item
        *ngIf="IsMyWorkSchedule && !session.isDelegating()"
        class="app-mat-list-item"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/my-work-schedule"
        (click)="tryClose(drawer)"
        [routerLinkActiveOptions]="{ exact: true }"
        ><span class="app-mat-list-item-span">My Work Schedule</span></a
      >
      <mat-divider aria-hidden="true" *ngIf="IsMyWorkSchedule"></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="isIncidentalClient"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/absence-dashboard"
        (click)="tryClose(drawer)"
        [routerLinkActiveOptions]="{ exact: true }"
        ><span class="app-mat-list-item-span">Absences</span></a
      >

      <mat-divider *ngIf="isIncidentalClient" aria-hidden="true"></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/communication-center"
        (click)="tryClose(drawer)"
        title="Communication center"
        *ngIf="!isIncidentalClient"
        ><span class="app-mat-list-item-span">communication center</span></a
      >
      <mat-divider aria-hidden="true" *ngIf="!isIncidentalClient"></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="showSearch"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/search"
        (click)="tryClose(drawer)"
        ><span class="app-mat-list-item-span">Claim Search</span></a
      >

      <mat-divider aria-hidden="true" *ngIf="showSearch"></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="showWatchlist && !session.isDelegating()"
        routerLink="/watchlist"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        (click)="tryClose(drawer)"
      >
        <span class="app-mat-list-item-span">Watch List</span>
      </a>
      <mat-divider
        aria-hidden="true"
        *ngIf="showWatchlist && !session.isDelegating()"
      ></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="showHistory && !session.isDelegating()"
        routerLink="/history"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        (click)="tryClose(drawer)"
      >
        <span class="app-mat-list-item-span">History</span>
      </a>
      <mat-divider
        aria-hidden="true"
        *ngIf="showHistory && !session.isDelegating()"
      ></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="!isIncidentalClient"
        routerLink="/help"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        (click)="redirectToLearningCenter(); tryClose(drawer)"
        ><span class="app-mat-list-item-span">Helpful Resources</span></a
      >
      <mat-divider aria-hidden="true" *ngIf="!isIncidentalClient"></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="!isManager"
        (click)="tryClose(drawer)"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="design"
        ><span class="app-mat-list-item-span">Design</span></a
      >
      <mat-divider aria-hidden="true" *ngIf="!isManager"></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="actAsDelegate && !session.isDelegating()"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/act-as-delegate"
        (click)="tryClose(drawer)"
        ><span class="app-mat-list-item-span">Act as delegate</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="actAsDelegate && !session.isDelegating()"
      ></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="
          (manageDelegates || manageOthersDelegators) && !session.isDelegating()
        "
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/manage-delegates"
        (click)="tryClose(drawer)"
        ><span class="app-mat-list-item-span">Manage delegates</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="
          (manageDelegates || manageOthersDelegators) && !session.isDelegating()
        "
      ></mat-divider>
      <a
        mat-list-item
        class="app-mat-list-item"
        *ngIf="showAccountSetting && !session.isDelegating()"
        routerLinkActive
        #rla="routerLinkActive"
        [class.active-link]="rla.isActive"
        [attr.aria-current]="rla.isActive ? 'page' : null"
        [style.text-decoration]="rla.isActive ? 'underline' : null"
        routerLink="/PreferencesPage"
        (click)="tryClose(drawer)"
        role="link"
        ><span class="app-mat-list-item-span">Account Settings</span></a
      >
      <mat-divider
        aria-hidden="true"
        *ngIf="showAccountSetting && !session.isDelegating()"
      ></mat-divider>

      <a
        mat-list-item
        class="app-mat-list-item"
        [routerLink]="[]"
        (click)="logOut()"
        routerLink=""
      >
        <span class="app-mat-list-item-span">Log Out</span>
      </a>
    </mat-nav-list>

    <ng-template #dialogReportNewClaim>
      <app-dialog
        title="Confirmation"
        ariaLabel="Confirmation dialog"
        [width]="700"
        [showActions]="false"
        [showHeader]="false"
        ariaLabelMessage="Close announcement popup"
      >
        <app-report-new-claim></app-report-new-claim>
      </app-dialog>
    </ng-template>
    <ng-template #dialogReportNewClaimList>
      <app-dialog
        title="Confirmation"
        ariaLabel="Confirmation dialog"
        [width]="700"
        [showActions]="false"
        [showHeader]="false"
        ariaLabelMessage="Close announcement popup"
      >
        <app-claim-list-report-absence
          [claims]="this.myClaims"
          [showContinue]="true"
          [redirectToSmartly]="true"
        ></app-claim-list-report-absence>
      </app-dialog>
    </ng-template>
    <ng-template #dialogLogoutConfirmation>
      <app-dialog
        [showHeader]="false"
        ariaLabel="Logout confirmation dialog"
        [width]="700"
        [showActions]="false"
        ariaLabelMessage="Close logout confirmation popup"
      >
        <app-end-claimant-preview></app-end-claimant-preview>
      </app-dialog>
    </ng-template>
  `,
  styles: [
    `
      mat-divider {
        border-top-color: #55b1e8 !important;
        margin-left: 20px;
        margin-right: 20px;
      }

      .app-mat-list-item {
        text-align: center;
      }

      .active-link {
        background-color: var(--color-teal);
      }
      .hideSideNav {
        background-color: red;
      }
    `,
  ],
})
export class SidenavComponent implements OnInit {
  @Input()
  drawer: MatDrawer;

  @Input()
  hasEulaAgreement: any;

  @Input()
  hasReportClaim: any;

  @Input()
  isIncidentalClient: any;

  @Input()
  isManager: any;

  @Input()
  showSearch: any;

  @Input()
  showWatchlist: any;

  @Input()
  showHistory: any;

  @Input()
  actAsDelegate: any;

  @Input()
  manageDelegates: any;

  @Input()
  manageOthersDelegators: any;

  @Input()
  showAccountSetting: any;

  @Input()
  myClaims: MyClaim[];

  @Input()
  isSmall: any;

  @Input()
  isAuditor: any;

  mgrAction: UserActions | null;
  @Input()
  IsMyWorkSchedule: boolean;

  @ViewChild('dialogReportNewClaim')
  dialogRefReportNewClaim: TemplateRef<any>;
  @ViewChild('dialogReportNewClaimList')
  dialogRefReportNewClaimList: TemplateRef<any>;
  @ViewChild('dialogLogoutConfirmation')
  dialogRefLogoutConfirmation: TemplateRef<any>;
  isOwnEmpDb = false;

  constructor(
    public session: Session,
    public dialog: MatDialog,
    public router: Router,
    private userAction: UserActionService,
    public userRole: UserRoleService,
    public display: DisplayService,
    public claimConfigService: ConfigurationService
  ) {}

  ngOnInit() {
    const params = getUrlParams();
    if (!params.emp) {
      this.isOwnEmpDb = true;
    }
  }

  navigateMyClaims() {
    if (this.isManager) {
      this.router.navigate(['/managerdashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  tryClose(drawer: MatDrawer) {
    if (drawer?.mode === 'over') {
      drawer.close();
    }
  }

  reportClaim(drawer: MatDrawer) {
    if (this.userRole.manager) {
      this.display.mgrAction = this.userAction.getAction();
    }
    if (
      ((this.userRole.manager &&
        (this.display.mgrAction == UserActions.ViewOwnEmpDb ||
          this.display.mgrAction == UserActions.ManagerSummaryView)) ||
        !this.userRole.manager) &&
      this.showReportAbsence() &&
      !this.claimConfigService.getConfigFeatureAccess('leavelinksso')
    ) {
      this.dialog.open(this.dialogRefReportNewClaimList, {
        panelClass: 'claimListDialog',
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Confirmation',
      };
      const dlg = this.dialog.open(this.dialogRefReportNewClaim, dialogConfig);
      // this one already has padding
      dlg.updateSize('700px', '380px');
      this.tryClose(drawer);
    }
  }

  redirectToLearningCenter() {
    this.userAction.setAction(UserActions.ViewOwnMgrDb);
    this.router.navigate(['help'], {
      queryParams: {
        focus: 'learningcenter',
        origin: 'leftNav',
      },
    });
  }

  redirectToMgrOwnEmpDashboard() {
    this.userAction.setAction(UserActions.ViewOwnEmpDb);
    if (this.userRole.manager) {
      // this.router.navigate(['dashboard'], {}).then(() => {
      // Commenting out for now. Reach out to SB if questions.
      //  window.location.reload();
      //});
      this.router
        .navigateByUrl('/history', { skipLocationChange: true })
        .then(() => {
          //this.router.navigate(['dashboard'], {});
          this.router.navigate(['dashboard'], {});
        });
    } else {
      this.router.navigate(['dashboard'], {});
    }
  }

  redirectToOwnMgrDashboard() {
    this.userAction.setAction(UserActions.ViewOwnMgrDb);
    this.router.navigate(['supervisor'], {});
  }
  showReportAbsence() {
    if (!this.myClaims) {
      return false;
    }
    return (
      this.myClaims.findIndex(
        (claim) =>
          claim.status === 'Open' &&
          claim.lineOfBusiness === 'LV' &&
          claim.claimDescription.toLowerCase().indexOf('intermittent') > -1
      ) > -1
    );
  }
  async logOut() {
    if (
      this.session.isEmulating$ &&
      this.session.user.emulatorContext != null
    ) {
      this.dialog.open(this.dialogRefLogoutConfirmation);
    } else {
      sessionStorage.removeItem('eulaAccepted');
      await this.session.logoff();
    }
  }
}
