import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IncidentalAbsence, Session } from '../..';
import { EditAbsenceDialogComponent } from 'src/app/my-sedgwick';
import { RemoveAbsenceDialogComponent } from 'src/app/my-sedgwick';
@Component({
  selector: 'app-absence-summary',
  template: `
    <app-panel
      [title]="title"
      [sentenceCase]="sentenceCase"
      [largeHeader]="true"
    >
      <app-action-row class="tw-w-full" *ngIf="hasReportClaim">
        <app-button right1 emphasis="High" (click)="reportClaim()"
          >Submit an absence</app-button
        >
      </app-action-row>
      <app-absence-grid
        [absences]="absences"
        [filterEnabled]="true"
        [employeeLinkEnabled]="false"
        [hideRef]="true"
      ></app-absence-grid>

      <ng-template #dialogReportNewClaim>
        <app-dialog
          title="Confirmation"
          [width]="700"
          [showActions]="false"
          [showHeader]="false"
          ariaLabel="Confirmation dialog"
          ariaLabelMessage="Close announcement popup"
        >
          <app-report-new-claim></app-report-new-claim>
        </app-dialog>
      </ng-template>
    </app-panel>
  `,
  styles: [],
})
export class AbsenceSummaryComponent implements OnInit {
  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  @Input()
  title: string;

  @Input()
  sentenceCase: boolean = true;

  @Input()
  absences: IncidentalAbsence[];

  hasReportClaim = false;

  constructor(private session: Session, public dialog: MatDialog) {}

  async ngOnInit() {
    this.hasReportClaim =
      !this.session.user.hasIntake || this.session.user.hasIntake === undefined
        ? false
        : true;
  }

  openEditAbsencePopup(absence: IncidentalAbsence) {
    let dialogRef = this.dialog.open(EditAbsenceDialogComponent);
    let instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
  }

  reportClaim() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
    };
    this.dialog.open(this.dialogRefReportNewClaim, dialogConfig);
  }
}
