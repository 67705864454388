import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { LoadingState, deepCopy, srSpeak } from 'src/app/shared';
import { SubSink } from 'subsink';
import { DisplayService, Excel, ExportRequest } from '../..';
import { DelegationService } from '../../services/delegation.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { Session } from '../../services/session.service';
import {
  DetailSearchConfiguration,
  SearchRequest,
} from '../search/detail-search-form.component';
import { Delegatee, Delegator } from './act-delegate-page.component';
/* import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { start } from 'repl';
import { endOfDay } from 'date-fns';
 */
@Component({
  selector: 'app-manage-delegate-page',
  template: `
    <app-section class="tw-w-full">
      <app-claim-breadcrumb class="tw-w-[90%]"></app-claim-breadcrumb>
      <form
        [formGroup]="delegateFormGroup"
        class="tw-w-full tw-flex tw-flex-wrap tw-items-center tw-px-2 tw-pb-1"
      >
        <div class="tw-grow">
          <app-manager-selector
            *ngIf="filterByDelegator"
            [isDelegatorSearch]="true"
            formControlName="delegator"
            [formGroup]="delegateFormGroup"
            label="Filter by delegator"
            ngDefaultControl
            [managerLookupSupervisor]="true"
            [managerLookupOmni]="false"
          >
          </app-manager-selector>
        </div>
      </form>
      <app-panel
        [largeHeader]="true"
        [headerLevel]="1"
        [canExpand]="false"
        [expanded]="true"
        [title]="
          (viewDelegatorLabel
            ? (viewDelegatorLabel | sentenceCase) + ' - '
            : '') + 'Manage delegates'
        "
        class="tw-w-full"
        [sentenceCase]="false"
      >
        <app-loading-panel [skeletonLines]="8" [state]="loading">
          <ng-template #empty>
            <div
              class="tw-flex tw-items-center tw-w-full tw-h-full tw-justify-around"
            >
              <app-empty-state-label
                icon="group_off"
                header="There are currently no delegates assigned"
                message="Use the form below to add delegates"
              >
              </app-empty-state-label>
            </div>
          </ng-template>
          <ng-template #content>
            <app-list
              class="tw-w-full"
              [dataSource]="delegateService.currentDelegatees()"
              [template]="row"
              [filterEnabled]="true"
              [exportEnabled]="true"
              exportButtonAriaLabel="Export and download these delegates as a XLSX file"
              [noDataIcon]="noDataIcon"
              [tableAriaLabel]="'Manage Added Delagates'"
              noDataHeader="No results"
              noDataMessage="There are no results that match the search"
              (export)="_export()"
            >
              <ng-container header>
                <tr class="tw-flex tw-flex-wrap">
                  <app-list-header
                    name="delegateeLabel"
                    label="Delegate"
                    class="tw-w-[20%]"
                  ></app-list-header>
                  <app-list-header
                    name="dateEffective"
                    label="Delegation start date"
                    class="tw-w-[20%]"
                  ></app-list-header>
                  <app-list-header
                    name="dateExpire"
                    label="Delegation end date"
                    class="tw-w-[20%]"
                  ></app-list-header>
                  <app-list-header
                    name="edit"
                    label="Edit"
                    [sortEnabled]="false"
                    class="tw-w-[20%]"
                  ></app-list-header>
                  <app-list-header
                    name="remove"
                    label="Remove"
                    class="tw-w-[20%]"
                    [sortEnabled]="false"
                  ></app-list-header>
                </tr>
              </ng-container>
              <ng-template #row let-element>
                <tr class="tw-flex tw-flex-wrap">
                  <td class="tw-w-full sm:tw-w-[20%] hylinks">
                    <app-value
                      [value]="element.delegateeLabel | titlecase"
                      label="Delegatee"
                      [attr.tabindex]="element.delegateeLabel ? '0' : '-1'"
                      layout="row-xs"
                    ></app-value>
                  </td>
                  <td class="tw-w-full sm:tw-w-[20%]">
                    <app-value
                      label="Delegation Start Date"
                      [tabindex]="element.dateEffective ? '0' : '-1'"
                      layout="row-xs"
                      [value]="element.dateEffective | format : 'date'"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full sm:tw-w-[20%]">
                    <app-value
                      label="Delegation End Date"
                      [tabindex]="element.dateExpire ? '0' : '-1'"
                      layout="row-xs"
                      [value]="element.dateExpire | format : 'date'"
                    >
                    </app-value>
                  </td>
                  <td class="tw-w-full sm:tw-w-[20%]">
                    <div>
                      <app-icon-button
                        [attr.aria-label]="
                          'Edit delegate ' + element.delegateeLabel
                        "
                        icon="edit"
                        label="Edit"
                        tabindex="0"
                        role="button"
                        (click)="
                          editLabel = 'Edit delegate';
                          current = element;
                          setCurrentValues()
                        "
                        (keydown.enter)="
                          editLabel = 'Edit delegate';
                          current = element;
                          setCurrentValues()
                        "
                      ></app-icon-button>
                    </div>
                  </td>
                  <td class="tw-w-full sm:tw-w-[20%]">
                    <div>
                      <app-icon-button
                        [attr.aria-label]="
                          'Remove delegate ' + element.delegateeLabel
                        "
                        tabindex="0"
                        role="button"
                        icon="delete_forever"
                        label="Remove"
                        (click)="current = element; dialog.open(removeDialog)"
                        (keydown.enter)="
                          current = element; dialog.open(removeDialog)
                        "
                      ></app-icon-button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </app-list>
          </ng-template>
        </app-loading-panel>

        <ng-template #removeDialog let-element="element">
          <app-dialog-container
            [headerId]="'removeDelegate'"
            [headerLevel]="2"
            aria-labelledby="'removeDelegate'"
          >
            <div class="tw-py-6">
              Press the confirm button below to remove Delegate
              <b>{{ current.delegateeLabel }}</b>
            </div>
            <app-action-row>
              <app-button
                emphasis="High"
                right2
                matDialogClose
                (click)="removeDelegate()"
                >Confirm</app-button
              >
              <app-button emphasis="Low" right1 matDialogClose
                >Cancel</app-button
              >
            </app-action-row>
          </app-dialog-container>
        </ng-template>

        <ng-template #editDialog>
          <app-dialog-container
            [label]="editLabel"
            [headerId]="'editDelegate'"
            [headerLevel]="2"
            (clearClicked)="resetFormGroup()"
            aria-labelledby="'editDelegate'"
          >
            <form
              class="tw-max-w-md tw-flex tw-flex-wrap"
              [formGroup]="formGroup"
            >
              <div class="tw-w-full tw-pb-4 tw-pt-2 tw-text-lg">
                {{ current.delegateeLabel }}
              </div>
              <div class="tw-w-full tw-pb-4 tw-pt-2 tw-text-md" tabindex="0">
                Asterisks(*) indicate required fields.
              </div>
              <app-date-control
                formControlName="startDate"
                [formGroup]="formGroup"
                label="Start Date"
                ngDefaultControl
                [validationControl]="'Start Date'"
                class="tw-w-full md:tw-w-[50%]"
              ></app-date-control>
              <app-date-control
                formControlName="endDate"
                [formGroup]="formGroup"
                label="End Date"
                ngDefaultControl
                [validationControl]="'End Date'"
                class="tw-w-full md:tw-w-[50%]"
              ></app-date-control>
              <h3 class="tw-w-full tw-pb-4 tw-pt-4 app-label">Permissions</h3>
              <div class="tw-w-full">
                <app-toggle-control
                  formControlName="reportAbsence"
                  [formGroup]="formGroup"
                  ngDefaultControl
                  label="Report an absence"
                  labelClass="tw-font-bold app-color-slate"
                ></app-toggle-control>
              </div>
              <div class="tw-w-full">
                <app-toggle-control
                  formControlName="reportClaim"
                  [formGroup]="formGroup"
                  ngDefaultControl
                  label="Report a new claim"
                  labelClass="tw-font-bold app-color-slate"
                ></app-toggle-control>
              </div>
              <div class="tw-w-full">
                <app-toggle-control
                  formControlName="receiveEmail"
                  [formGroup]="formGroup"
                  ngDefaultControl
                  label="Receive email correspondence for claim"
                  labelClass="tw-font-bold app-color-slate"
                ></app-toggle-control>
              </div>

              <div class="tw-w-full tw-pb-4">
                <app-toast type="Error" *ngIf="noChangesMade"
                  >Error : Please enter at least one update criteria or cancel
                  to close the window.
                </app-toast>
              </div>
              <app-action-row class="tw-w-full">
                <app-button
                  emphasis="High"
                  role="button"
                  right2
                  (click)="addDelegate()"
                  >Save</app-button
                >
                <app-button
                  emphasis="Low"
                  role="button"
                  right1
                  matDialogClose
                  (click)="resetFormGroup()"
                  >Cancel</app-button
                >
              </app-action-row>
            </form>
          </app-dialog-container>
        </ng-template>
      </app-panel>
      <app-panel
        title="Add a delegate"
        [headerLevel]="2"
        [largeHeader]="false"
        class="tw-w-full"
      >
        <div class="tw-min-h-[510px] tw-w-full">
          <app-detail-search-form
            #searchForm
            [config]="config"
            (search)="searchDelegates($event)"
            [loading]="searchLoading"
          >
          </app-detail-search-form>
          <app-loading-panel [skeletonLines]="8" [state]="searchLoading">
            <ng-template #empty>
              <div
                class="tw-flex tw-items-center tw-w-full tw-h-full tw-justify-around"
              >
                <app-empty-state-label
                  icon="search"
                  x-header="Search results"
                  [message]="config.initialSearchMsg"
                >
                </app-empty-state-label>
              </div>
            </ng-template>
            <ng-template #content>
              <app-search-count-row
                [count]="searchResults.length"
                [maxResultsMsg]="maxResultsMsg"
                [resultSingle]="employeeAlias"
                [resultPlural]="employeeAlias + 's'"
              ></app-search-count-row>
              <app-list
                class="tw-w-full"
                [dataSource]="searchResults"
                [template]="addRow"
                [filterEnabled]="true"
                [exportEnabled]="true"
                exportButtonAriaLabel="Export and download these delegates as a XLSX file"
                [noDataIcon]="noDataIcon"
                noDataHeader="No Results"
                noDataMessage="There are no results that match the search"
                (export)="_results_export()"
                [tableAriaLabel]="'Search Delegatee Results'"
              >
                <ng-container header>
                  <tr class="tw-flex tw-flex-wrap">
                    <app-list-header
                      name="add"
                      label="Add delegate"
                      class="tw-w-[33%]"
                      [sortEnabled]="false"
                    ></app-list-header>
                    <app-list-header
                      name="delegateeLabel"
                      label="Name"
                      class="tw-w-[33%]"
                    ></app-list-header>
                    <app-list-header
                      name="managerLabel"
                      label="Manager"
                      class="tw-w-[34%]"
                    ></app-list-header>
                  </tr>
                </ng-container>
                <ng-template #addRow let-element>
                  <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                    <td class="tw-w-full sm:tw-w-[33%]">
                      <app-icon-button
                        icon="group_add"
                        label="Add delegate"
                        role="button"
                        tabindex="0"
                        [attr.aria-label]="'Add Delegate'"
                        (click)="
                          editLabel = 'Add delegate';
                          current = element;
                          resetFormGroup();
                          setCurrentValues(true)
                        "
                        (keydown.enter)="
                          editLabel = 'Add delegate';
                          current = element;
                          resetFormGroup();
                          setCurrentValues(true)
                        "
                      ></app-icon-button>
                    </td>
                    <td class="tw-w-full sm:tw-w-[33%]">
                      <app-value
                        [value]="element.delegateeLabel"
                        [tabindex]="element.delegateeLabel ? '0' : '-1'"
                        layout="row-xs"
                      >
                      </app-value>
                    </td>
                    <td class="tw-w-full sm:tw-w-[34%]">
                      <app-value
                        layout="row-xs"
                        [value]="element.managerLabel"
                        [tabindex]="element.managerLabel ? '0' : '-1'"
                      >
                      </app-value>
                    </td>
                  </tr>
                </ng-template>
              </app-list>
            </ng-template>
          </app-loading-panel>
        </div>
      </app-panel>
    </app-section>
  `,
  styles: `
  .hylinks {
    font-weight: 400 !important;
    text-decoration: none !important;
    cursor:default;
  }
  .hylinks:focus {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #007dbb !important;
}
  `,
})
export class ManageDelegatePageComponent implements OnInit {
  subs = new SubSink();

  @Output()
  export = new EventEmitter<ExportRequest>();

  get startControl() {
    return this.formGroup.controls.startDate;
  }

  get endControl() {
    return this.formGroup.controls.endDate;
  }

  get startDate() {
    return this.dateonly(new Date(this.formGroup.controls.startDate.value));
  }
  get endDate() {
    return this.dateonly(new Date(this.formGroup.controls.endDate.value));
  }

  config = new DetailSearchConfiguration();
  employeeAlias: string;
  current: Delegatee;
  dialogRef: MatDialogRef<any>;
  searchResults: any[];
  maxResultsMsg: string =
    'The Search Results maximum has been exceeded. Please modify the search and try again.';
  isMaxCount: boolean = false;
  searchRequest: SearchRequest;
  loading: LoadingState;
  searchLoading: LoadingState = 'Init';
  noDataIcon: string = 'list_alt';
  viewDelegatorLabel: string = '';
  filterByDelegator: boolean = false;
  filteringByDelegator: boolean = false;
  filteredDelegator: Delegator;
  noChangesMade: boolean = false;

  @ViewChild('editDialog', { static: true }) editDialog: TemplateRef<any>;

  formGroup = this.fb.group({
    startDate: [''],
    endDate: [''],
    reportAbsence: [false],
    reportClaim: [false],
    receiveEmail: [false],
  });

  delegateFormGroup = this.fb.group({
    delegator: [],
  });

  editLabel = 'Edit delegate';

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public delegateService: DelegationService,
    private session: Session,
    private aliases: InpageAliasService,
    public cdr: ChangeDetectorRef,
    public display: DisplayService,
    private excel: Excel
  ) {
    this.config.key = 'manage-delegates:search-employees';
    this.config.setVisibility(true);
    this.config.firstName.visible = true;
    this.config.lastName.visible = true;
    this.config.employeeId.visible = true;
    this.config.initialSearch = false;
    this.config.initialSearchResultsOnClear = false;
    this.config.managerLookupSupervisor = false;
    this.config.showParametersToggle = false;
    this.config.expanded = true;
    this.config.claimNumber.visible = false;
    this.config.lob.visible = false;
    this.config.includeIndirect.visible = false;
    this.config.supervisor.visible = false;
    this.config.endDate.visible = false;
    this.config.startDate.visible = false;
    this.config.ssn.visible = false;
    this.config.status.visible = false;
    this.config.firstName.widthPercent = 33;
    this.config.lastName.widthPercent = 33;
    this.config.employeeId.widthPercent = 33;
    this.config.emptyFormAllowed = false;
    this.config.payType.visible = false;
    this.config.initialSearchMsg = 'Search results will appear here.';
    this.config.setDefaultVisibilitySettings = false;
    this.config.alertType.visible = false;
  }

  async ngOnInit() {
    this.subs.sink = this.startControl.valueChanges.subscribe((v) => {
      if (v && this.current !== undefined && this.current !== null) {
        this.validateInputs();
        setTimeout(() => {
          this.formGroup.controls.startDate.updateValueAndValidity();
          this.formGroup.controls.startDate.markAllAsTouched();
        }, 5);
        this.cdr.detectChanges();
      }
    });

    this.subs.sink = this.endControl.valueChanges.subscribe((v) => {
      if (v && this.current !== undefined && this.current !== null) {
        this.validateInputs();
        setTimeout(() => {
          this.formGroup.controls.endDate.updateValueAndValidity();
          this.formGroup.controls.endDate.markAllAsTouched();
        }, 5);

        this.cdr.detectChanges();
      }
    });

    const delegationFeatures = await this.display.delegationFeatures();
    this.filterByDelegator = delegationFeatures.manageOthersDelegates;
    this.subs.sink =
      this.delegateFormGroup.controls.delegator.valueChanges.subscribe(
        async (value: {
          empId: string;
          firstName: any;
          lastName: any;
          middleName: any;
          empUNum: any;
          managerUserId: any;
        }) => {
          if (value && value.empUNum != null) {
            this.viewDelegatorLabel = value?.empUNum
              ? value.firstName + ' ' + value.lastName
              : '';
            this.filteredDelegator = {
              delegatorEmpUnum: value?.empUNum,
              delegatorFirstName: value?.firstName,
              delegatorLastName: value?.lastName,
              delegatorEmpId: value?.empId,
              delegatorHrMasterUid: value?.managerUserId,
            };
            this.filteringByDelegator = true;
            await this.delegateService.loadUpdateDelegatees(value?.empUNum);
            this.updateLoading();
          } else if (!value) {
            this.viewDelegatorLabel = '';
            this.filteringByDelegator = false;
            await this.delegateService.loadUpdateDelegatees('');
            this.updateLoading();
          }
        }
      );

    this.employeeAlias = await this.aliases.get('employeeSingular');
    this.loading = 'Loading';
    if (delegationFeatures.manageOwnDelegates) {
      await this.delegateService.loadUpdateDelegatees('');
      this.updateLoading();
    } else {
      this.loading = 'Loaded';
    }
    this.resetFormGroup();
  }

  updateLoading() {
    this.loading =
      this.delegateService.currentDelegatees() &&
      this.delegateService.currentDelegatees().length > 0
        ? 'Loaded'
        : this.delegateService.currentDelegatees() &&
          this.delegateService.currentDelegatees().length === 0
        ? 'Empty'
        : 'Loading';
  }

  setCurrentValues(isAddNew: boolean = false) {
    this.addValidators(isAddNew);
    if (this.current) {
      this.formGroup.patchValue({
        startDate: isAddNew ? '' : this.current.dateEffective,
        endDate: isAddNew ? '' : this.current.dateExpire,
        reportAbsence: this.current.addAbsences,
        reportClaim: this.current.reportClaim,
        receiveEmail: this.current.emailCorrespondence,
      });

      this.noChangesMade = false;
      if (isAddNew) {
        this.formGroup.markAsDirty();
      }
      if (!isAddNew) {
        setTimeout(() => {
          this.formGroup.controls.startDate.markAsTouched();
          this.formGroup.controls.startDate.updateValueAndValidity();
          this.formGroup.controls.endDate.markAsTouched();
          this.formGroup.controls.endDate.updateValueAndValidity();
        }, 50);
      }
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.ariaLabelledBy = isAddNew ? 'addDelegate' : 'editDelegate';
    this.dialogRef = this.dialog.open(this.editDialog, dialogConfig);
  }

  async removeDelegate() {
    this.loading = 'Loading';
    this.current.deletedFlag = true;
    await this.delegateService.upsertDeleteDelegatee(
      this.current,
      this.filteredDelegator
    );
    this.updateLoading();
    srSpeak('Delegate removed', 'polite');
  }

  addValidators(isAddNew: boolean) {
    this.startControl.setValidators([Validators.required]);
    this.endControl.setValidators([Validators.required]);
  }

  resetFormGroup() {
    this.formGroup.reset({
      startDate: '',
      endDate: '',
      reportAbsence: false,
      reportClaim: false,
      receiveEmail: false,
    });
  }

  async addDelegate() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      this.noChangesMade = false;
      return Promise.resolve(false);
    }
    if (!this.formGroup.dirty) {
      this.noChangesMade = true;
      return Promise.resolve(false);
    }
    this.noChangesMade = false;
    this.loading = 'Loading';
    this.current.dateEffective = this.formGroup.get('startDate')?.value ?? '';
    this.current.dateExpire = this.formGroup.get('endDate')?.value ?? '';
    this.current.addAbsences =
      this.formGroup.get('reportAbsence')?.value ?? false;
    this.current.reportClaim =
      this.formGroup.get('reportClaim')?.value ?? false;
    this.current.emailCorrespondence =
      this.formGroup.get('receiveEmail')?.value ?? false;
    await this.delegateService.upsertDeleteDelegatee(
      this.current,
      this.filteredDelegator
    );
    this.resetFormGroup();
    this.loading = 'Loaded';
    this.dialogRef.close();
    srSpeak('Delegate added or edited', 'polite');
    return Promise.resolve(true);
  }

  async searchDelegates(request: SearchRequest) {
    this.isMaxCount = false;
    this.searchLoading = 'Loading';
    const ownEmpUnum = this.filteringByDelegator
      ? this.filteredDelegator.delegatorEmpUnum
      : null;
    const delegateSearchInfo =
      await this.delegateService.getDelegateSearchResults(
        request.getParameters(),
        ownEmpUnum
      );
    this.searchResults = delegateSearchInfo.delegatees;
    this.isMaxCount = delegateSearchInfo.isMaxCount;
    this.searchLoading =
      this.searchResults && this.searchResults.length > 0
        ? 'Loaded'
        : this.searchResults && this.searchResults.length === 0
        ? 'Loaded'
        : 'Empty';
  }

  _results_export() {
    const claimRows = deepCopy(this.searchResults);

    const request = new ExportRequest();
    request.fileName = 'Delegates_Search.xlsx';
    request.sheets = [
      {
        name: 'Delegates_Search',
        rows: claimRows,
        headers: [
          { label: 'NAME', value: 'delegateeLabel' },
          { label: 'MANAGER', value: 'managerLabel' },
        ],
      },
    ];
    this.export.emit(request);
    this.excel.export(request);
  }

  _export() {
    const claimRows = deepCopy(this.delegateService.currentDelegatees());

    const request = new ExportRequest();
    request.fileName = 'Delegates.xlsx';

    request.sheets = [
      {
        name: 'Delegates',
        rows: claimRows,
        headers: [
          { label: 'DELEGATE', value: 'delegateeLabel' },
          {
            label: 'DELEGATION START DATE',
            value: 'dateEffective',
            format: 'date',
          },
          { label: 'DELEGATION END DATE', value: 'dateExpire', format: 'date' },
          { label: 'REPORT A NEW CLAIM', value: 'reportClaim' },
          { label: 'REPORT AN ABSENCE', value: 'addAbsences' },
          {
            label: 'RECEIVE EMAIL CORRESPONDENCE FOR CLAIM',
            value: 'emailCorrespondence',
          },
        ],
      },
    ];

    this.export.emit(request);
    this.excel.export(request);
  }
  dateonly(d: Date): Date {
    return new Date(d.toDateString());
  }
  dateOverlapsPeriod(d: Date, sd: Date, ed: Date): boolean {
    return ed.getTime() >= d.getTime() && d.getTime() >= sd.getTime();
  }
  datesWrapPeriod(sd: Date, ed: Date, SD: Date, ED: Date) {
    return sd.getTime() <= SD.getTime() && ed.getTime() >= ED.getTime();
  }

  validateInputs(): any {
    const dateRangeMsg =
      'Date range overlaps existing dates for this delegate.';
    const curDelRows = this.delegateService
      .currentDelegatees()
      .filter(
        (d) =>
          this.current &&
          d.delegateeEmpId === this.current.delegateeEmpId &&
          d.delegatePk !== this.current.delegatePk
      );
    const curDelDates = curDelRows.map((cd) => {
      return {
        startDate: new Date(cd.dateEffective),
        endDate: new Date(cd.dateExpire),
      };
    });

    const yearMs = 365 * 24 * 60 * 60 * 1000;
    // only do extra validations if we don't have a basic error like required or invalid date format
    if (
      (this.startControl.errors === null && this.endControl.errors === null) ||
      (this.startControl.errors !== null &&
        !this.startControl.errors['matDatepickerParse']) ||
      (this.endControl.errors !== null &&
        !this.endControl.errors['matDatepickerParse'])
    ) {
      // check start date past end date
      if (this.startDate.getTime() > this.endDate.getTime()) {
        const dateInFutureMsg = 'Error : Enter a valid date range';
        this.startControl.setErrors({
          startInFutureStart: true,
          customMsg: dateInFutureMsg,
        });
        this.endControl.setErrors({
          startInFutureEnd: true,
          customMsg: dateInFutureMsg,
        });
        return null;
      }

      // check end date not greater than a year from start date
      if (this.endDate.getTime() - this.startDate.getTime() > yearMs) {
        const periodOverYearMsg =
          'Error : Access period must be less than one year.';

        this.startControl.setErrors({
          periodOverYearStart: true,
          customMsg: periodOverYearMsg,
        });
        this.endControl.setErrors({
          periodOverYearEnd: true,
          customMsg: periodOverYearMsg,
        });
        return;
      }

      let addedError = false;
      // check if start date overlaps configured periods
      if (
        curDelDates.filter((d1) =>
          this.dateOverlapsPeriod(
            this.startDate,
            this.dateonly(d1.startDate),
            this.dateonly(d1.endDate)
          )
        ).length > 0
      ) {
        // input start date itself overlaps a range, set error on start control
        if (this.startControl.errors === null) {
          this.startControl.setErrors({
            startDateOverlaps: true,
            customMsg: dateRangeMsg,
          });
          return null;
        }
      }

      // check if end date overlaps configured periods
      if (
        curDelDates.filter((d2) =>
          this.dateOverlapsPeriod(
            this.endDate,
            this.dateonly(d2.startDate),
            this.dateonly(d2.endDate)
          )
        ).length > 0
      ) {
        // input start date itself overlaps a range, set error on start control
        this.endControl.setErrors({
          endDateOverlaps: true,
          customMsg: dateRangeMsg,
        });
        addedError = true;
      }

      // check if input period "wraps" a configured period
      if (
        curDelDates.filter((d3) =>
          this.datesWrapPeriod(
            this.startDate,
            this.endDate,
            this.dateonly(d3.startDate),
            this.dateonly(d3.endDate)
          )
        ).length > 0
      ) {
        // set error on both inputs
        if (this.startControl.errors === null) {
          this.startControl.setErrors({
            datesWrapStart: true,
            customMsg: dateRangeMsg,
          });
        }
        this.endControl.setErrors({
          datesWrapEnd: true,
          customMsg: dateRangeMsg,
        });
        addedError = true;
      }

      if (this.startControl.errors !== null) {
        // && startErrors == null) {
        //this.startControl.updateValueAndValidity();
      }

      if (this.endControl.errors !== null) {
        // && endErrors == null) {
        //this.endControl.updateValueAndValidity();
      }

      if (
        addedError ||
        this.startControl.errors !== null ||
        this.endControl.errors !== null
      ) {
        return null;
      }
    } else {
      return null;
    }
    if (this.startControl.errors !== null || this.endControl.errors !== null) {
      // console.log(
      //   'VALIDATING INPUTS',
      //   this.startControl.errors,
      //   this.endControl.errors
      // );
    }

    this.endControl.setErrors(null);
    this.startControl.setErrors(null);
    return null;
  }
}
