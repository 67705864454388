export class AbsenceReport {
  claimId: string;
  source: string;
  message: string;
  absences: AbsenceInfo[];
}

export class AbsenceInfo {
  dateOfAbsence: Date;
  hours: number;
  minutes: number;
  reason: string;
  confirmationNumber: string;

  get result(): string {
    return `${this.hours} hours ${this.minutes} minutes`;
  }

  get reasonText(): string | null {
    if (!this.reason) {
      return null;
    }

    const lowerCaseReason = this.reason.toLowerCase();

    if (lowerCaseReason === 'illness') {
      return 'Illness';
    } else if (lowerCaseReason === 'appointment') {
      return 'Appointment';
    } else {
      return null;
    }
  }
}

export class AbsenceInfoRequest {
  date: string;
  hours: number;
  minutes: number;
  reason: string;
  id: string;
  reasonText: string;
  employeeId: string;
  confirmation: string;
  messageId: string;
}

export class ReportAbsenceInfoRequest {
  claimId: string;
  source: string;
  message: string;
  absences: AbsenceInfoRequest[];
}

export class ReportIntermittentAbsenceResponse {
  success: boolean;
  message: string;
  confirmationId: number;
}
