import { Injectable } from '@angular/core';
import { UserActionService, getUrlParams } from 'src/app/shared';
import { ClaimService } from './claim.service';
import { EncryptionService } from './encryption.service';
import { Session } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimantService {
  constructor(
    private encrypt: EncryptionService,
    private userActions: UserActionService,
    private session: Session,
    public claimService: ClaimService
  ) {}
  async getEmpId() {
    const params = getUrlParams();
    let empID = null;

    const claimant = this.userActions.getClaimant();
    if (claimant?.empId) {
      empID = claimant.empId;
    } else if (claimant?.employeeID) {
      empID = claimant.empId;
    } else if (
      empID != null &&
      !empID &&
      //!empID.length &&
      params.source &&
      params.claimId
    ) {
      const claim = await this.claimService.getSummary(
        params.source,
        params.claimId
      );
      if (claim) {
        if (claim.empId) {
          empID = claim.empId;
        } else if (claim.employeeId) {
          empID = claim.employeeId;
        }
      }
    }
    if (!empID || !empID.length) {
      //console.error('empID is Undefined');
    }

    return empID;
  }
}
