export interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  employeeId: string;
  manager: boolean;
  claimant: boolean;
  clientNumber: string;
  clientName: string;
  clientType: string;
  hrClient: boolean;
  userName: string;
  userId: string;
  hasIntake: boolean;
  empUnum: string;
  accommodationType: string;
  aliases: any[];
  contNum: string;
  features: any[];
  gender: string;
  intakes: any[];
  isTerminated: boolean;
  leaveType: string;
  lob: string;
  sessionId: string;
  emailId: string;
  phoneType: string;
  phoneNumber: string;
  lineOfBusiness: any[];
  clientLobs: any[];
  timezone: string;
  timeoutThresholds: any;
  authenticationType: string;
  logOutUrl: string;
  userType: string;
  role: UserRole;
  roles: Roles;
  emulatorContext: User;
  delegateeContext: User;
  isEmulating: boolean;
  isDelegating: boolean;
  isClaimAccessible: boolean;
  isClaimClosed: boolean;
  supervMatchType: string;
  registrationMethod: string;
  hasSsnSearch: boolean;
  accessibleRoles: string[];
  rateType: string;
  mcF94: string;
  mcF377: string;
  empStatus: string;
  error: string;
  outboundSSO: OutboundSSO;
  hasLiabilityLob: boolean;
  employeeType: string;
  schedulerKey: string;
  delegatorEmpUnum: string; // remove after pepsico transistions to 5.0
}

export class OutboundSSO {
  controlNumber: string;
  ssoType: string;
  ssoLinkEmployee: string;
  ssoLinkManager: string;
  ssoEndPoint: string;
}

export type UserRole =
  | 'MANAGER'
  | 'HRM'
  | 'CONTROLLER'
  | 'AUDITOR'
  | 'OMNI'
  | 'OVERSEER'
  | 'SUPERVISOR'
  | 'EE';

export enum UserActions {
  ViewOwnEmpDb, //View Own Employee Dashboard
  ViewOwnMgrDb, // View Own Manager Dashboard
  MgrViewEmpDb, // Manager View Employee's Dashboard
  ViewOwnClaim, // Manager View Own claim
  MgrViewEmpClaim, // Manager view Employee's Claim
  MgrViewEmpSubpage, // Manager view employee's subpage
  MgrViewOwnSubpage, // Manager viewing own subpage
  ManagerSummaryView, //OMNI / overseer first page
  FilterByManager, // manager filtering by manager
}

export function isManagement(role: string): boolean {
  return (
    role === 'MANAGER' ||
    role === 'CONTROLLER' ||
    role === 'AUDITOR' ||
    role === 'OMNI' ||
    role === 'OVERSEER' ||
    role === 'SUPERVISOR'
  );
}

export class Roles {
  employee: boolean;
  auditor: boolean;
  supervisor: boolean;
  overseer: boolean;
  omni: boolean;
  controller: boolean;

  /**
   * Manager is in role type supevisor, omni or overseer.
   */
  get manager(): boolean {
    return this.supervisor || this.overseer || this.omni;
  }
}
