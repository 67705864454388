import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { reportNewClaim } from 'src/app/my-sedgwick/functions/report-newclaim';
import { scrollToElement } from 'src/app/shared';
import { SubSink } from 'subsink';
import { DynamicEventBindService, LogService } from '../..';
import { ExternalLinkDialogComponent } from '../../../shared/components/controls/dialog/external-link-dialog.component';
import { ConfigurationService } from '../../services/configuration.service';
import { LearningCenterService } from '../../services/learning-center.service';
import { OpenFileService } from '../../services/openfile.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-learning-center-wc',
  template: `
    <div #dynamicContentContainer class="tw-w-full tw-flex">
      <!-- CONTENT -->
      <div class="tw-grow">
        <!-- INTRODUCTION -->
        <h2
          class="app-lc-header-2"
          id="lc-intro"
          *ngIf="isIntroduction"
          role="heading"
        >
          Introduction
        </h2>
        <div class="app-lc-text">
          <p [innerHTML]="learningCenter.introductionResults[0]?.value"></p>
        </div>
        <h2
          *ngIf="isWhatisWC"
          [innerHTML]="learningCenter.introductionResults[1].value"
          class="app-lc-header-2"
          id="lc-what"
          role="heading"
        ></h2>
        <div class="app-lc-text tw-flex">
          <div class="tw-grow">
            <p [innerHTML]="learningCenter.introductionResults[2]?.value"></p>
            <p [innerHTML]="learningCenter.introductionResults[3]?.value"></p>
          </div>
          <div
            class="tw-flex-none app-pl2 tw-hidden md:tw-block"
            *ngIf="isWhatisWC"
          >
            <img
              src="./assets/images/img_LC_introphone_desktop.webp"
              width="69"
              height="100"
              alt=""
              aria-hidden="true"
              role="img"
            />
          </div>
        </div>
        <!-- INTRODUCTION END-->
        <!-- BENEFITS CALC-->
        <h2
          class="app-lc-header-2"
          id="lc-ben"
          role="heading"
          *ngIf="isBenefitsCalc"
        >
          Benefits Calculations
        </h2>
        <div class="app-lc-text">
          <p [innerHTML]="learningCenter.benefits[0]?.value"></p>
          <p [innerHTML]="learningCenter.benefits[1]?.value"></p>
        </div>
        <!-- BENEFITS CALC END-->
        <!-- SUBMITTING CLAIM INFO-->

        <h2 class="app-lc-header-2" id="lc-sub" role="heading">
          Submitting Claim Information
        </h2>
        <div *ngIf="isMgr; then mgrSubmitInfo; else nonMgrSubmitInfo"></div>
        <ng-template #mgrSubmitInfo>
          <div>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[0]?.value }}"
              icon="1"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter?.submittingclaiminformations[1].value
                "
              ></p>
              <span
                #dynamicContentContainer
                [innerHTML]="
                  learningCenter?.submittingclaiminformations[2].value
                "
              ></span>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[3]?.value }}"
              icon="2"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[4]?.value
                "
              ></p>
              <p
                *ngIf="!claimClosed && isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[5]?.value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[6].value }}"
              icon="3"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[7]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[9]?.value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              *ngIf="this.learningCenter.submittingclaiminformations[10].value"
              label="{{
                learningCenter.submittingclaiminformations[10]?.value
              }}"
              icon="4"
              layout="bullet"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
              >
                <app-learning-center-section
                  *ngIf="
                    this.learningCenter.submittingclaiminformations[11].value &&
                    this.claimConfigService.getFeatureAccess(
                      'UploadDocuments'
                    ) &&
                    isMgr &&
                    isShown
                  "
                  class="tw-basis-1/4 app-color-teal"
                  layout="icon-small"
                  icon="upload_file"
                >
                  <a
                    tabindex="0"
                    label="Documents"
                    class="go-to-documents-upload"
                    >Upload Documents</a
                  >
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="
                    isMgr &&
                    learningCenter.submittingclaiminformations[13]?.value
                  "
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="mail"
                >
                  Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[13]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[14]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="fax"
                >
                  Fax:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[14]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[12]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="laptop_mac"
                >
                  Email:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[12]?.value
                    "
                  ></span>
                </app-learning-center-section>
              </div>
            </app-learning-center-section>
          </div>
        </ng-template>
        <ng-template #nonMgrSubmitInfo>
          <div>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[0]?.value }}"
              icon="1"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter?.submittingclaiminformations[1].value
                "
              ></p>
              <span
                #dynamicContentContainer
                [innerHTML]="
                  learningCenter?.submittingclaiminformations[2].value
                "
              ></span>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[3]?.value }}"
              icon="2"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[4]?.value
                "
              ></p>
              <p
                *ngIf="!claimClosed && isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[5]?.value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[6].value }}"
              icon="3"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[7]?.value
                "
              ></p>
              <p
                *ngIf="!claimClosed && isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[8]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[9]?.value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              *ngIf="this.learningCenter.submittingclaiminformations[10].value"
              label="{{
                learningCenter.submittingclaiminformations[10]?.value
              }}"
              icon="4"
              layout="bullet"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
              >
                <app-learning-center-section
                  *ngIf="
                    this.learningCenter.submittingclaiminformations[11].value &&
                    this.claimConfigService.getFeatureAccess(
                      'UploadDocuments'
                    ) &&
                    !claimClosed &&
                    isShown
                  "
                  class="tw-basis-1/4 app-color-teal"
                  layout="icon-small"
                  icon="upload_file"
                >
                  <a
                    tabindex="0"
                    label="Documents"
                    class="go-to-documents-upload"
                    >Upload Documents</a
                  >
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[13]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="mail"
                >
                  Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[13]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[14]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="fax"
                >
                  Fax:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[14]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[12]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="laptop_mac"
                >
                  Email:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[12]?.value
                    "
                  ></span>
                </app-learning-center-section>
              </div>
            </app-learning-center-section>
          </div>
        </ng-template>
        <!-- SUBMITTING CLAIM INFO END-->
        <!-- WHATS NEXT-->
        <h2
          class="app-lc-header-2"
          role="heading"
          id="lc-next"
          *ngIf="isWhatsNext"
        >
          What's Next?
        </h2>
        <div class="app-lc-text tw-flex">
          <div class="tw-grow">
            <div *ngIf="isMgr; then mgrNext; else nonMgrNext"></div>
            <ng-template #mgrNext>
              <div
                class="right-wrap app-pl2 tw-hidden md:tw-block"
                *ngIf="isWhatsNext"
              >
                <img
                  src="./assets/images/img_LC_WhatsNext_computer.webp"
                  width="141"
                  height="100"
                  alt=""
                  role="img"
                />
              </div>
              <p [innerHTML]="learningCenter.whatsnexts[0]?.value"></p>
              <p
                *ngIf="
                  (isShown && learningCenter.whatsnexts[3]?.value) ||
                  (learningCenter.whatsnexts[4]?.value &&
                    learningCenter.whatsnexts[5]?.value) ||
                  (learningCenter.whatsnexts[7]?.value &&
                    learningCenter.whatsnexts[8]?.value &&
                    learningCenter.whatsnexts[9]?.value &&
                    learningCenter.whatsnexts[9]?.value) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[5]?.value &&
                    learningCenter.whatsnexts[6]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[1]?.value"
              >
                >
              </p>
              <div>
                <app-learning-center-section
                  *ngIf="isShown && learningCenter.whatsnexts[3]?.value"
                  label="{{ learningCenter.whatsnexts[2]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
                </app-learning-center-section>
              </div>
              <div>
                <app-learning-center-section
                  *ngIf="learningCenter.whatsnexts[8]?.value"
                  label="{{ learningCenter.whatsnexts[6]?.value }}"
                  icon="contact_phone"
                  layout="icon"
                >
                  <p>
                    {{
                      learningCenter.whatsnexts[7]?.value +
                        ' ' +
                        learningCenter.whatsnexts[8]?.value +
                        ' ' +
                        learningCenter.whatsnexts[9]?.value
                    }}
                  </p>
                </app-learning-center-section>
              </div>
            </ng-template>
            <ng-template #nonMgrNext>
              <div
                class="right-wrap app-pl2 tw-hidden md:tw-block"
                *ngIf="isWhatsNext"
              >
                <img
                  src="./assets/images/img_LC_WhatsNext_computer.webp"
                  width="141"
                  height="100"
                  alt=""
                  role="img"
                />
              </div>
              <p [innerHTML]="learningCenter.whatsnexts[0]?.value"></p>
              <p
                *ngIf="
                  (isShown && learningCenter.whatsnexts[3]?.value) ||
                  (learningCenter.whatsnexts[4]?.value &&
                    learningCenter.whatsnexts[5]?.value) ||
                  (learningCenter.whatsnexts[7]?.value &&
                    learningCenter.whatsnexts[8]?.value &&
                    learningCenter.whatsnexts[9]?.value &&
                    learningCenter.whatsnexts[9]?.value) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[5]?.value &&
                    learningCenter.whatsnexts[6]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[1]?.value"
              >
                >
              </p>
              <div>
                <app-learning-center-section
                  *ngIf="
                    !claimClosed &&
                    isShown &&
                    learningCenter.whatsnexts[3]?.value
                  "
                  label="{{ learningCenter.whatsnexts[2]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
                </app-learning-center-section>
              </div>
              <div>
                <app-learning-center-section
                  *ngIf="
                    this.claimConfigService.getConfigFeatureAccess(
                      'notificationpreferences'
                    ) && learningCenter.whatsnexts[5]?.value
                  "
                  label="{{ learningCenter.whatsnexts[4]?.value }}"
                  icon="chat"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[5]?.value"></p>
                </app-learning-center-section>
              </div>
              <div>
                <app-learning-center-section
                  *ngIf="learningCenter.whatsnexts[8]?.value"
                  label="{{ learningCenter.whatsnexts[6]?.value }}"
                  icon="contact_phone"
                  layout="icon"
                >
                  <p>
                    {{ learningCenter.whatsnexts[7]?.value + ' ' }}
                    {{ learningCenter.whatsnexts[8]?.value | phone }}
                    {{ learningCenter.whatsnexts[9]?.value }}
                  </p>
                </app-learning-center-section>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- WHATS NEXT END-->
        <!-- MED TREATMENT-->
        <h2
          class="app-lc-header-2"
          role="heading"
          id="lc-med"
          *ngIf="isMedical"
        >
          Medical Treatment and Providers
        </h2>
        <div class="app-lc-text tw-flex">
          <div class="tw-grow">
            <div
              class="right-wrap app-pl2 tw-hidden md:tw-block"
              *ngIf="isMedical"
            >
              <img
                src="./assets/images/img_LC_MedTreatment_desktop.webp"
                width="176"
                height="200"
                alt=""
                role="img"
              />
            </div>
            <div *ngIf="isMgr; then mgrTandP; else nonMgrTandP"></div>
            <ng-template #mgrTandP>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[0]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[2]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[3]?.value
                "
              ></p>
              <div
                class="tw-w-full tw-flex tw-flex-wrap tw-flex-col md:tw-flex-row  tw-justify-items-stretch"
              >
                <app-learning-center-section
                  class="tw-basis-1/3"
                  layout="icon-small"
                  icon="fax"
                  *ngIf="learningCenter.medicaltreatmentandproviders[4]?.value"
                >
                  <p
                    [innerHTML]="
                      learningCenter.medicaltreatmentandproviders[4]?.value
                    "
                  ></p>
                </app-learning-center-section>
                <app-learning-center-section
                  class="tw-basis-1/3"
                  layout="icon-small"
                  icon="mail"
                  *ngIf="learningCenter.medicaltreatmentandproviders[5]?.value"
                >
                  <p
                    [innerHTML]="
                      learningCenter.medicaltreatmentandproviders[5]?.value
                    "
                  ></p>
                </app-learning-center-section>
              </div>

              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[8]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[9]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[10]?.value
                "
              ></p>
            </ng-template>
            <ng-template #nonMgrTandP>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[0]?.value
                "
              ></p>
              <p
                *ngIf="!claimClosed && isShown"
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[1]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[2]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[3]?.value
                "
              ></p>

              <div
                class="tw-w-full tw-flex tw-flex-wrap tw-flex-col md:tw-flex-row  tw-justify-items-stretch"
              >
                <app-learning-center-section
                  class="tw-basis-1/3"
                  layout="icon-small"
                  icon="mail"
                  *ngIf="learningCenter.medicaltreatmentandproviders[4]?.value"
                >
                  <p
                    [innerHTML]="
                      learningCenter.medicaltreatmentandproviders[4]?.value
                    "
                  ></p>
                </app-learning-center-section>
                <app-learning-center-section
                  class="tw-basis-1/3"
                  layout="icon-small"
                  icon="fax"
                  *ngIf="learningCenter.medicaltreatmentandproviders[5]?.value"
                >
                  <p
                    [innerHTML]="
                      learningCenter.medicaltreatmentandproviders[5]?.value
                    "
                  ></p>
                </app-learning-center-section>
              </div>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[6]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[7]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[8]?.value
                "
              ></p>
              <p
                [innerHTML]="
                  learningCenter.medicaltreatmentandproviders[9]?.value
                "
              ></p>
            </ng-template>
          </div>
        </div>
        <!-- MED TREATMENT END-->
        <!-- RETURN TO WORK-->

        <h2
          class="app-lc-header-2"
          role="heading"
          id="lc-rtw"
          *ngIf="isReturnToWork"
        >
          Returning to Work
        </h2>
        <div class="app-lc-text tw-flex">
          <div class="tw-grow">
            <div
              class="left-wrap app-pr2 img-pd-btm tw-hidden lg:tw-block"
              *ngIf="isReturnToWork"
            >
              <img
                src="./assets/images/img_LC_RTW_desktop.webp"
                width="270"
                height="210"
                alt=""
              />
            </div>
            <div *ngIf="isMgr; then mgrReturn; else nonMgrReturn"></div>
            <ng-template #mgrReturn>
              <p>
                <span
                  ><b
                    [innerHTML]="learningCenter.returntoworks[0]?.value"
                  ></b></span
                ><br />
              </p>
              <p [innerHTML]="learningCenter.returntoworks[1]?.value"></p>
              <p>
                <span
                  ><b
                    [innerHTML]="learningCenter.returntoworks[2]?.value"
                  ></b></span
                ><br />
              </p>
              <p [innerHTML]="learningCenter.returntoworks[3]?.value"></p>
              <p>
                <span
                  ><b
                    [innerHTML]="learningCenter.returntoworks[4]?.value"
                  ></b></span
                ><br />
              </p>
              <p [innerHTML]="learningCenter.returntoworks[5]?.value"></p>
            </ng-template>
            <ng-template #nonMgrReturn>
              <p>
                <span
                  ><b
                    [innerHTML]="learningCenter.returntoworks[0]?.value"
                  ></b></span
                ><br />
              </p>
              <p [innerHTML]="learningCenter.returntoworks[1]?.value"></p>
              <p [innerHTML]="learningCenter.returntoworks[3]?.value"></p>
              <p>
                <span
                  ><b
                    [innerHTML]="learningCenter.returntoworks[4]?.value"
                  ></b></span
                ><br />
              </p>
              <p [innerHTML]="learningCenter.returntoworks[5]?.value"></p>
            </ng-template>
          </div>
        </div>
        <!-- RETURN TO WORK END-->

        <!-- THINGS TO KNOW-->

        <h2
          class="app-lc-header-2"
          role="heading"
          id="lc-things"
          *ngIf="isThingsToKnow"
        >
          Things to Know
        </h2>
        <div class="app-lc-text tw-flex">
          <div class="tw-grow">
            <app-learning-center-section
              label="{{ learningCenter.thingstoknows[0]?.value }}"
              icon="schedule"
              layout="icon"
              *ngIf="isThingsToKnow"
            >
            </app-learning-center-section>
            <b *ngIf="learningCenter.thingstoknows[1]?.value">
              {{ learningCenter.thingstoknows[1]?.value }}
            </b>
            <div *ngIf="isMgr; then mgrKnow; else nonMgrKnow"></div>
            <ng-template #mgrKnow>
              <ul class="tw-list-disc tw-list-inside">
                <li *ngIf="learningCenter.thingstoknows[2]?.value">
                  {{ learningCenter.thingstoknows[2]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[3]?.value">
                  {{ learningCenter.thingstoknows[3]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[4]?.value">
                  {{ learningCenter.thingstoknows[4]?.value }}
                </li>
                <li
                  *ngIf="learningCenter.thingstoknows[5]?.value"
                  [innerHTML]="learningCenter.thingstoknows[5]?.value"
                ></li>
                <li *ngIf="learningCenter.thingstoknows[6]?.value">
                  {{ learningCenter.thingstoknows[6]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[7]?.value">
                  {{ learningCenter.thingstoknows[7]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[8]?.value">
                  {{ learningCenter.thingstoknows[8]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[9]?.value">
                  {{ learningCenter.thingstoknows[9]?.value }}
                </li>
              </ul>
            </ng-template>
            <ng-template #nonMgrKnow>
              <ul class="tw-list-disc tw-list-inside">
                <li *ngIf="learningCenter.thingstoknows[2]?.value">
                  {{ learningCenter.thingstoknows[2]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[3]?.value">
                  {{ learningCenter.thingstoknows[3]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[4]?.value">
                  {{ learningCenter.thingstoknows[4]?.value }}
                </li>
                <li
                  *ngIf="
                    !claimClosed &&
                    isShown &&
                    learningCenter.thingstoknows[5]?.value
                  "
                  [innerHTML]="learningCenter.thingstoknows[5]?.value"
                ></li>
                <li *ngIf="learningCenter.thingstoknows[6]?.value">
                  {{ learningCenter.thingstoknows[6]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[7]?.value">
                  {{ learningCenter.thingstoknows[7]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[8]?.value">
                  {{ learningCenter.thingstoknows[8]?.value }}
                </li>
                <li *ngIf="learningCenter.thingstoknows[9]?.value">
                  {{ learningCenter.thingstoknows[9]?.value }}
                </li>
              </ul>
            </ng-template>
          </div>
        </div>
        <!-- THINGS TO KNOW END-->
      </div>
      <!-- RHS MENU -->
      <div
        class="tw-hidden lg:tw-block tw-flex-none tw-max-w-[220px] app-lc-menu"
      >
        <app-sticky-menu>
          <div class="app-pt2">
            <div class="app-lc-sticky-link"><b>Workers' Compensation</b></div>
            <div class="app-lc-sticky-link" *ngIf="isIntroduction">
              <a (click)="scrollToElement('lc-intro', false, 64)"
                >Introduction</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isWhatisWC">
              <a (click)="scrollToElement('lc-what', false, 64)"
                >What is a Workers' Compensation Claim</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isBenefitsCalc">
              <a (click)="scrollToElement('lc-ben', false, 64)"
                >Benefits Calculations</a
              >
            </div>
            <div class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-sub', false, 64)"
                >Submitting Claim Information</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isWhatsNext">
              <a (click)="scrollToElement('lc-next', false, 64)">What's Next</a>
            </div>
            <div class="app-lc-sticky-link" *ngIf="isMedical">
              <a (click)="scrollToElement('lc-med', false, 64)"
                >Medical Treatment and Providers</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isReturnToWork">
              <a (click)="scrollToElement('lc-rtw', false, 64)"
                >Returning to Work</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isThingsToKnow">
              <a (click)="scrollToElement('lc-things', false, 64)"
                >Things to Know</a
              >
            </div>
          </div>
        </app-sticky-menu>
      </div>
    </div>
  `,
  styles: [
    `
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-header-2 {
        color: var(--color-teal);
        font-size: 28px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .left-wrap {
        float: left;
      }
      .right-wrap {
        float: right;
      }
      .img-pd-btm {
        padding-bottom: 95px;
      }
      .center {
        text-align: center;
      }
      .mat-icon {
        margin: auto;
      }
      p + p {
        margin-top: 10px;
      }

      .documents-routing {
        visibility: hidden !important;
        display: none !important;
      }

      li:empty {
        display: none;
      }
    `,
  ],
})
export class LearningCenterWcComponent implements OnInit, AfterViewInit {
  @Input()
  isMgr: boolean;

  @Input()
  learningCenter: any;

  @Input()
  loading: boolean;
  isShown: boolean = false;
  @Input()
  claimClosed: boolean;

  scrollToElement = scrollToElement;
  @Input()
  lcType: string;
  isOpenfile: boolean = false;
  documentUrl: string;
  mappings: any;

  isIntroduction: boolean = false;
  isWhatisWC: boolean = false;
  isBenefitsCalc: boolean = false;
  isSubmittingClaim: boolean = false;
  isWhatsNext: boolean = false;
  isMedical: boolean = false;
  isReturnToWork: boolean = false;
  isThingsToKnow: boolean = false;

  @ViewChild('dynamicContentContainer') container: ElementRef;
  private unlistens: (() => void)[] = [];
  constructor(
    private session: Session,
    public learningCenterService: LearningCenterService,
    private dialog: MatDialog,
    public openFileService: OpenFileService,
    public router: Router,
    private bindEvents: DynamicEventBindService,
    public claimConfigService: ConfigurationService,
    private logService: LogService
  ) {}

  subs = new SubSink();

  async ngOnInit() {
    this.checkHeaderValues();
    this.mappings = [
      {
        selector: '.report-new-claim',
        action: this.reportClaim.bind(this),
      },
      {
        selector: '.medical-provider',
        action: this.openFileLink.bind(this),
      },
      {
        selector: '.go-to-personal-notif',
        route: '/PreferencesPage',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-comm-center',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'comm-center',
        },
      },
      {
        selector: '.go-to-pharmacy-card',
        route: '/pharmacy-card',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-documents-upload',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: true,
        },
      },
      {
        selector: '.go-to-documents',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'DOCUMENTS',
        },
      },
      {
        selector: '.go-to-documents-show',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: false,
        },
      },
    ];
    this.checkViaClaim();
  }

  ngAfterViewInit() {
    //angular's innerHTML attribute doesnt bind click events so have to do this
    this.unlistens = this.bindEvents.bindDynamicEvents(
      this.container,
      this.mappings
    );
  }

  openFileLink(event: MouseEvent) {
    event.preventDefault();
    const dialogRef = this.dialog.open(ExternalLinkDialogComponent);
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openFileService.openfile();
        this.logService.LogFeatureWithClaim(
          this.learningCenter.claimInfo.claimId,
          this.learningCenter.claimInfo.source,
          this.learningCenter.claimInfo.lob,
          'Find a Doctor',
          'Learning Center'
        );
      }
    });
  }

  reportClaim(event: MouseEvent) {
    event.preventDefault();
    reportNewClaim(this.dialog);
  }

  checkViaClaim() {
    if (this.learningCenter.claimInfo.claimId != null) {
      this.isShown = !this.isShown;
    }
  }

  ngOnDestroy(): void {
    this.unlistens.forEach((unlisten) => unlisten());
    this.subs.unsubscribe();
  }
  checkHeaderValues() {
    if (this.checkArrayValue(this.learningCenter.introductionResults, 0)) {
      this.isIntroduction = true;
    }
    if (this.checkArrayValue(this.learningCenter.introductionResults, 2)) {
      this.isWhatisWC = true;
    }
    if (this.checkArrayValue(this.learningCenter.benefits, 0)) {
      this.isBenefitsCalc = true;
    }
    if (this.checkArrayValue(this.learningCenter.whatsnexts, 0)) {
      this.isWhatsNext = true;
    }

    if (
      this.checkArrayValue(this.learningCenter.medicaltreatmentandproviders, 0)
    ) {
      this.isMedical = true;
    }

    if (this.checkArrayValue(this.learningCenter.returntoworks, 1)) {
      this.isReturnToWork = true;
    }

    if (this.checkArrayValue(this.learningCenter.thingstoknows, 0)) {
      this.isThingsToKnow = true;
    }
  }

  checkArrayValue(arr: any[], index: number) {
    const arrVal = arr.slice();
    if (index > 0) {
      arrVal.splice(index - 1, 1);
      return arrVal.some((obj) => obj.value !== '');
    } else {
      return arrVal.some((obj) => obj.value !== '');
    }
  }
}
