import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  EditAbsenceDialogComponent,
  RemoveAbsenceDialogComponent,
} from 'src/app/my-sedgwick';
import { CsvParams, CsvService } from 'src/app/shared';
import { CalendarEvent } from 'src/app/shared/models/CalendarEvent';
import {
  AbsenceService,
  DismissDialogComponent,
  IncidentalAbsence,
  IncidentalAbsenceReason,
} from '../..';
import { ConfigurationService } from '../../services/configuration.service';
import { srSpeak } from 'src/app/shared';

@Component({
  selector: 'app-absence-grid',
  template: `
    <div class="tw-w-full tw-flex tw-flex-wrap">
      <div class="tw-w-full" [hidden]="!showButtons">
        <mat-button-toggle-group
          name="displayMode"
          aria-label="Display Mode"
          value="list"
          #displayMode="matButtonToggleGroup"
        >
          <mat-button-toggle
            value="list"
            (change)="onValChange($event.value)"
            aria-label="Select to format absences in a list"
            ><mat-icon
              color="accent"
              aria-label="Select to format absences in a list"
              >list</mat-icon
            ></mat-button-toggle
          >
          <mat-button-toggle
            value="calendar"
            (change)="onValChange($event.value)"
            aria-label="Select to format absences in a calendar layout"
            ><mat-icon
              color="accent"
              aria-label="Select to format absences in a calendar layout"
              >date_range</mat-icon
            ></mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>

      <div class="app-pl2 tw-w-full md:tw-w-[20%]" *ngIf="searchEnabled"></div>

      <div class="app-pl2 tw-w-full md:tw-w-[20%]" *ngIf="searchEnabled">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="fromDate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="fromDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="app-pl1 tw-w-full md:tw-w-[20%]" *ngIf="searchEnabled">
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="toDate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="toDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div
        class="app-pl1 app-pt1 tw-w-full md:tw-w-[20%]"
        *ngIf="searchEnabled"
      >
        <app-button emphasis="High">Apply</app-button>
        <app-button emphasis="Low">Clear</app-button>
      </div>
    </div>

    <div *ngIf="displayMode.value === 'calendar'">
      <div class="app-pt2"></div>
      <app-calendar [events]="calendarevents"> </app-calendar>
    </div>
    <div
      *ngIf="displayMode.value === 'list'"
      class="tw-flex tw-flex-wrap app-pt2"
    >
      <app-list
        class="tw-w-full"
        [dataSource]="absences"
        [template]="row"
        [filterEnabled]="filterEnabled"
        [exportEnabled]="true"
        exportButtonAriaLabel="Export and download these absence/s as a XLSX file"
        [noDataIcon]="noDataIcon"
        [noDataMessage]="noDataMessage"
        [noDataThinMessage]="noDataThinMessage"
        [toastInfoMessage]="toastInfoMessage"
        [displayInfoToastMsg]="displayInfoToastMsg"
        (export)="export()"
        [tableAriaLabel]="'Absences'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full ">
            <app-list-header
              name="lastName"
              label="Name"
              class="tw-w-full md:tw-w-[22%]"
              [names]="['firstName', 'lastName', 'empId']"
            ></app-list-header>

            <app-list-header
              name="absenceReason"
              label="Reason"
              class="tw-w-full md:tw-w-[18%]"
            ></app-list-header>

            <app-list-header
              [names]="['absenceStartDate', 'absenceStartTime']"
              name="absenceStartDate"
              label="Start"
              class="tw-w-full md:tw-w-[15%]"
              filterType="Date"
            ></app-list-header>

            <app-list-header
              [names]="['absenceEndDate', 'absenceEndTime']"
              name="absenceEndDate"
              label="End"
              class="tw-w-full md:tw-w-[15%]"
              filterType="Date"
            ></app-list-header>

            <app-list-header
              name="estimatedRTWDate"
              label="Detail"
              class="tw-w-full md:tw-w-[25%]"
              [names]="['referenceNumber', 'estimatedRtw', 'shifts']"
            ></app-list-header>

            <app-list-header
              name="action"
              label="Actions"
              class="tw-w-full md:tw-w-[5%]"
              [ngClass]="IsActionEnable ? 'tw-block' : 'tw-hidden'"
              [sortEnabled]="false"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>
    </div>
    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full ">
        <td class="tw-w-full md:tw-w-[22%]">
          <app-value label="Name" layout="row-xs">
            <div value>
              <a
                routerLink=""
                (click)="
                  navigateToAbsenceEmp(
                    element.empUnum,
                    element.firstName,
                    element.lastName,
                    element.empId
                  )
                "
                *ngIf="employeeLinkEnabled; else noLink"
              >
                <b>
                  {{ element.firstName }} {{ element.lastName }} -
                  {{ element.empId }}</b
                >
              </a>
              <ng-template #noLink>
                <b
                  >{{ element.firstName }} {{ element.lastName }} -
                  {{ element.empId }}</b
                >
              </ng-template>
            </div>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[18%]">
          <app-value
            label="Reason"
            layout="row-xs"
            [tabindex]="element.absenceReason ? '0' : '-1'"
          >
            <div value>
              <div class="tw-w-full">
                {{ element.absenceReason }} - {{ element.typeOfCallOff }}
              </div>
            </div>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Start"
            [tabindex]="element.absenceStartDate ? '0' : '-1'"
            layout="row-xs"
            [value]="element.absenceStartDate | format : 'dateTime'"
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            [tabindex]="element.absenceEndDate ? '0' : '-1'"
            label="End"
            layout="row-xs"
            [value]="element.absenceEndDate | format : 'dateTime'"
          >
          </app-value>
        </td>

        <td class="tw-w-full md:tw-w-[25%] pmt-header-init">
          <app-value
            [tabindex]="'0'"
            label="Detail"
            labelStyle="pmt-header-init"
            layout="row-xs"
          >
            <div value>
              <div class="tw-w-full" *ngIf="!hideRef">
                <b class="tw-w-full">Reference: </b>
                {{ element.referenceNumber }}
              </div>
              <div class="tw-w-full">
                <b>Return Date: </b>
                {{ element.estimatedRTWDate | format : 'dateTime' }}
              </div>
              <div class="tw-w-full">
                <b>Work Days Absent: </b>{{ element.shiftsMissed }}
              </div>
            </div>
          </app-value>
        </td>

        <app-value
          class="tw-w-full md:tw-w-[5%]"
          label="Actions"
          layout="row-xs"
          [ngClass]="IsActionEnable == true ? 'tw-block' : 'tw-hidden'"
        >
          <app-icon-button
            value
            mat-icon-button
            color="accent"
            icon="more_vert"
            label="Actions"
            [attr.aria-label]="
              'Actions menu for ' +
              element.firstName +
              ' ' +
              element.lastName +
              ' absence'
            "
            [matMenuTriggerFor]="actionMenu"
            [attr.aria-expanded]="isActionsMenuExpanded"
            tabindex="0"
            role="button"
            (click)="toggleMenu()"
            (keydown.enter)="toggleMenu()"
          >
            <!-- <mat-icon color="accent">more_vert</mat-icon> -->
          </app-icon-button>
          <mat-menu #actionMenu="matMenu" role="menu" (closed)="toggleMenu()">
            <button mat-menu-item (click)="openEditAbsencePopup(element)">
              <mat-icon color="accent">open_in_new</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="openRemoveAbsencePopup(element)">
              <mat-icon color="accent">highlight_off</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </app-value>
      </tr>
    </ng-template>
  `,
  styles: [
    `
      .heading-vtop {
        vertical-align: top;
      }
    `,
  ],
})
export class AbsenceGridComponent implements OnInit {
  @Input()
  absences: IncidentalAbsence[];

  @Input()
  reasonData: IncidentalAbsenceReason[];

  calendarevents: CalendarEvent[] = [];

  @Input()
  searchEnabled: boolean = false;

  @Input()
  employeeLinkEnabled: boolean = true;

  @Input()
  IsManager: boolean = false;

  @Input()
  showButtons: boolean = true;

  @Input()
  filterEnabled: boolean = false;

  @Input()
  noDataIcon: string = 'search';
  @Input()
  noDataMessage: string = 'There are no absences.';

  @Input()
  noDataThinMessage: string = '';

  IsActionEnable: boolean = true;

  @Input()
  toastInfoMessage: string = '';

  @Input()
  displayInfoToastMsg: boolean = false;
  /*
  @Input()
  disableActions: boolean = false; */

  @Input()
  hideRef: boolean = false;

  isActionsMenuExpanded: boolean = false;

  constructor(
    public dialog: MatDialog,
    private csvService: CsvService,
    private config: ConfigurationService,
    private absenceservice: AbsenceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.IsActionEnable = this.config.getConfigFeatureAccess(
      'UpdateIncidentalAbsences5'
    );
  }

  onValChange(value: any) {
    if (value == 'calendar') {
      this.calendarevents = [];
      this.calendarevents = this.absenceservice.generateEventsFromAbsence(
        this.absences,
        false
      );
    }
  }

  dismiss() {
    this.dialog.open(DismissDialogComponent, {
      data: {
        title: 'Cancel Absence',
        message: 'Press Confirm to cancel this absence.',
      },
    });
  }

  export() {
    const csvParams = new CsvParams();
    csvParams.includeTimeWithDates = true;
    csvParams.rows = this.absences;
    if (this.hideRef) {
      csvParams.keys = [
        'firstName',
        'lastName',
        'empId',
        'absenceReason',
        'typeOfCallOff',
        'absenceStartDate',
        'absenceEndDate',

        'estimatedRTWDate',
        'shiftsMissed',
      ];
      csvParams.headers = [
        'First Name',
        'Last Name',
        'Employee Id',
        'Reason',
        'Secondary Reason',
        'Start',
        'End',

        'Return Date',
        'Work Days Absent',
      ];
    } else {
      csvParams.keys = [
        'firstName',
        'lastName',
        'empId',
        'absenceReason',
        'typeOfCallOff',
        'absenceStartDate',
        'absenceEndDate',
        'absenceNumber',
        'estimatedRTWDate',
        'shiftsMissed',
      ];
      csvParams.headers = [
        'First Name',
        'Last Name',
        'Employee Id',
        'Reason',
        'Secondary Reason',
        'Start',
        'End',
        'Reference',
        'Return Date',
        'Work Days Absent',
      ];
    }
    this.csvService.download(csvParams);
  }

  openEditAbsencePopup(absence: IncidentalAbsence) {
    const dialogRef = this.dialog.open(EditAbsenceDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
    instance.reasonData = this.reasonData;
  }

  openRemoveAbsencePopup(absence: IncidentalAbsence) {
    const dialogRef = this.dialog.open(RemoveAbsenceDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
  }

  async navigateToAbsenceEmp(
    empUnum: any,
    firstName: any,
    lastName: any,
    empId: any
  ) {
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('empId');
    sessionStorage.setItem('firstName', firstName);
    sessionStorage.setItem('lastName', lastName);
    sessionStorage.setItem('empId', empId);

    if (empUnum != null && empUnum != undefined) {
      this.router.navigate(['absences'], {
        queryParams: {
          empunum: empUnum,
        },
      });
    }
    /*  reportClaim() {
      this.dialog.open(this.dialogRefReportNewClaim);
    }
 */
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
