import { Injectable } from '@angular/core';
import {
  FormContent,
  SearchResult,
  blobToFile,
  getUrlParams,
} from 'src/app/shared';
import { AnswersKey, ClaimService, FormApi, LogApi, LogMessage } from '..';
import jsPDF from 'jspdf';
import { FormParts } from '../components/form/form-parts';
import { ActivityService } from './activity.service';
import { SendMessage } from '../models/Communications';

/**
 * Provides state and notification for the active claim.
 * Used by claims page components for notification of claim
 * selection.
 */
@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(
    private formApi: FormApi,
    private logApi: LogApi,
    public claimService: ClaimService,
    private activityService: ActivityService
  ) {}

  formId: string;
  claimId: string;
  source: string;
  flNum: string;
  type: string;
  name: string;
  doc: string;
  html: string;
  loaded: boolean = false;
  english: boolean = true;
  downloadOnly: boolean = false;
  textBin: any;
  textPlain: string;
  pdfBin: any;
  lob: string;
  vendorId: string;
  state: string;
  claim: SearchResult;
  examiner: string;

  async init() {
    const GROUP_TEMPLATE = '{group-unique-id}';
    const params = getUrlParams();
    this.formId = params.formId;
    this.claimId = params.claimId;
    this.source = params.source;
    this.flNum = params.flNum;
    this.type = params.type;
    this.name = params.name;
    this.doc = params.doc;
    this.loaded = false;
    this.claim = await this.claimService.getSummary(this.source, this.claimId);
    this.lob = this.claim.lob;
    this.examiner = this.claim.examinerName;
    const template = await this.formApi.getTemplate(
      this.source,
      this.claimId,
      this.claim.lob,
      this.claim.stateofJurisdiction,
      this.claim.claimNumber,
      this.name
    );

    if (
      this.flNum.toLowerCase().includes('.pdf') &&
      !this.flNum.toLowerCase().includes('.htm')
    ) {
      const pdf = await this.formApi.getPdf(
        this.source,
        this.claimId,
        this.claim.lob,
        this.claim.stateofJurisdiction,
        this.claim.claimNumber,
        this.name
      );
      this.pdfBin = pdf;
      const blobURL = URL.createObjectURL(pdf);
      // window.open(blobURL, '_blank');
      this.html = `<embed src="${blobURL}" style="max-width:800px; max-height:1075px; width:85vw; height:120vw;" />`;
    } else if (!template.html || template.html.length === 0) {
      this.html = 'NOT FOUND';
    } else {
      // contain form style overides so they only effect the form
      const containStyle = 'inner-web-form';
      const groups = /<style[^>]*>(?<css>.+)<\/style>/s.exec(
        template.html
      )?.groups;
      if (groups && groups['css']) {
        const css = groups['css'];
        let newcss = css.replaceAll(
          /(?<space>[ \t\r\n]*)(?<style>[^{]+{[^}]+})/gs,
          '$<space>& $<style>'
        );
        newcss = newcss.replaceAll(/\bbody\b/gs, '.body');
        template.html = template.html.replace(
          css,
          '\r\n.' + containStyle + ' {\r\n' + newcss + '\r\n}\r\n'
        );
        template.html = template.html.replace(
          '<html>',
          '<div class="' + containStyle + '">'
        );
        template.html = template.html.replace('</html>', '</div>');
        template.html = template.html.replace('<body>', '<div class="body">');
        template.html = template.html.replace('</body>', '</div>');
      }

      if (this.needsAuthorize(this.type)) {
        template.html =
          '<div style="white-space: pre-wrap;">' + template.html + '</div>';
      }

      // form parts
      for (const [key, value] of FormParts.entries()) {
        template.html = template.html.replaceAll('{{' + key + '}}', value);
      }
      let group = 0;
      while (template.html.indexOf(GROUP_TEMPLATE) != -1) {
        // each group has 6 ids that need to match
        for (let g = 0; g < 6; g++) {
          template.html = template.html.replace(
            GROUP_TEMPLATE,
            group.toString()
          );
        }
        group++;
      }
      // answers
      const answers = await this.formApi.getValues(
        this.source,
        this.claimId,
        this.claim.lob
      );
      this.html = template.html;
      window.requestAnimationFrame(() => {
        for (const answer of answers) {
          const elements = Array.from(
            document.querySelectorAll(
              '#' + answer.key + ', input[name="' + answer.key + '"]'
            )
          ) as Array<HTMLInputElement>;
          for (const element of elements) {
            if (element) {
              element.setAttribute('value', answer.value?.toString());
              if (answer.value && answer.value?.toString().length > 0) {
                // business rule, all answers from the server are to be read only
                element.setAttribute('data-readonly', 'true');
                this.setReadOnlyElement(true, element);
                if (element.type === 'radio' || element.type === 'checkbox') {
                  element.checked = answer.value as unknown as boolean;
                }
              } else {
                element.classList.add('web-form');
                if (answer.required) {
                  element.classList.add('web-form-required');
                  element.required = true;
                  if (element.type === 'radio' || element.type === 'checkbox') {
                    // check for an existing web-form-group
                    let belongsToWebFormGroup = false;
                    let e: Element = element;
                    while (!belongsToWebFormGroup && e.parentElement) {
                      e = e.parentElement;
                      if (e.classList.contains('web-form-group')) {
                        belongsToWebFormGroup = true;
                      }
                    }
                    if (!belongsToWebFormGroup) {
                      element.outerHTML =
                        '<span class="sub-text blue-required"><span class="web-form web-form-group">' +
                        element.outerHTML +
                        '</span></span>';
                    }
                  } else {
                    element.outerHTML =
                      '<span class="sub-text blue-required">' +
                      element.outerHTML +
                      '</span>';
                  }
                } else if (
                  element.type === 'radio' ||
                  element.type === 'checkbox'
                ) {
                  element.outerHTML =
                    '<span class="sub-text"><span class="web-form web-form-group">' +
                    element.outerHTML +
                    '</span></span>';
                }
              }
            }
          }
        }
        this.loadScripts();
      });

      // log any missing templates or answers
      const MISSING_EXP = new RegExp('{{[^}]+}}', 'g');
      const matches = document.documentElement.innerHTML.match(MISSING_EXP);
      if (matches) {
        const log: LogMessage = new LogMessage();
        log.message = 'Missing template or answer for:';
        matches.forEach((match) => {
          log.message += ' ' + match;
          document.documentElement.innerHTML.replaceAll(match, '');
        });
        this.logApi.error(log);
      }

      // prep validation for html templates
      window.requestAnimationFrame(() => {
        const plainInputs = Array.from(
          document.querySelectorAll('input[type="text"]')
        );
        for (const plain of plainInputs) {
          if (!plain.classList.contains('web-form')) {
            plain.classList.add('web-form');
          }
          if (
            !plain.classList.contains('web-form-readonly') &&
            plain.id.toLowerCase().indexOf('date') !== -1
          ) {
            plain.outerHTML =
              '<span class="sub-text"><input id="' +
              plain.id +
              '" type="date" class="web-form' +
              (plain.classList.contains('web-form-required')
                ? ' web-form-required'
                : '') +
              '" style="width:125px;"' +
              (plain.classList.contains('web-form-required')
                ? ' required="required"'
                : '') +
              '></span>';
          }
        }

        // set validation
        const requireds = document.querySelectorAll('[required]');
        requireds.forEach((required) => {
          required.addEventListener('blur', this.validateElement);
          required.addEventListener('change', this.validateElement);
          required.addEventListener('keyup', this.validateElement);
        });
      });
    }
    this.assignHandlers();
    this.loaded = true;
  }

  loadScripts() {
    const scripts = Array.from(document.querySelectorAll('script'));
    for (const script of scripts) {
      if (script.innerText.length > 0) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.appendChild(document.createTextNode(script.innerText));
        document.body.appendChild(s);
      }
    }
    if (this.downloadOnly) {
      this.doDownloadOnly();
    }
  }

  validateElement(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.type === 'radio' || target.type === 'checkbox') {
      const group = Array.from(
        document.querySelectorAll('input[name="' + target.name + '"]')
      );
      for (const eachGroup of group) {
        const blue = eachGroup.parentElement.querySelector('.blue-required');
        if (group.some((_) => (_ as HTMLInputElement).checked)) {
          eachGroup.parentElement.classList.remove('web-form-error');
          if (blue) {
            blue.classList.remove('web-form-error');
          }
        } else {
          if (blue) {
            blue.classList.add('web-form-error');
          }
          eachGroup.parentElement.classList.add('web-form-error');
        }
      }
    } else {
      if (target.value.length === 0) {
        target.classList.add('web-form-error');
      } else {
        target.classList.remove('web-form-error');
      }
    }
  }

  toggleLanguage() {
    this.english = !this.english;
    const en: any = document.getElementById('englishsection');
    const es: any = document.getElementById('spanishsection');
    en.style.display = this.english ? 'block' : 'none';
    es.style.display = !this.english ? 'block' : 'none';
  }

  assignHandlers() {
    setTimeout(() => {
      const es: any = document.getElementById('asanchor');
      const en: any = document.getElementById('aeanchor');
      if (es && en) {
        es.removeAttribute('href');
        es.addEventListener('click', this.toggleLanguage.bind(this));
        en.removeAttribute('href');
        en.addEventListener('click', this.toggleLanguage.bind(this));

        // setting english to false so it's toggled to true.
        this.english = false;
        this.toggleLanguage();
      }
    }, 100);
  }

  async validate() {
    let valid = true;
    const requireds = document.querySelectorAll('[required].web-form-required');
    requireds.forEach((required) => {
      const target = required as HTMLInputElement;
      target.focus();
      target.blur();
    });
    const errors = document.querySelectorAll('.web-form-error');
    if (errors.length > 0) {
      valid = false;
      const target = errors[0] as HTMLInputElement;
      if (target.classList.contains('web-form-group')) {
        (target.firstElementChild as HTMLInputElement).focus();
      } else {
        target.focus();
      }
    }
    if (valid) {
      await this.createBlobs();
    }
    return valid;
  }

  setReadOnly(readOnly: boolean) {
    document.querySelectorAll('.web-form').forEach((el) => {
      if (readOnly) {
        el.classList.add('web-form-readonly');
      } else {
        if (!el.hasAttribute('data-readonly')) {
          el.classList.remove('web-form-readonly');
        }
      }

      if (el.nodeName === 'SPAN') {
        el.querySelectorAll('input').forEach((el) => {
          this.setReadOnlyElement(readOnly, el);
        });
      } else {
        this.setReadOnlyElement(readOnly, el);
      }
    });
  }

  setDownloadOnly(downloadOnly: boolean) {
    this.downloadOnly = downloadOnly;
  }

  doDownloadOnly() {
    document.querySelectorAll('.web-form').forEach((el) => {
      el.setAttribute('readonly', 'readonly');
      el.setAttribute('disabled', 'disabled');
      el.classList.add('web-form-readonly');
      el.classList.remove('web-form');
      el.classList.remove('web-form-required');
      if (el.getAttribute('placeholder')) {
        el.setAttribute(
          'placeholder',
          el.getAttribute('placeholder').replace('*', '')
        );
      }
    });
    document.querySelectorAll('.blue-required').forEach((el) => {
      el.classList.remove('blue-required');
    });
  }

  setReadOnlyElement(readOnly: boolean, el: Element) {
    if (el) {
      const temp: any = el;
      if (readOnly) {
        temp.setAttribute('readonly', '');
        temp.setAttribute('disabled', 'disabled');
        temp.classList.add('web-form-readonly');
      } else {
        if (!el.hasAttribute('data-readonly')) {
          temp.removeAttribute('readonly');
          temp.removeAttribute('disabled');
          temp.classList.remove('web-form-readonly');
        }
      }
    }
  }

  submit() {
    if (!this.notSIR(this.type)) {
      this.formApi.sendToSIR(
        this.source,
        this.claimId,
        this.claim,
        this.getFileName() + '.pdf',
        this.pdfBin
      );
    }

    const form: FormContent = {
      html: 'this.textPlain /* can remove the quotes if this message is needed again */',
    }; // message text no longer needed
    this.formApi.sendToJURIS(
      this.source,
      this.claimId,
      this.lob,
      this.examiner,
      this.name,
      this.claim.stateofJurisdiction.length
        ? this.claim.stateofJurisdiction
        : 'xx',
      form
    );

    let notifyType = '';
    if (this.name.toLowerCase().includes('medicare consent')) {
      notifyType = 'MC_NEEDED_A';
    }
    if (this.name.toLowerCase().includes('medicare beneficiary')) {
      notifyType = 'MROI_NEEDED_A';
    }
    if (this.name.toLowerCase().includes('medical authorization')) {
      notifyType = 'ROI_NEEDED_A';
    }
    if (this.name.toLowerCase().includes('reimbursement agreement')) {
      notifyType = 'ROR_NEEDED_A';
    }
    if (notifyType) {
      this.activityService.DismissNotificationAlert(
        notifyType,
        this.claim.claimNumber,
        this.claim.claimId,
        this.source,
        this.claim.userOwns
      );
    }
  }

  async createBlobs(keepElements = false) {
    const temp = document
      .getElementById('form-container')
      .cloneNode(true) as HTMLElement;
    temp.setAttribute('id', 'temp');
    const styles = temp.querySelectorAll(
      '.web-form,.web-form-readonly,.web-form-required,.blue-required,.sub-text'
    );
    styles.forEach((element) => {
      element.classList.remove('web-form');
      element.classList.remove('web-form-readonly');
      element.classList.remove('web-form-required');
      element.classList.remove('blue-required');
      element.classList.remove('sub-text');
    });

    // values to text
    const PIX_PER_CHAR = 7.6;
    let text = '';

    const texts = temp.querySelectorAll('input[type="text"]');
    texts.forEach((element) => {
      let padding =
        parseInt((element as HTMLInputElement).style.width) / PIX_PER_CHAR;
      if (!keepElements) {
        padding = 0;
      }
      const parentHtml = element.outerHTML.replace(
        element.outerHTML,
        '<span class="web-form-ink">' +
          (element as HTMLInputElement).value.padEnd(
            padding,
            keepElements ? '_' : ' '
          ) +
          '</span>'
      );
      const parent = element.parentElement;
      if (parent.parentElement) {
        element.remove();
        parent.outerHTML = parentHtml;
      }
    });
    const checkboxs = temp.querySelectorAll('input[type="checkbox"]');
    checkboxs.forEach((element) => {
      const box = (element as HTMLInputElement).checked
        ? '[<span class="web-form-ink">x</span>]'
        : '[ ]';
      element.outerHTML = element.outerHTML.replace(element.outerHTML, box);
    });
    const dates = temp.querySelectorAll('input[type="date"]');
    dates.forEach((element) => {
      element.outerHTML = element.outerHTML.replace(
        element.outerHTML,
        (element as HTMLInputElement).valueAsDate
          ? '<span class="web-form-ink">' +
              ((element as HTMLInputElement).valueAsDate.getUTCMonth() + 1) +
              '/' +
              (element as HTMLInputElement).valueAsDate.getUTCDate() +
              '/' +
              (element as HTMLInputElement).valueAsDate.getUTCFullYear() +
              '</span>'
          : ''
      );
    });
    const areas = temp.querySelectorAll('textarea');
    areas.forEach((element) => {
      element.outerHTML = element.outerHTML.replace(
        element.outerHTML,
        '<span class="web-form-ink">' +
          (element as HTMLTextAreaElement).value +
          '\r\n</span>'
      );
    });

    const radios = temp.querySelectorAll('input[type="radio"]');
    radios.forEach((radio) => {
      let radioTemp = '';
      radioTemp += (radio as HTMLInputElement).checked
        ? '(<span class="web-form-ink">x</span>)'
        : '( )';
      radio.outerHTML = radio.outerHTML.replace(radio.outerHTML, radioTemp);
    });

    const tables = temp.querySelectorAll('table');
    tables.forEach((table) => {
      table.outerHTML = table.innerText;
    });

    const hideTags = Array.from(temp.querySelectorAll('style,script'));
    for (const element of hideTags) {
      if (!element.hasAttribute('data-keep')) {
        (element as HTMLElement).innerText = '';
      }
    }

    // nuke all the extra white space and unicode
    text = temp.innerText.replaceAll(/^[ \t\r\n]+$/gm, '');
    text = text.replaceAll(/^[ \t]+/gm, '\t');
    // eslint-disable-next-line no-control-regex
    text = text.replaceAll(/[^\x00-\x7F]/gm, '');
    const decoder = document.createElement('div');
    decoder.innerHTML = text;
    const sanitized = decoder.textContent;
    this.textPlain = sanitized;
    this.textBin = new Blob([text], { type: 'text/plain' });

    // pdf
    const answers = new Array<AnswersKey>();
    const elements = Array.from(document.querySelectorAll('input'));
    for (const element of elements) {
      const answer = new AnswersKey();
      answer.id = element.id;
      if (element.type === 'radio' || element.type === 'checkbox') {
        answer.value = element.checked.toString();
      } else {
        answer.value = element.value;
      }
      answers.push(answer);
    }
    if (!this.notSIR(this.type)) {
      this.pdfBin = await this.formApi.savePdf(
        this.flNum,
        !this.english,
        answers
      );
    }

    // if we did not have a pdf to fill out we will make one from the html
    if (!this.pdfBin || this.pdfBin.size === 0) {
      const pdf = new jsPDF('p', 'pt', 'letter');
      text = temp.innerHTML; // temp.innerHTML is with formatting // the original `text` can be used if you want plain text
      text = text.replaceAll('<br>', '</div><div class="web-form-paragraph">');
      text = this.maskSocials(text);
      await pdf
        .html(
          `<div style="width: 5.8in; font-size: 9px; font-family: helvetica;"><div class="web-form-paragraph">${text}</div></div>`,
          {
            // margin [left, top, right ,bottom]
            margin: [20, 30, 20, 30],
            autoPaging: 'text',
          }
        )
        .then(() => (this.pdfBin = pdf.output('blob')));
    }
  }

  maskSocials(answer: string) {
    const ssn = RegExp('\\b[0-9]{3}-[0-9]{2}-([0-9]{4})\\b', 'gi');

    let match = ssn.exec(answer);
    while (match) {
      answer = answer.replaceAll(match[0], '***-**-' + match[1]);
      match = ssn.exec(answer);
    }

    return answer;
  }

  getFileName() {
    const today = new Date();
    let fileName = this.name.replaceAll('%20', '_').replaceAll(' ', '_');
    fileName +=
      '_' +
      today.getFullYear() +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      ('0' + today.getDate()).slice(-2);
    return fileName;
  }

  savetoText() {
    blobToFile(this.textBin, 'text/plain', this.getFileName() + '.txt');
  }

  savetoPdf() {
    blobToFile(this.pdfBin, 'application/pdf', this.getFileName() + '.pdf');
  }

  needsAuthorizeAndYesNo(formType: string) {
    const types = ['release of information', 'reimbursement'];
    if (!formType) {
      formType = this.name;
    }
    return types.includes(formType?.trim().toLowerCase());
  }

  needsInitials(formType: string) {
    const types = ['release of information', 'reimbursement'];
    if (!formType) {
      formType = this.name;
    }
    return types.includes(formType?.trim().toLowerCase());
  }

  needsAuthorize(formType: string) {
    const types = ['release of information', 'reimbursement'];
    if (!formType) {
      formType = this.name;
    }
    return types.includes(formType?.trim().toLowerCase());
  }

  notSIR(formType: string) {
    const types = ['release of information', 'reimbursement'];
    if (!formType) {
      formType = this.name;
    }
    return types.includes(formType?.trim().toLowerCase());
  }

  async saveMessage(
    source: string,
    claimId: string,
    lob: string,
    sendMessageRequest: SendMessage
  ): Promise<boolean> {
    return await this.formApi.saveMessage(
      source,
      claimId,
      lob,
      sendMessageRequest
    );
  }
}
