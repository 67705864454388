import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { BaseControlComponent, ManagerResult, srSpeak } from 'src/app/shared';
import { SearchApi, Session } from '../..';

@Component({
  selector: 'app-manager-selector',
  template: `
    <form
      [formGroup]="formGroup"
      class="tw-w-full tw-flex tw-flex-wrap tw-items-center"
    >
      <mat-form-field
        [hintLabel]="hintLabel"
        appearance="outline"
        class="tw-bg-white tw-w-64 tw-mr-1"
        subscriptSizing="dynamic"
      >
        <mat-label>{{ label }}</mat-label>
        <input
          aria-controls="ResultsListContainer"
          matInput
          #searchInput
          [placeholder]="placeHolder"
          [attr.aria-expanded]="false"
          aria-haspopup="listbox"
          aria-controls="ResultsListContainer"
          [matAutocomplete]="auto"
          [formControlName]="formControlName"
        />
        <mat-error *ngIf="formControl.invalid">{{ errorMessage }}</mat-error>
        <mat-autocomplete
          id="ResultsListContainer"
          #auto="matAutocomplete"
          [autoActiveFirstOption]="false"
          requireSelection="true"
          hideSingleSelectionIndicator="true"
          (optionSelected)="optionSelected($event)"
          [displayWith]="getFullName"
          panelWidth="auto"
        >
          <mat-option
            *ngFor="let option of managers"
            [value]="option"
            class="tw-min-w-[256px]"
            [disabled]="option.employeeId === '__NONE'"
          >
            <span *ngIf="option.employeeId === '__NONE'">
              {{ option.firstName }}
            </span>
            <span *ngIf="option.employeeId !== '__NONE'" class="">
              {{ option.firstName }} {{ option.lastName }} -
              {{ option.employeeId }}
            </span>
          </mat-option>
        </mat-autocomplete>

        <button
          mat-icon-button
          matSuffix
          class="cglass"
          color="accent"
          [disabled]="true"
          aria-label="Search"
          *ngIf="!loading && !formControl.value"
        >
          <mat-icon>search</mat-icon>
        </button>

        <div matSuffix *ngIf="loading" class="tw-pr-4">
          <div class="app-spinner-grey"></div>
        </div>

        <button
          mat-icon-button
          matSuffix
          color="accent"
          role="button"
          *ngIf="!loading && (managerSelected || filteredManagerInSession)"
          aria-label="Remove filtered manager"
          (click)="clearSearch()"
          (keydown.enter)="clearSearch()"
        >
          <mat-icon *ngIf="formControl.value">highlight_off</mat-icon>
        </button>
      </mat-form-field>
    </form>
  `,
  styles: [
    `
      .close-icon {
        height: 18px;
        width: 18px;
        font-size: 18px;
        cursor: pointer;
        padding-left: 4px;
      }

      ::ng-deep .mat-mdc-option:focus.mdc-list-item,
      .mat-mdc-option.mat-mdc-option-active {
        border: 2px solid #67707a !important;
      }
    `,
  ],
})
export class ManagerSelectorComponent
  extends BaseControlComponent
  implements OnInit
{
  @Input() managerLookupSupervisor: boolean = true;
  @Input() managerLookupOmni: boolean = false;
  @Input() isDelegatorSearch: boolean = false;
  @Output()
  supervisorSelected = new EventEmitter<ManagerResult>();

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  managers: ManagerResult[] = [];

  managerSelected = false;

  loading = false;

  currentSearch: string;

  isFocused: boolean = false;

  filteredManagerInSession: any;

  ngOnInit(): void {
    this.filteredManagerInSession = sessionStorage.getItem(
      this.session.user.sessionId + 'filteredManager'
    );

    this.subs.sink = fromEvent<InputEvent>(
      this.searchInput.nativeElement,
      'input'
    )
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((x) => {
        const name: string = (<any>x.target).value;
        this.search(name);
      });
  }

  constructor(private searchApi: SearchApi, private session: Session) {
    super();
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.managerSelected = true;
    this.formControl.setValue(event.option.value);
    this.supervisorSelected.emit(event.option.value);
  }

  getFullName(manager: ManagerResult) {
    if (!manager) {
      return null;
    } else {
      return `${manager.firstName} ${manager.lastName}`;
    }
  }

  clearSearch() {
    this.formControl.setValue(null);
    this.managerSelected = false;
    this.managers = [];
    this.supervisorSelected.emit(null);
  }

  search(name: string) {
    this.currentSearch = name;
    this.managers = [];
    if (typeof name === 'string') {
      if (name) {
        this.loading = true;
        srSpeak('Searching...', 'polite');
        const searchPromise = this.isDelegatorSearch
          ? this.searchApi.getDelegators(name)
          : this.searchApi.getManagers(
              name,
              this.managerLookupSupervisor,
              this.managerLookupOmni
            );

        searchPromise.then((managerSearch) => {
          // discard previous in-flight searches.
          if (managerSearch.searchFilter !== this.currentSearch) {
            return;
          }

          this.loading = false;
          this.managers = managerSearch.managers;
          if (this.managers.length === 0) {
            srSpeak('No results found for ' + name, 'polite');
          } else {
            srSpeak(
              this.managers.length + ` manager/s found for ${name}`,
              'polite'
            );
          }
        });
      }
    }
  }
}
