import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { OverlayMode, ProgressService, SearchResult } from 'src/app/shared';
import { RestrictionTable } from '../../models/Restrictions';
import { RTWResponseStatus } from '../../models/rtw-response';
import { ClaimService } from '../../services/claim.service';
import { emulator } from '../../services/emulator.service';
import { LogService } from '../../services/log.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { Restriction } from './restriction-detail.component';
import { RestrictionsComponent } from './restrictions.component';

@Component({
  selector: 'app-return-to-work-edit',
  template: `
    <div class="app-pb2 tw-w-full">
      <form [formGroup]="formGroup">
        <div class="tw-flex tw-flex-wrap">
          <div class="tw-w-[40%]">
            <app-claimant-label
              value
              [claimant]="claim"
              [showNameLabel]="true"
              [nameClass]="'headingbold'"
              [disabled]="!userRole.manager"
            >
            </app-claimant-label>
          </div>
          <div class="app-pb2 tw-w-[40%]">
            <app-claim-label
              value
              [claim]="claim"
              layout="row"
              [disabled]="true"
              [showNameLabel]="true"
              [noLink]="true"
            ></app-claim-label>
          </div>
          <div class="app-pb2 tw-w-[20%] fullPanelFL headingbold">
            * Required
          </div>

          <div class="tw-w-full tw-flex tw-flex-wrap">
            <app-container-control
              ngDefaultControl
              [formGroup]="formGroup"
              formControlName="noReturn"
              class="app-pb2"
              *ngIf="showDidNotReturn"
            >
              <mat-checkbox formControlName="noReturn"
                >Employee did not return to work</mat-checkbox
              >
            </app-container-control>

            <div class="tw-w-full md:tw-max-w-[30%]">
              <app-date-control
                label="Return to Work Date *"
                ngDefaultControl
                [formGroup]="formGroup"
                formControlName="rtwDate"
              ></app-date-control>
            </div>

            <div class="tw-w-full">
              <app-container-control
                ngDefaultControl
                [formGroup]="formGroup"
                formControlName="duty"
                class="app-pb3 headingbold"
                label="RETURN TO WORK TYPE *"
              >
                <mat-radio-group
                  aria-label="Full duty or restricted duty"
                  role="radiogroup"
                >
                  <mat-radio-button value="Full Duty"
                    >Full Duty</mat-radio-button
                  >
                  <span class="app-pl2"></span>
                  <mat-radio-button value="Restricted Duty"
                    >Restricted Duty</mat-radio-button
                  >
                </mat-radio-group>
                <mat-error *ngIf="!Duty.valid" class="errormsg">{{
                  getErrorMessage()
                }}</mat-error>
              </app-container-control>
            </div>
          </div>
          <div class="tw-w-full md:tw-max-w-[30%]">
            <app-text-area-control
              label="Addtional Comments"
              ngDefaultControl
              [formGroup]="formGroup"
              formControlName="comments"
              [maxLength]="500"
              [rows]="3"
            ></app-text-area-control>
          </div>
        </div>
      </form>
    </div>
  `,
  styles: [
    `
      .mat-mdc-radio-button:focus {
        border: 1px solid #007dbc;
      }
      .errormsg {
        color: #c24533;
      }
      @media (max-device-width: 480px) {
        ::ng-deep .mat-mdc-text-field-wrapper {
          /* width : 250%;*/
        }
      }
    `,
  ],
})
export class ReturnToWorkEditComponent {
  restrictionTable: RestrictionTable[];

  @Input()
  showDidNotReturn: boolean = false;

  @Input()
  ClaimID: string;

  @Input()
  LOB: string;

  @Input()
  claim: SearchResult;

  @Input()
  hideHeader: boolean = false;

  @Output()
  AfterSuccess: EventEmitter<RTWResponseStatus> =
    new EventEmitter<RTWResponseStatus>();
  restrictionTables: RestrictionTable[];
  restriction: Restriction;

  get Duty() {
    return this.formGroup.get('duty');
  }

  IsValidDuty: boolean = false;

  formGroup = this.fb.group({
    rtwDate: [''],
    duty: [''],
    comments: [],
    noReturn: [],
  });

  @ViewChild('restrictionChild') restrictionChild: RestrictionsComponent;

  constructor(
    public session: Session,
    private fb: FormBuilder,
    public overlayService: ProgressService,
    public logService: LogService,
    private claimService: ClaimService,
    private enumalatorService: emulator,
    public userRole: UserRoleService
  ) {}

  getErrorMessage() {
    return 'Error : Return to Work Type is required.';
  }

  IsErrorMessage() {
    return this.IsValidDuty;
  }

  async submitreturntowork() {
    if (
      this.formGroup.controls.rtwDate.value == '' ||
      this.formGroup.controls.rtwDate.value == undefined
    ) {
      this.formGroup.controls.rtwDate.setErrors({
        'Error : New Return to Work Date is required.': true,
      });
      this.formGroup.controls.rtwDate.markAsTouched();
    }

    if (
      this.formGroup.controls.duty.value == '' ||
      this.formGroup.controls.duty.value == undefined
    ) {
      this.formGroup.controls.duty.setErrors({
        'Error : Return to Work Type is required.': true,
      });
      this.IsValidDuty = true;
    }

    if (this.formGroup.valid) {
      this.enumalatorService.showAuditorInfo();
      this.overlayService.show(OverlayMode.Spinner, 'Please wait..');

      const RtwData: any = {
        RtwDate: this.formGroup.controls.rtwDate.value,
        RtwType: this.formGroup.controls.duty.value,
        Message: this.formGroup.controls.comments.value ?? '',
        EmpUnum: '',
        LineOfBusiness: this.LOB,
        UniqueId: this.claim.claimId,
        ClaimNumber: this.claim.claimNumber,
        ClientNumber: this.claim.client,
        UserClaim: this.claim.userOwns,
        ExaminerName: this.claim.examinerName,
        ExaminerEmail:
          this.claim.examinerEmail != undefined ? this.claim.examinerEmail : '',
        LossDate: this.claim.lossDate,
        Name: this.claim.name,
        Type: this.claim.type,
        Status: this.claim.status,
        SubStatus:
          this.claim.subStatus != undefined ? this.claim.subStatus : '',
      };

      const isLiability =
        this.claim.isLiabilityClaim == true || this.LOB == 'WC' ? true : false;

      const test = await this.claimService.submitReturnToWork(
        isLiability,
        this.claim.lob,
        RtwData
      );
      this.overlayService.hide();

      this.formGroup.reset();
      this.AfterSuccess.emit({
        msg: test.msg,
        isSuccess: test.isSuccess,
        requestValid: false,
        jurisReqStatus: false,
        tamsReqStatus: false,
      });
      this.LogFeature();
    }
  }

  LogFeature() {
    this.logService.LogFeature(
      this.claim,
      'ReturnToWork',
      'Actvity - Return To Work'
    );
  }
}
