import { Injectable } from '@angular/core';
import { Http, NewClaim } from 'src/app/shared';
import { OmniDashboard, SearchParameters, SearchService } from '../..';
import { ActivityService } from '../activity.service';

@Injectable({
  providedIn: 'root',
})
export class OmniApi {
  hcStatus: string[] = ['O', 'I'];

  constructor(
    private http: Http,
    private activityService: ActivityService,
    private searchApi: SearchService
  ) {}

  async getOmniDashboard(): Promise<OmniDashboard> {
    const conf = await this.searchApi.getConfiguration();
    const sp = new SearchParameters();
    sp.status = conf.status.map((x) => x.value);
    sp.lob = conf.lineOfBusiness.map((x) => x.value);
    sp.dsClaimType = conf.claimTypeDs.map((x) => x.value);
    sp.dsClaimSubstatus = conf.claimSubstatusDs.map((x) => x.value);
    sp.lvClaimType = conf.claimTypeLv.map((x) => x.value);
    sp.lvClaimSubstatus = conf.absenceSubstatusLv.map((x) => x.value);
    sp.lvAbsenceType = conf.absenceTypeLv.map((x) => x.value);
    sp.lvCausedBy = conf.causedBy.map((x) => x.value);
    sp.includeIndirectReports = false;

    return this.http.post<OmniDashboard>('/api/omni/dashboard', sp, {
      cache: true,
    });
  }

  async getNewClaims(searchParameters: SearchParameters): Promise<NewClaim[]> {
    const result = await this.http.post<NewClaim[]>(
      '/api/omni/new-claims',
      searchParameters,
      {
        cache: true,
      }
    );
    const actions = this.activityService.alertActions();
    return result.filter(
      (n) => !actions.some((x) => x.alertMasterUid.toString() === n.alertId)
    );
  }

  clearNewClamsCache() {
    this.http.deleteCacheRange('/api/omni/new-claims');
  }

  getOffWorks(searchParameters: SearchParameters): any {
    return this.http.post<any>('/api/omni/off-works', searchParameters, {
      cache: true,
    });
  }

  getPendingAccomodationsJobFunctions(searchParameters: SearchParameters): any {
    return this.http.post<any>(
      '/api/omni/pending-accommodations-jobfunctions',
      searchParameters,
      {
        cache: true,
      }
    );
  }

  getNotificationAccomodations(searchParameters: SearchParameters): any {
    return this.http.post<any>('/api/omni/notifications', searchParameters, {
      cache: true,
    });
  }

  async getRtws(searchParameters: SearchParameters) {
    const results = await this.http.post<any[]>(
      '/api/omni/return-to-works',
      searchParameters,
      {
        cache: true,
      }
    );
    const actions = this.activityService.alertActions();
    return results.filter(
      (n) =>
        !actions.some(
          (x) => x.claimNumber === n.claimNumber && x.sourceSystem === n.source
        )
    );
  }
}
