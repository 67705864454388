import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  BaseComponent,
  ClaimDataService,
  ConfigurationSetting,
  MyClaim,
  RouterService,
  getUrlParams,
} from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { Http } from 'src/app/shared/services/http.service';
import { CommCenterService, DisplayService, UserRoleService } from '../..';
import { ActivityRequest } from '../../../shared/models/activity';
import { ClaimKey } from '../../../shared/models/claim-key';
import { UserActions } from '../../../shared/models/user';
import { UserActionService } from '../../../shared/services/user-action.service';
import { AccommodationJobFunction } from '../../models/accommodation';
import { AccommodationsService } from '../../services/accommodations-service';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { EncryptionService } from '../../services/encryption.service';
import { Session } from '../../services/session.service';
import { ActivityComponent } from '../activity/activity.component';

@Component({
  selector: 'app-dashboard-page',
  template: ` <app-section *ngIf="!userRole.auditor">
    <div class="tw-w-full" [ngClass]="{ 'app-pt1': xSmall$ | async }">
      <div
        *ngIf="showAssociateMsg"
        class="md:tw-w-[55%] tw-w-full"
        [ngClass]="{ 'app-pr4': (xSmall$ | async) === false }"
      >
        <app-toast type="Info" *ngIf="showAssociateMsg">{{
          AssociateMsg
        }}</app-toast>
      </div>
      <div class="tw-flex tw-w-full tw-items-center">
        <app-claim-breadcrumb class="tw-grow"></app-claim-breadcrumb>
        <div class="tw-grow-0">
          @if(hasMyClaims && countLoaded) {
          <button
            mat-icon-button
            style="color: var(--color-input-grey)"
            alt="New Notifications"
            class="nopadding"
            role="button"
            aria-label="View new notification/s"
            tabindex="0"
            (click)="setNotificationFocus('Notifications')"
          >
            <mat-icon
              [matBadge]="notificationCountUpdate()"
              matBadgeColor="accent"
              >notifications_none</mat-icon
            >
          </button>
          }
        </div>
      </div>
    </div>
    <div
      class="tw-w-full"
      *ngIf="welcomeMessageInfo && welcomeMessageInfo.length > 0"
    >
      <app-welcome></app-welcome>
    </div>
    <div class="tw-w-full">
      @if(clientLevelConfig) {
      <app-myclaimspanel
        *ngIf="accommodationsLoaded"
        [isClaim]="true"
        [commCenterMsgCount]="commCenterService.unreadMessageCount()"
        [ClientLevelConfig]="clientLevelConfig"
        [activitiesMyClaims]="activitiesDashboard"
        [myclaims]="dashboardmyClaims"
        [myclaimsLoaded]="isDashboardActivitiesLoaded"
        [hideNameColumn]="hideNameColumn"
        [claimant]="claimant"
        [mgrAction]="mgrAction"
        [employeeLabel]="employeeLabel"
        (loaded)="setPanelLoaded()"
        [accommodationsJobFunctions]="accommodationsJobfunctions"
        [accommodationsLoaded]="accommodationsLoaded"
      ></app-myclaimspanel>
      }
    </div>
    <div class="tw-w-full tw-flex tw-flex-wrap">
      <div class="tw-w-full md:tw-w-[50%]">
        <div
          *ngIf="
            dashboardmyClaims?.length > 0 &&
            displayService.offWorkCalendar &&
            claimsLoaded
          "
        >
          <app-off-work-calendar [claims]="claimkey"></app-off-work-calendar>
        </div>
        <app-visit-learning-center
          class="tw-w-full"
          *ngIf="hasMyClaims"
          [originator]="'empDash'"
        ></app-visit-learning-center>
      </div>

      <div class="tw-w-full md:tw-w-[50%]">
        <div *ngIf="hasMyClaims">
          <app-comm-center2-card [class.tw-hidden]="!panelLoaded" />
        </div>

        <app-leave-balance
          class="tw-w-full"
          [claims]="dashboardmyClaims"
          *ngIf="displayService.leavebalancesummary && hasMyClaims"
          [canExpand]="true"
          [headerLevel]="2"
        ></app-leave-balance>
      </div>
    </div>
  </app-section>`,
  styles: [
    `
      .CommCenterIcon {
        float: right;
        margin-right: 15px;
        margin-top: -15px;
      }
      @media (max-width: 740px) {
        .headerPdl20 {
          padding-left: 20px !important;
        }
        .nopadding {
          padding-left: 0px !important;
          width: 50px !important;
        }
      }
    `,
  ],
})
export class DashboardPageComponent extends BaseComponent implements OnInit {
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  @ViewChild('activityPnl') activityPanel: ActivityComponent;
  xSmall$: Observable<boolean>;
  clientLevelConfig: ConfigurationSetting;
  maxBadgeCount = 99;
  showLowerPanels: boolean = true;
  notificationCount: number;
  countLoaded: boolean = false;
  hasReportClaim: boolean = false;
  searchClasses: string;
  isClaimant: boolean;
  isEmployee: boolean;
  welcomeMessageInfo: any;
  welcomeMessageInfoLoaded: boolean = false;
  AssociateMsg: string =
    'There is no account associated with this claimant/employee.';

  myClaims: MyClaim[];

  userEmulating: boolean = false;
  showAssociateMsg: boolean = false;
  myClaimsloaded: boolean = false;
  isDashboardActivitiesLoaded: boolean = false;
  activitiesDashboard: any;
  dashboardmyClaims: MyClaim[];
  claimsLoaded: boolean = false;
  isIncidentalClient: boolean = false;
  claimkey: ClaimKey[];
  hasMyClaims: boolean;
  activityRequest: ActivityRequest;
  EmpUserName: string;
  isEmpDb: boolean = false;
  employeeLabel: string;
  empUnum: string;
  @Output()
  emitHideSideNav = new EventEmitter<boolean>();
  hideNameColumn: boolean = false;
  testSideNav: string;
  mgrAction: UserActions | null;
  claimant: any;
  isOwnEmpDb: boolean = false;

  userActionUI: UserActions;
  panelLoaded = false;
  accommodationsJobfunctions: AccommodationJobFunction[] = [];
  accommodationsLoaded: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private alertService: ActivityService,
    private cd: ChangeDetectorRef,
    public configurationService: ConfigurationService,
    public routerService: RouterService,
    public claimService: ClaimService,
    public session: Session,
    private http: Http,
    private router: Router,
    private claimDataService: ClaimDataService,
    public userRole: UserRoleService,
    private activityService: ActivityService,
    public config: ConfigurationService,
    public displayService: DisplayService,
    public userAction: UserActionService,
    public encrypt: EncryptionService,
    public commCenterService: CommCenterService,
    public accommodationsService: AccommodationsService
  ) {
    super();
  }

  hasClaims() {
    return this.dashboardmyClaims && this.dashboardmyClaims.length > 0;
  }

  async ngOnInit() {
    this.xSmall$ = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((result) => result.matches));
    this.subs.sink = this.alertService.notificationCount.subscribe(
      (count: number) => {
        this.notificationCount = count > this.maxBadgeCount ? 99 : count;
        this.countLoaded = true;
        this.cd.detectChanges();
      }
    );

    !this.session.user ? await this.session.loadUser() : null;
    const params = getUrlParams();

    await this.setUserAction(params);

    this.hasReportClaim =
      !this.session.user.hasIntake || this.session.user.hasIntake === undefined
        ? false
        : true;
    this.isClaimant = this.userRole.claimant;
    this.isEmployee = this.userRole.employee;
    this.searchClasses = this.getSearchClasses();
    this.clientLevelConfig =
      await this.configurationService.getAdditionalUserInformation();

    if (!this.dashboardmyClaims && !this.isIncidentalClient) {
      if (
        (this.userRole.manager || this.userRole.omni) &&
        this.isEmpDb &&
        this.claimant?.empUnum
      ) {
        const [claims, accommodations] = await Promise.all([
          this.claimService.getClaimsEmpUNum(this.claimant?.empUnum),
          this.displayService.accommodations
            ? this.accommodationsService.getManagerAccommodations(
                false,
                this.mgrAction,
                true,
                this.claimant.empUnum
              )
            : Promise.resolve([]),
        ]);

        this.dashboardmyClaims = <any>claims;
        this.accommodationsJobfunctions = accommodations;
        this.accommodationsLoaded = true;
      } else {
        this.dashboardmyClaims = await (<any>this.claimService.getClaims());
        this.accommodationsLoaded = true;
      }
      this.claimsLoaded = true;
      this.claimDataService.setClaims(this.dashboardmyClaims);
      this.claimkey = this.dashboardmyClaims.map((claimData) => {
        return {
          claimId: claimData.claimId,
          lob: claimData.lineOfBusiness,
          source: claimData.source,
          userOwns: false,
          claimNumber: claimData.claimNumber,
          hrMasterUid: claimData.hrMasterUid,
          empUnum: claimData.empUnum,
        };
      });
      this.hasMyClaims = this.dashboardmyClaims.length > 0 ? true : false;

      const action = params.emp
        ? UserActions.MgrViewEmpDb
        : UserActions.ViewOwnEmpDb;

      this.subs.sink = this.claimInfo$.subscribe(async () => {
        const path = '/api/manager/claim/new-claims/';
        this.activityRequest = {
          includeIndirects:
            sessionStorage.getItem(
              this.session.user.sessionId + 'includeIndirects'
            ) === 'true',
          userActions: action,
          empUnum: this.claimant?.empUnum
            ? this.claimant?.empUnum
            : params.emp
            ? this.encrypt.decrypt(params.emp)
            : null,
          claimKeys: this.claimkey,
          userOwns: params.emp ? false : true,
          delegateeEmpUnum: this.session.isDelegating()
            ? this.session.user.delegateeContext.empUnum
            : null,
        };

        this.session.GetWelcomeMessage().then((welcomeMessage) => {
          this.welcomeMessageInfo = welcomeMessage;
        });
        this.activityService
          .postActivities(path, this.activityRequest)
          .then((response) => {
            this.activitiesDashboard = response;
            this.notificationCount =
              this.activitiesDashboard.notifications.length;
            this.countLoaded = true;
            this.isDashboardActivitiesLoaded = true;
          })
          .catch((error) => console.error(error))
          .finally(() => {});
      });
    }

    if (
      (this.userRole.manager || this.userRole.omni) &&
      this.mgrAction == UserActions.ViewOwnEmpDb
    ) {
      this.hideNameColumn = true;
    }

    if (
      this.session?.user?.emulatorContext != null &&
      this.session?.user?.emulatorContext != undefined &&
      this.session?.user?.emulatorContext.isEmulating
    ) {
      this.userEmulating = true;
      if (
        this.session.user.userType === 'CLM_EMULATION' &&
        this.session.user.userName.includes('CLM_EMULATEE')
      ) {
        this.showAssociateMsg = true;
      }
    }
  }

  notificationCountUpdate = (): number => {
    return isNaN(this.notificationCount) ? 0 : Number(this.notificationCount);
    //(notificationCount !== null && notificationCount !== undefined) ? (Number(notificationCount)) : 0
  };

  async setUserAction(params: { emp?: string }) {
    if (
      this.userRole.supervisor ||
      this.userRole.omni ||
      this.userRole.manager
    ) {
      this.mgrAction = this.userAction.getAction();
    }
    if (!params.emp) {
      this.userAction.setAction(UserActions.ViewOwnEmpDb);
    } else if (params.emp && this.userRole.manager) {
      this.userAction.setAction(UserActions.MgrViewEmpDb);
      this.mgrAction = UserActions.MgrViewEmpDb;
    }
    if (
      this.mgrAction == UserActions.MgrViewEmpDb ||
      this.mgrAction == UserActions.MgrViewEmpClaim
    ) {
      this.claimant = this.userAction.getClaimant();
      const empID = this.claimant.empId
        ? this.claimant.empId
        : this.claimant.employeeID
        ? this.claimant.employeeID
        : this.claimant.employeeId;
      if (this.claimant) {
        this.isEmpDb = true;
        this.employeeLabel = this.claimant.name + ' - ' + empID;
      }
    }

    if (
      this.mgrAction == UserActions.ViewOwnEmpDb &&
      (this.userRole.manager || this.userRole.omni)
    ) {
      this.isEmpDb = false;
      this.isOwnEmpDb = true;
      this.employeeLabel =
        this.session.user.firstName +
        ' ' +
        this.session.user.lastName +
        ' - ' +
        this.session.user.employeeId;
    }
  }

  visible(visible: boolean) {
    if (visible) {
      this.showLowerPanels = true;
    }
  }
  setNotificationFocus(type: any) {
    const notification = document.getElementById('activityPnl');
    if (notification != null) {
      this.alertService.setFocusToNotification(type);
      notification.scrollIntoView();
    }
  }

  getSearchClasses() {
    let classList = '';
    if (this.hasReportClaim) {
      classList = 'tw-w-full md:tw-w-[50%] comfloat-left';
    } else {
      classList = 'tw-w-full md:tw-w-[100%] comfloat-left';
    }

    return classList;
  }

  setPanelLoaded() {
    setTimeout(() => {
      this.panelLoaded = true;
    }, 0);
  }
}
