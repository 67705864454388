import { Component, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { IncidentalAbsence, IncidentalAbsenceReason } from '../..';
import { AbsenceService } from 'src/app/my-sedgwick/services/absence.service';
import { AccessibleSupervisors } from '../../models/AccessibleSupervisors';

@Component({
  selector: 'app-new-absences',
  template: `
    <div class="app-pt3 tw-w-full tw-flex tw-flex-wrap">
      <form
        [formGroup]="newAbsenceFormGroup"
        (ngSubmit)="(null)"
        class="tw-flex tw-flex-wrap tw-w-full"
      >
        <app-http-error
          class="tw-w-full"
          [httpErrorResponse]="null"
        ></app-http-error>

        <app-date-control
          formControlName="fromDate"
          [formGroup]="newAbsenceFormGroup"
          label="From Date"
          ngDefaultControl
          class="tw-w-full md:tw-w-[33%]"
          [minValue]="minDate"
        ></app-date-control>
        <app-date-control
          formControlName="toDate"
          [formGroup]="newAbsenceFormGroup"
          label="To Date"
          ngDefaultControl
          class="tw-w-full md:tw-w-[33%]"
          [validationControl]="'To Date'"
          [startAt]="greaterDate"
          [maxValue]="maxDate"
        ></app-date-control>

        <!--<app-text-control
      formControlName="managers"
      [formGroup]="newAbsenceFormGroup"
      label="Managers"
      ngDefaultControl
      class="tw-w-full md:tw-w-[33%]"
      [textHint] = mgrText
    ></app-text-control>-->

        <app-autocomplete-control
          formControlName="managers"
          [formGroup]="newAbsenceFormGroup"
          label="Additional Managers"
          ngDefaultControl
          class="tw-w-full md:tw-w-[33%]"
          [textHint]="mgrText"
          [options]="options"
        ></app-autocomplete-control>

        <!--<app-action-row class="tw-w-full">
      <app-button right2 emphasis="High" type="submit" [loading]="false">
        Search
      </app-button>
      <app-button
        right1
        emphasis="Low"
        (click)="newAbsenceFormGroup.reset(); absences = []"
      >
        Clear
      </app-button>
     </app-action-row>-->
        <div
          ng-hide="!showMessage"
          [innerHTML]="showMessage ? errorMessage : ''"
          class="error-validation"
        ></div>
        <app-action-row class="tw-w-full pr8">
          <app-button
            mat-button
            right2
            emphasis="High"
            (click)="search()"
            [loading]="searching"
            class="btn-search roundbtn"
          >
            Search
          </app-button>
          <button
            mat-button
            right1
            emphasis="Low"
            class="btn-clear roundbtn"
            (click)="clearForm()"
          >
            Clear
          </button>
        </app-action-row>
      </form>
      <div class="tw-w-full">
        <app-loading-panel [state]="searching ? 'Loading' : 'Loaded'">
          <ng-template #content>
            <app-absence-grid
              [absences]="absences"
              [reasonData]="reasonData"
              [filterEnabled]="true"
              [noDataMessage]="noDataMsg"
              [noDataThinMessage]="noDataThinMessage"
              [hideRef]="true"
            ></app-absence-grid>
          </ng-template>
        </app-loading-panel>
      </div>
    </div>
  `,
  styles: [
    `
      .error-validation {
        color: red;
      }

      .btn-clear {
        background-color: #ffffff !important;
        color: #3f464d !important;
        border: 1px solid #3f464d !important;
        font-weight: bold !important;
      }
      .btn-clear:hover {
        background-color: #f1f4f7 !important;
      }
      .btn-search {
        color: #ffffff !important;
        background-color: #007dbc !important;
        font-weight: bold;
      }

      @media (min-width: 768px) {
        .btn-search {
          /* margin-left:18px;*/
        }
        .wd34 {
          width: 34% !important;
        }
      }

      .btn-search:hover {
        background-color: #035e81 !important;
      }
    `,
  ],
})
export class NewAbsencesComponent {
  options: string[] = [];
  defaultFromDate: any;
  defaultToDate: any;
  reasonData: IncidentalAbsenceReason[];
  searching: boolean = true;
  mgrText: string;
  showMessage: boolean = false;
  errorMessage: string = '';
  AccessibleSupervisors: AccessibleSupervisors[] = [];
  noDataMsg: string = '<strong>No results</strong><br>';
  noDataThinMessage: string = 'There are no results that match the search';

  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
  greaterDate = new Date(new Date().setDate(new Date().getDate() + 14));
  newAbsenceFormGroup = this.fb.group({
    managers: [],
    fromDate: [],
    toDate: [],
  });

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private AbsenceService: AbsenceService
  ) {
    this.newAbsenceFormGroup.controls['toDate'].addValidators(
      customvalidations.greaterThan(
        this.newAbsenceFormGroup.controls['fromDate']
      )
    );

    this.newAbsenceFormGroup.controls['managers'].addValidators(
      customvalidations.cannotContainSpecialCharacters
    );
  }

  async ngOnInit() {
    this.createFormControl();
    this.mgrText = "View manager's off work staff";
    this.AccessibleSupervisors =
      await this.AbsenceService.GetAccessibleSupervisors();
    this.reasonData = await this.AbsenceService.getAbsenceReasons();
    this.filetrOptions(this.AccessibleSupervisors);
    //this.absences = await this.AbsenceService.getMyAbsence();
    this.defaultFromDate = new Date(new Date().setDate(new Date().getDate()));
    this.defaultToDate = new Date(
      new Date().setDate(new Date().getDate() + 14)
    );
    this.newAbsenceFormGroup.controls['fromDate'].setValue(
      this.defaultFromDate
    );
    this.newAbsenceFormGroup.controls['toDate'].setValue(this.defaultToDate);
    this.searching = false;
    this.absences = [];
    this.search();
  }

  createFormControl() {}

  absences: IncidentalAbsence[] = [];

  minDateValidation() {
    const fromDateVal = new Date(
      this.newAbsenceFormGroup.get('fromDate').value
    );
    const toDateVal = new Date(this.newAbsenceFormGroup.get('toDate').value);
    let isFromInvalid: boolean = false;
    let isToInvalid: boolean = false;

    if (
      fromDateVal != undefined &&
      fromDateVal != null &&
      fromDateVal.toDateString().length > 7
    ) {
      const startDateVal = new Date(fromDateVal);
      if (startDateVal < this.minDate) {
        this.errorMessage =
          'The From Date cannot be earlier than 2 years from today’s date.';
        isFromInvalid = true;
        //return false;
      }
    }
    if (
      toDateVal != undefined &&
      toDateVal != null &&
      toDateVal.toDateString().length > 7
    ) {
      const endDateVal = new Date(toDateVal);
      if (endDateVal > this.maxDate) {
        this.errorMessage =
          'The To Date cannot be greater than 2 years from today’s date.';
        isToInvalid = true;
        //return false;
      }
    }

    if (isFromInvalid && isToInvalid) {
      this.errorMessage =
        'The From Date cannot be earlier than 2 years from today’s date.' +
        '<br />' +
        'The To Date cannot be greater than 2 years from today’s date.';
      return false;
    } else if (isFromInvalid || isToInvalid) {
      return false;
    }

    return true;
  }
  async search() {
    let searchManagerEmpUnum = '';

    if (!this.newAbsenceFormGroup.valid || !this.minDateValidation()) {
      this.showMessage = true;
      return;
    } else if (this.newAbsenceFormGroup.valid) {
      this.showMessage = false;
      if (
        this.AccessibleSupervisors &&
        this.AccessibleSupervisors.length > 0 &&
        this.newAbsenceFormGroup.get('managers').value
      ) {
        const managerValue = this.newAbsenceFormGroup.get('managers').value;
        const result = this.AccessibleSupervisors.filter(
          (x) =>
            x.firstName.toUpperCase() == managerValue.toUpperCase() ||
            x.lastName.toUpperCase() == managerValue.toUpperCase() ||
            x.middleName.toUpperCase() == managerValue.toUpperCase() ||
            x.firstName.toUpperCase() +
              ' ' +
              x.middleName.toUpperCase() +
              ' ' +
              x.lastName.toUpperCase() ==
              managerValue.toUpperCase()
        );
        if (result && result.length > 0) {
          searchManagerEmpUnum = result[0].empUNum;
        } else if (managerValue) {
          this.absences = [];
          return;
        }
      }
      if (searchManagerEmpUnum != 'Empty') {
        const searchParameters: any = {
          BeginDate: this.newAbsenceFormGroup.get('fromDate').value
            ? new Date(this.newAbsenceFormGroup.get('fromDate').value)
            : null,
          EndDate: this.newAbsenceFormGroup.get('toDate').value
            ? new Date(this.newAbsenceFormGroup.get('toDate').value)
            : null,
          EmpUNum: searchManagerEmpUnum ? searchManagerEmpUnum : '',
        };
        this.searching = true;
        const listAbsenceOffWork = await this.AbsenceService.getOffWorkAbsence(
          searchParameters
        );
        this.absences =
          this.AbsenceService.mergeDateToString(listAbsenceOffWork);
      }
      this.searching = false;

      //this.absences = await this.AbsenceService.getOffWorkAbsence(searchParameters);
    }
  }

  clearForm() {
    this.newAbsenceFormGroup.reset();
    this.showMessage = false;
  }

  filetrOptions(val: AccessibleSupervisors[]) {
    const firstName = val.map(function (el) {
      return el.firstName;
    });
    const middleName = val.map(function (el) {
      return el.middleName;
    });
    const lastName = val.map(function (el) {
      return el.lastName;
    });
    const len = firstName.length;
    for (let index = 0; index < len; ++index) {
      const opt =
        Object.values(firstName)[index] +
        ' ' +
        Object.values(middleName)[index] +
        ' ' +
        Object.values(lastName)[index];
      this.options.push(opt);
    }
  }
}
