import { Component, HostBinding, Input } from '@angular/core';
import { Emphasis } from 'src/app/shared';

@Component({
  selector: 'app-button',
  template: `
    <button
      mat-flat-button
      class="text"
      [attr.role]="role"
      [ngClass]="{
        'high-emphasis': emphasis === 'High',
        'high-emphasis-disabled': emphasis === 'High' && disabled,
        'medium-emphasis': emphasis === 'Medium',
        'medium-emphasis-disabled': emphasis === 'Medium'  && disabled,
        'low-emphasis': emphasis === 'Low',
        'low-emphasis-disabled': emphasis === 'Low' && disabled,
      }"
      [disabled]="disabled || loading"
      [attr.aria-label]="arialabel"
      [type]="type"
    >
      <div class="tw-flex tw-items-center">
        <mat-icon class="icon" *ngIf="icon && !loading">{{ icon }}</mat-icon>
        <div
          *ngIf="loading"
          class="tw-mr-2"
          [ngClass]="{
            'app-spinner-white': emphasis !== 'Low',
            'app-spinner-grey': emphasis === 'Low',
          }"
        ></div>
        <div><ng-content></ng-content></div>
      </div>
    </button>
  `,
  styles: [
    `
      .text {
        font-weight: bold;
        letter-spacing: 0px;
        /* border-radius: 8px;*/
      }

      .icon {
        height: 18px;
        width: 18px;
        font-size: 18px;
        margin-right: 4px;
        margin-top: 1px;
      }

      .high-emphasis {
        background-color: var(--color-sedgwick-blue);
        color: white;
        border-radius: 20px;
        padding: 0 20px;
      }

      .high-emphasis-disabled {
        background-color: #dcdcdc;
        color: #888888;
      }

      
      .low-emphasis {
        background-color: white;
        color: var(--color-slate);
        border: 1px solid var(--color-slate);
        border: 2px solid #dcdcdc;
        border-radius: 20px;
        padding: 0 20px;
      }

      .low-emphasis:focus {
        color: black;
        outline: 2px solid #67707a !important;
      }

      .low-emphasis-disabled {
        background-color: white;
        color: #888888;
        border: 0px solid #dcdcdc;
      }

      .medium-emphasis {
        background-color: var(--color-slate);
        color: white;
      }

      .medium-emphasis-disabled {
        background-color: #dcdcdc;
        color: #888888;
      }

      .medium-emphasis:hover {
        background-color: var(--color-slate-500);
        color: white;
      }

      .text:focus {
        outline: 2px solid black;
      }
    `,
  ],
})
export class ButtonComponent {
  @Input()
  emphasis: Emphasis = 'Low';

  @Input()
  text: string;

  @Input()
  icon: string;

  @Input()
  type: InputType = 'button';

  @Input()
  disabled = false;

  @Input()
  loading = false;

  @Input()
  arialabel: string;

  @Input()
  role: string = 'button';

  @Input()
  id: string;

  /**
   * Setting pointer-events is needed to prevent click events from
   * firing when the button is disabled or loading.
   */
  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled || this.loading) {
      return 'none';
    }
    return 'auto';
  }
}

export type InputType = 'button' | 'submit';
