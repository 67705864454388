import { Injectable } from '@angular/core';
import { Http } from 'src/app/shared/services/http.service';
import { PtoInfoDetails } from '../models/ptoInfoDetails';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleService {
  constructor(private http: Http) {}

  async getPto(contNum: string, empUnum: string, lob: string, claimId: string) {
    return await this.http.get<PtoInfoDetails>(
      `/api/workschedule/${contNum}/${empUnum}/${lob}/${claimId}/getpto`
    );
  }

  updateWeeklyWorkSchedule = async (WorkScheduleData: any) => {
    return await this.http.post<any>(
      `/api/workschedule/UpdateWorkScheduleNotes`,
      WorkScheduleData
    );
  };

  getcutomworkschedule = async (workscheduledata: any) => {
    return await this.http.post<any[]>(
      `/api/workschedule/GetWorkSchedule`,
      workscheduledata
    );
  };
}
