import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';

import { parse } from 'date-fns';
import { IncidentalAbsence } from '../../models/incidental-absence';
import { IncidentalAbsenceReason } from '../../models/incidental-absence-reason';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-edit-absence-dialog',
  template: `
    <app-dialog
      #editDialog
      title="Edit Absence"
      ariaLabel="Edit Absence dialog"
      [showActions]="true"
      (confirmEvent)="updateAbsence($event)"
      [confirmLabel]="'Update'"
      [cancelLabel]="'Cancel'"
      [btnConfirmAltText]="'Update'"
      [btnConfirmTitleText]="'Update'"
      [btnCloseAltText]="'Cancel'"
      [btnCloseTitleText]="'Cancel'"
      [showHeader]="true"
    >
      <form [formGroup]="formGroup" class="tw-flex tw-flex-wrap">
        <div class="tw-w-full">
          <br />
          <mat-form-field *ngIf="reasonData">
            <mat-label>Primary Reason</mat-label>
            <mat-select [required] [(value)]="primaryReason">
              <mat-option
                *ngFor="let reason of getPrimaryReasons()"
                [value]="reason.reasonDesc"
              >
                {{ reason.reasonDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="reasonData">
            <mat-label>Secondary Reason</mat-label>
            <mat-select [required] [(value)]="secondaryReason">
              <mat-option
                *ngFor="let reason of getSecondaryReasons()"
                [value]="reason.reasonDesc"
              >
                {{ reason.reasonDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-date-control
            formControlName="startDate"
            [formGroup]="formGroup"
            label="Start Date"
            ngDefaultControl
            class="tw-w-full md:tw-w-[100%]"
            [placeHolder]="'Enter Start Date'"
            [validationControl]="'Start Date'"
            [errormsgClass]="'errormsgabsence'"
          ></app-date-control>
          <app-text-control
            formControlName="startTime"
            [formGroup]="formGroup"
            label="Start Time"
            ngDefaultControl
            class="tw-w-full md:tw-w-[33%]"
          ></app-text-control>
          <div
            *ngIf="
              this.formGroup.controls['startTime'].errors &&
              this.formGroup.controls['startTime'].errors[
                'timeGreaterThanHours'
              ]
            "
          >
            <br />
          </div>
          <app-date-control
            formControlName="endDate"
            [formGroup]="formGroup"
            label="End Date"
            ngDefaultControl
            class="tw-w-full md:tw-w-[100%]"
            [validationControl]="'End Date'"
            [errormsgClass]="'errormsgabsence'"
          ></app-date-control>
          <app-text-control
            formControlName="endTime"
            [formGroup]="formGroup"
            label="End Time"
            ngDefaultControl
            class="tw-w-full md:tw-w-[33%]"
          ></app-text-control>
          <div
            *ngIf="
              this.formGroup.controls['endTime'].errors &&
              this.formGroup.controls['endTime'].errors['timeGreaterThanHours']
            "
          >
            <br />
          </div>
          <app-text-control
            formControlName="shifts"
            [formGroup]="formGroup"
            label="Work Days Absent"
            ngDefaultControl
            class="tw-w-full md:tw-w-[33%]"
          ></app-text-control>

          <app-date-control
            formControlName="rtwDate"
            [formGroup]="formGroup"
            label="Return Date"
            ngDefaultControl
            class="tw-w-full md:tw-w-[100%]"
            [validationControl]="'Return Date'"
            [errormsgClass]="'errormsgabsence'"
          ></app-date-control>
          <app-text-control
            formControlName="rtwTime"
            [formGroup]="formGroup"
            label="Return Time"
            ngDefaultControl
            class="tw-w-full md:tw-w-[33%]"
          ></app-text-control>
          <div class="messages">
            <app-toast type="Info" *ngIf="updateSuccess === true"
              >The absence updates have been reported. Please allow up to two
              hours for updates to be visible in mySedgwick.
            </app-toast>
            <app-toast type="Error" *ngIf="noChangesMade === true"
              >Error : Please enter at least one update criteria or cancel to
              close the window.
            </app-toast>
            <app-toast type="Error" *ngIf="updateError === true"
              >There was an error updating the absence.
            </app-toast>
            <span id="placeholder">&nbsp;</span>
          </div>
        </div>
      </form>
    </app-dialog>
  `,
  styles: [
    `
      .messages {
        max-width: 270px;
      }

      .errorMsg {
        font-weight: bold;
        color: red;
      }
      ::ng-deep .commCenterName {
        font-weight: bold;
      }
      @media only screen and (max-device-width: 480px) {
        .icon {
          display: none;
        }

        ::ng-deep .commactionbtn {
          padding-right: 30px !important;
        }
      }

      .messageDate {
        float: right;
        margin-right: 10px;
      }
      .examiner-container {
      }
      .icon {
        font-size: 40px;
        width: 40px;
        height: 40px;
        color: var(--color-accent);
        margin-top: 18px;
      }
      .user-message {
        --background-color: #c9edfc;
        background-color: #e0effe;
        --background-color: #eff7ff;

        border-radius: 8px;
        --padding: 12px 16px 12px 16px;
        margin-left: 8px;
      }
      .message-div {
        margin-right: 10px;
        padding: 12px 16px 12px 16px;
      }
      .examiner-message {
        background-color: #f6f6f6;
        border-radius: 8px;
        --padding: 12px 16px 12px 16px;
        margin-left: 8px;
      }
      .sender {
        font-size: 12px;
        margin-left: 24px;
        font-weight: bold;
        padding-bottom: 4px;
        color: var(--color-input-grey);
      }
    `,
  ],
})
//extends BaseComponent
export class EditAbsenceDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('editDialog', { static: false }) editDialog: DialogComponent;
  @Input()
  dialogRef: MatDialogRef<any>;

  @Input()
  absence: IncidentalAbsence;

  @Input()
  reasonData: IncidentalAbsenceReason[];

  primaryReason: string;
  secondaryReason: string;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  shifts: any;

  rtwDate: Date;
  rtwTime: string;
  primaryReasonControl: FormControl;
  secondaryReasonControl: FormControl;

  updateSuccess: boolean = false;
  isUpdating: boolean = false;
  errorMessage: string = '';
  noChangesMade: boolean = false;
  updateError: boolean = false;

  isFormDirty = (): boolean => {
    let isDirty = false;
    isDirty ||= this.primaryReason !== this.absence.absenceReason;
    isDirty ||= this.secondaryReason !== this.absence.typeOfCallOff;
    isDirty ||= this.shifts !== this.formGroup.controls['shifts'].value;
    const ed = this.getDateTime('end');
    const sd = this.getDateTime('start');
    const rd = this.getDateTime('rtw');
    isDirty ||= new Date(this.endDate).valueOf() !== ed.valueOf();
    isDirty ||= new Date(this.startDate).valueOf() !== sd.valueOf();
    isDirty ||= new Date(this.rtwDate).valueOf() !== rd.valueOf();

    this.noChangesMade = !isDirty;
    if (this.noChangesMade) {
      //let err = document.getElementById('placeholder');
      //err.scrollIntoView();
      this.showMessage();
    }

    return isDirty;
  };
  showMessage = () => {
    const err = document.getElementById('placeholder');
    setTimeout(() => err.scrollIntoView(), 50);
  };
  getPrimaryReasons = (): IncidentalAbsenceReason[] => {
    const response: IncidentalAbsenceReason[] = [];

    const primaryReasons = this.reasonData.filter((r) => r.parentId === '0');
    for (let i = 0; i < primaryReasons.length; i++) {
      if (
        response.filter((r) => r.reasonDesc === primaryReasons[i].reasonDesc)
          .length === 0
      ) {
        response.push(primaryReasons[i]);
      }
    }

    return response;
  };

  getSecondaryReasons = (): AbsenceReason[] => {
    //return [];
    const curPrimaryReason = this.reasonData.filter(
      (p) => p.reasonDesc == this.primaryReason
    );
    const curPrimaryId =
      curPrimaryReason.length > 0 ? curPrimaryReason[0].reasonId : '';
    let secondaryReasons = this.reasonData.filter(
      (r) => r.parentId === curPrimaryId
      //this.absenceReasons.filter((p) => p.reasonDesc == this.primaryReason)[0].reasonId
      //}
    );

    // if theres no specific secondary reasons for the primary reason, then only allow primary reason to be selected
    if (secondaryReasons.length == 0) {
      secondaryReasons = this.reasonData.filter(
        (p) => p.reasonDesc == this.primaryReason
      );

      // Since there should only be one option here for secondary reasons, preselect it?
      //this.secondaryReason = secondaryReasons[0].reasonDesc;
    }
    const response: IncidentalAbsenceReason[] = [];

    for (let i = 0; i < secondaryReasons.length; i++) {
      if (
        response.filter((r) => r.reasonDesc === secondaryReasons[i].reasonDesc)
          .length === 0
      ) {
        response.push(secondaryReasons[i]);
      }
    }

    return response;
  };

  isValidDate = (dateType: string): boolean => {
    const testDate = new Date(this.formGroup.controls[dateType + 'Date'].value);
    if (!(testDate instanceof Date && !isNaN(testDate.valueOf()))) {
      this.formGroup.controls[dateType + 'Date'].setErrors({
        customMsg: 'Invalid Date',
      });
      return false;
    }

    return true;
  };

  isValidTime = (dateType: string): boolean => {
    const testDate = parse(
      this.formGroup.controls[dateType + 'Time'].value,
      'hh:mm a',
      new Date()
    );
    if (!(testDate instanceof Date && !isNaN(testDate.valueOf()))) {
      this.formGroup.controls[dateType + 'Time'].setErrors({
        customMsg: 'Invalid Time',
      });
      return false;
    }

    return true;
  };

  isDataValid = (): boolean => {
    this.formGroup.controls['endTime'].setErrors(null);
    this.formGroup.controls['startTime'].setErrors(null);
    this.formGroup.controls['startDate'].updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
    this.formGroup.controls['startTime'].updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
    this.formGroup.controls['endDate'].updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
    this.formGroup.controls['endTime'].updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
    this.formGroup.controls['rtwDate'].updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
    this.formGroup.controls['rtwTime'].updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });

    if (
      this.formGroup.controls['startDate'].errors ||
      this.formGroup.controls['startTime'].errors ||
      this.formGroup.controls['endDate'].errors ||
      this.formGroup.controls['endTime'].errors ||
      this.formGroup.controls['rtwDate'].errors ||
      this.formGroup.controls['rtwTime'].errors
    ) {
      return false;
    }

    if (
      !this.isValidDate('start') ||
      !this.isValidDate('end') ||
      !this.isValidDate('rtw') ||
      !this.isValidTime('start') ||
      !this.isValidTime('end') ||
      !this.isValidTime('rtw')
    ) {
      return false;
    }

    if (isNaN(this.formGroup.controls['shifts'].value)) {
      this.formGroup.controls['shifts'].setErrors({
        customMsg: 'Invalid number',
      });
      return false;
    }

    return true;
  };

  getDateTime = (type: string): Date => {
    const d = this.datePipe.transform(
      this.formGroup.controls[type + 'Date'].value,
      'MM-dd-yyyy'
    );
    const dt_string = d + ' ' + this.formGroup.controls[type + 'Time'].value;
    const ret: Date = new Date(
      parse(dt_string, 'MM-dd-yyyy hh:mm a', new Date())
    );

    return new Date(ret); //.toUTCString());
  };

  //hideConfirmBtn = new EventEmitter();
  canUpdate = () => {
    return false;
  };
  async updateAbsence(message: any): Promise<string> {
    //    this.hideConfirmBtn.emit('');
    //    return '';
    this.formGroup.markAllAsTouched();
    this.noChangesMade = false;
    this.updateError = false;
    if (this.isUpdating) {
      return '';
    }
    if (!this.isDataValid()) {
      return '';
    }
    if (!this.isFormDirty()) {
      return '';
    }
    const editAbsence: IncidentalAbsence = {
      empId: this.absence.empId,
      firstName: this.absence.firstName,
      lastName: this.absence.lastName,
      absenceEndDate: this.getDateTime('end'),
      absenceStartDate: this.getDateTime('start'),
      estimatedRTWDate: this.getDateTime('rtw'),
      absenceReason: this.primaryReason,
      typeOfCallOff: this.secondaryReason,
      absenceType: this.absence.absenceType,
      absenceNumber: this.absence.absenceNumber,
      shiftsMissed: this.formGroup.controls['shifts'].value,
      dateReported: this.absence.dateReported,
      absenceStatus: this.absence.absenceStatus,
      absenceReportedBy: this.absence.absenceReportedBy,
      empUnum: this.absence.empUnum,
      referenceNumber: this.absence.referenceNumber,
      ClaimLevelFeatures: [],
    };

    editAbsence.absenceEndTime = this.formGroup.controls['endTime'].value; //editAbsence.absenceEndDate;
    editAbsence.absenceStartTime = this.formGroup.controls['startTime'].value; // editAbsence.absenceStartDate;
    editAbsence.estimatedRTWTime = this.formGroup.controls['rtwTime'].value; //editAbsence.estimatedRTWDate;

    this.isUpdating = true;

    const returnValue = await this.absenceService.editRemoveAbsence(
      editAbsence
    );

    if (returnValue !== undefined && returnValue === true) {
      this.isUpdating = false;
      this.updateSuccess = true;

      this.absence = editAbsence;
      //document.getElementById('placeholder').scrollIntoView();
      this.editDialog.hideConfirm();
      //let successToast = document.getElementById('placeholder');
      //successToast.scrollIntoView();
      this.showMessage();
      //    this.hideConfirmBtn.emit('');
    } else if (returnValue !== undefined && returnValue === false) {
      this.isUpdating = false;
      this.updateSuccess = false;
      this.updateError = true;
      //document.getElementById('placeholder').scrollIntoView();
      this.showMessage();
    }

    return '';
  }

  formGroup = this.fb.group({
    primaryReason: [''],
    secondaryReason: [''],
    startDate: [''],
    startTime: [''],
    endDate: [''],
    endTime: [''],
    shifts: [''],
    rtwDate: [''],
    rtwTime: [''],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private absenceService: AbsenceService
  ) {}

  ngAfterViewInit(): void {
    //return;
    this.cdr.detach();
    this.formGroup.controls['primaryReason'].addValidators([
      customvalidations.required,
    ]);
    this.formGroup.controls['secondaryReason'].addValidators([
      customvalidations.required,
    ]);
    this.formGroup.controls['startDate'].addValidators([
      customvalidations.required,
      customvalidations.dateLessThan(
        this.formGroup.controls['endDate'],
        false,
        'Start Date cannot be later than the Start Date.'
      ),
    ]);
    this.formGroup.controls['startTime'].addValidators([
      customvalidations.required,
      customvalidations.timeGreaterThanHours(
        this.formGroup.controls['endTime'],
        20,
        'The dates and time entered exceed 20 hours, please review'
      ),
    ]);
    this.formGroup.controls['endDate'].addValidators([
      customvalidations.required,
      customvalidations.dateGreaterThan(
        this.formGroup.controls['startDate'],
        false,
        'End Date cannot be earlier than the Start Date.'
      ),
    ]);
    this.formGroup.controls['endTime'].addValidators([
      customvalidations.required,
      customvalidations.timeGreaterThanHours(
        this.formGroup.controls['startTime'],
        20,
        'The dates and time entered exceed 20 hours, please review'
      ),
    ]);
    this.formGroup.controls['shifts'].addValidators([
      customvalidations.required,
    ]);

    this.formGroup.controls['rtwDate'].addValidators([
      customvalidations.dateGreaterThan(
        this.formGroup.controls['endDate'],
        false,
        'Return Date cannot be earlier than the End Date.'
      ),
      customvalidations.required,
    ]);
    this.formGroup.controls['rtwTime'].addValidators([
      customvalidations.required,
    ]);

    this.cdr.reattach();
    this.cdr.markForCheck();
    //this.formGroup.updateValueAndValidity();
  }

  closeDialog(close: boolean) {
    if (close) {
      this.dialogRef.close();
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.absence) {
      console.log('SETTING EDIT ABSENCE', this.absence);
      this.primaryReason = this.absence.absenceReason;
      this.secondaryReason = this.absence.typeOfCallOff;
      this.startDate = this.absence.absenceStartDate;
      this.startTime = this.absence.absenceStartTime; // this.absence.absenceStartDate !== undefined ? formatDate(this.absence.absenceStartDate, 'hh:mm a', 'en-US') : '';
      this.endDate = this.absence.absenceEndDate;
      this.endTime = this.absence.absenceEndTime; //this.absence.absenceEndDate !== undefined ? formatDate(this.absence.absenceEndDate, 'hh:mm a', 'en-US') : '';
      this.shifts = this.absence.shiftsMissed;
      this.rtwDate = this.absence.estimatedRTWDate;
      this.rtwTime = this.absence.estimatedRTWTime; // this.absence.estimatedRTWDate !== undefined ? formatDate(this.absence.estimatedRTWDate, 'hh:mm a', 'en-US') : '';
      this.formGroup.controls['startDate'].setValue(this.startDate);
      this.formGroup.controls['startTime'].setValue(this.startTime);
      this.formGroup.controls['endDate'].setValue(this.endDate);
      this.formGroup.controls['endTime'].setValue(this.endTime);
      this.formGroup.controls['rtwDate'].setValue(this.rtwDate);
      this.formGroup.controls['rtwTime'].setValue(this.rtwTime);
      this.formGroup.controls['shifts'].setValue(this.shifts);

      //this.initializeFormGroup();
      //    this.formGroup.controls['rtwDate'].setValue(this.rtwDate);
      //    this.formGroup.controls['startDate'].setValue(this.startDate);

      //this.formGroup.updateValueAndValidity();
      //this.formGroup.markAllAsTouched();

      if (!this.reasonData) {
        this.reasonData = await this.absenceService.getAbsenceReasons();
      }
    }
  }
}

class AbsenceReason {
  parentId: string;
  reasonId: string;
  reasonDesc: string;
}
