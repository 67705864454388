import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  Activity,
  BaseComponent,
  ConfigurationSetting,
  MyClaim,
  NewClaim,
  NotificationItem,
  OffWork,
  TabGroupComponent,
} from 'src/app/shared';
import { ClaimInfo, SearchResult } from 'src/app/shared/models/search-result';
import { DisplayService, UserRoleService } from '../..';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Session } from '../../services/session.service';
import { ReportNewClaimComponent } from '../report-new-claim/report-new-claim.component';
import { AccommodationJobFunction } from '../../models/accommodation';

@Component({
  selector: 'app-activity',
  template: `
    <div id="activitypnl">
      <app-panel
        title="Activity"
        [showTitle]="showTitle"
        minHeight="510px"
        [largeHeader]="false"
        sectionName="Activity"
        [class.mat-elevation-z0]="flat"
        [flat]="flat"
      >
        <div class="tw-w-full app-pt2" *ngIf="showMyClaims && !isClaim">
          <app-loading-panel [state]="!isLoaded ? 'Loading' : 'Loaded'">
            <ng-template #content>
              <app-my-claims
                *ngIf="showMyClaims && !isClaim"
                featurename="MyClaims"
                [myClaimsLoaded]="isLoaded"
                [myClaims]="listOfClaims"
                [accommodationsJobFunctions]="accommodationsData"
              ></app-my-claims>
            </ng-template>
          </app-loading-panel>
        </div>
        <div class="btn-container" *ngIf="showTitle">
          <button
            mat-button
            *ngIf="
              hasReportClaim &&
              isIncidentalClient === false &&
              (isClaimant || isEmployee) &&
              !hideReportClaim
            "
            aria-label="Report a New Claim"
            emphasis="High"
            (click)="reportClaim()"
            class="btn-info roundbtn reportNewClmBtn pdl15 pdr15"
            mat-raised-button
            tabindex="0"
          >
            Report a new claim
          </button>
        </div>
        <div *ngIf="!showTitle" class="app-pt2"></div>
        <app-tab-group [tabGroupId]="'activitiesTabGroup'">
          <app-tab
            [visible]="paymentTabVisible"
            label="Payments"
            id="paymentsTab"
            featureName="Payments"
            [template]="payments"
          >
            <ng-template #payments>
              <div class="app-pt3"></div>
              <app-custom-message
                [listClientMessage]="this.claim?.listClientMessage"
                [keyForMessage]="'Payments'"
              >
              </app-custom-message>
              <app-payment
                [claim]="claim"
                [isHomePage]="isHomePage"
                [paymentsDataMyClaims]="activities.payments"
                [paymentMyClaims]="listOfClaims"
              ></app-payment>
            </ng-template>
          </app-tab>

          <app-tab
            label="New Claims"
            id="newClaimsTab"
            featureName="New Claims"
            *ngIf="showNewClaims"
            [template]="newClaims"
          >
            <ng-template #newClaims>
              <app-new-claims
                *ngIf="showNewClaims && isLoaded"
                [newClaims]="activities.newClaims"
                featurename="NewClaims"
              ></app-new-claims>
            </ng-template>
          </app-tab>

          <app-tab
            label="Notifications"
            id="notificationTab"
            featureName="Notifications"
            name="Notifications"
            [template]="notifications"
          >
            <ng-template #notifications>
              <app-notifications
                *ngIf="isLoaded"
                [notifications]="activities?.notifications"
                [actualNotifications]="activities?.notifications"
                [ntnMyClaims]="activities"
                [mgrAction]="mgrAction"
                [showFilter]="isFilterApplied"
                (clearFilterEvent)="onClearFilter()"
                [showClaimLink]="!isClaim"
                [isBlueLabel]="isBlueLabel"
              ></app-notifications>
            </ng-template>
          </app-tab>
          <app-tab
            label="Return to Work"
            featureName="returntowork"
            name="returntowork"
            [visible]="isReturnToWork"
            [template]="returnToWork"
          >
            <ng-template #returnToWork>
              <app-return-to-work
                *ngIf="isLoaded && isReturnToWork"
                [returnToWork]="activities.returnToWork"
              ></app-return-to-work>
            </ng-template>
          </app-tab>
          <app-tab
            label="Off Work"
            [visible]="showOffWork && !isClaim"
            featureName="OffWork"
            id="offWorkTab"
            [template]="offWork"
          >
            <ng-template #offWork>
              <app-off-work
                *ngIf="isLoaded"
                [offWork]="activities.offWork"
                [ClientLevelConfig]="ClientLevelConfig"
                [yourClaim]="yourClaim"
              ></app-off-work>
            </ng-template>
          </app-tab>
          <app-tab
            label="Accommodations"
            [visible]="showAccommodations"
            featureName="Accommodations"
            id="accommodations"
            [template]="accommodations"
          >
            <ng-template #accommodations>
              <app-accommodation-page
                [ClientLevelConfig]="ClientLevelConfig"
                [isClaimSummary]="false"
                [accommodations]="accommodationsData"
              ></app-accommodation-page>
            </ng-template>
          </app-tab>
        </app-tab-group>
      </app-panel>
    </div>
  `,
  styles: [
    `
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      #activitypnl ::ng-deep .mat-tab-label {
        color: #007dbc !important;
        border-bottom: 2px solid #bdbdbd;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      #activitypnl ::ng-deep .mat-tab-label-active {
        color: #007dbc;
        font-weight: bold;
        border-bottom: 2px solid #007dbc;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      #activitypnl ::ng-deep .mat-tab-label:hover {
        color: #007dbc;
        font-weight: bold;

        border-bottom: 2px solid #007dbc;
      }

      #activitypnl ::ng-deep .mat-accent {
        color: #035e81 !important;
      }
      .mat-badge-content {
        background-color: #007dbc !important;
        color: #fff;
      }
    `,
  ],
})
export class ActivityComponent extends BaseComponent implements OnInit {
  @ViewChild(TabGroupComponent) tabGroup: TabGroupComponent;
  @Input() focusTab: EventEmitter<string>;

  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  @Input()
  showTitle = true;

  @Input()
  focus = 'myclaims';

  selectedIndex = 100;

  @Input()
  claimInfo$ = new Observable<ClaimInfo>();

  @Input()
  claimConfig: ConfigurationSetting;

  @Input()
  ClientLevelConfig: ConfigurationSetting;

  @Input()
  isClaim: boolean = false;

  @Input()
  hideReportClaim: boolean = false;

  @Input()
  claim: SearchResult;

  @Input()
  flat = false;

  @Input()
  isBlueLabel: boolean;

  @Input()
  isHomePage: boolean = false;
  //parameterize this when we refactor dashboards for multiple users? Or get
  yourClaim: boolean = false;

  offWork: OffWork[];

  returnToWork: OffWork[];

  notifications: NotificationItem[];

  newClaims: NewClaim[];

  @Input()
  listOfClaims: MyClaim[];

  @Input()
  activities: Activity;

  paymentTabVisible: boolean;

  isLoaded: boolean = false;

  @Input()
  isFilterApplied: boolean = false;

  @Output()
  clearFilter = new EventEmitter<void>();

  @Output()
  valueChanged = new EventEmitter<any>();

  myClaims: MyClaim[];
  isIncidentalClient: boolean = false;
  isClaimant: boolean = false;
  isEmployee: boolean = false;
  isAuditor: boolean = false;
  showNewClaims: boolean = true;
  showNotifications: boolean = true;
  showOffWork: boolean = true;
  showMyClaims: boolean = false;
  hasReportClaim: boolean = false;
  showActivityFilter: boolean;
  isReturnToWork: boolean = false;
  setToastButnShow: boolean;
  hasFeatureEnabled: boolean = false;
  showAccommodations: boolean = false;

  @Input()
  mgrAction: UserActions | null;
  isManager: boolean = false;

  @Input()
  accommodationsData: AccommodationJobFunction[];
  @Output()
  setPaymentAlertBool = new EventEmitter<boolean>();

  // activity: Activity;

  get isRtw(): boolean {
    // Return to Work Feature not in scope, so just not displaying if we are on claim and client  doesnt have LOB WC
    //return !this.isClaim && this.claimConfigService.getFeatureAccess('ReturnToWork');
    //const hasWc =
    //  this.session.user.lineOfBusiness.filter((l) => l.lob == 'WC').length > 0;
    return this.isClaim || this.userrole.manager;
    // new requirements say it will always be visible for supervisor
  }

  constructor(
    public claimConfigService: ConfigurationService,
    private activityService: ActivityService,
    private session: Session,
    private change: ChangeDetectorRef,
    public dialog: MatDialog,
    public claimService: ClaimService,
    public userrole: UserRoleService,
    public display: DisplayService,
    private userAction: UserActionService
  ) {
    super();
  }

  async ngOnInit() {
    this.selectedIndex = 0;
    this.isClaimant = this.userrole.claimant;
    this.isEmployee = this.userrole.employee;
    this.isManager = this.userrole.manager;
    this.isReturnToWork = this.display.rtw;
    // feature check is not needed here as feature only applies when reporting RTW on a claim and not on alert based stuff - _RM
    //&& this.claimConfigService.getConfigFeatureAccess('returntowork');
    this.isIncidentalClient = this.claimConfigService.getConfigFeatureAccess(
      'IncidentalAbsences5'
    );
    this.showNewClaims = this.display.newClaims;
    this.showOffWork = this.display.offWork;
    this.showMyClaims = this.display.myClaims;
    this.hasReportClaim = this.display.reportClaim;
    this.showAccommodations = this.display.accommodations;

    this.showActivityFilter =
      this.isClaimant || this.isEmployee ? false : !this.isClaim;

    this.subs.sink = this.claimInfo$.subscribe(async () => {
      this.subs.sink = this.activityService.focusNotification.subscribe(
        (x: string) => {
          this.setTabIndex(x);
          this.change.markForCheck();
        }
      );

      this.paymentTabVisible = await this.showPaymentTab();
    });
    // focus tab will not be set for controller
    if (this.focusTab) {
      this.subs.sink = this.focusTab.subscribe((tabId: string) => {
        this.setTabIndex(tabId);
      });
    }
    this.isLoaded = true;
  }

  async showPaymentTab(): Promise<boolean> {
    if (
      this.userrole.manager &&
      this.userAction.getAction() == UserActions.ViewOwnMgrDb
    ) {
      this.setPaymentAlertBool.emit(false);
      return false;
    } else if (
      this.userrole.manager &&
      this.listOfClaims &&
      (this.userAction.getAction() == UserActions.MgrViewEmpDb ||
        this.userAction.getAction() == UserActions.ViewOwnEmpDb)
    ) {
      const data1 = await this.HasAnyClaimHaveFeature(
        'payments',
        this.listOfClaims
      );
      this.setPaymentAlertBool.emit(data1);
      return data1;
    } else if (this.userrole.claimant || this.userrole.employee) {
      const paymentTab = await this.HasAnyClaimHaveFeature(
        'payments',
        this.listOfClaims
      );
      const data = paymentTab && !this.session.user.isClaimClosed;
      this.setPaymentAlertBool.emit(data);
      return data;
    }
    this.setPaymentAlertBool.emit(false);
    return false;
  }

  HasAnyClaimHaveFeature(
    featureName: string,
    listOfClaims: MyClaim[]
  ): boolean {
    for (let i = 0; i < listOfClaims.length; i++) {
      if (listOfClaims[i].claimLevelFeatures.indexOf(featureName) > -1) {
        this.hasFeatureEnabled = true;
        break;
      }
    }
    return this.hasFeatureEnabled;
  }

  /*    const userClaims = await this.claimService.getClaims();

    let showPayments = false;

    for (let i = 0; i < userClaims.length; i++) {
      if (userClaims[i].status === 'Closed') {
        break;
      } else {
        showPayments = true;
      }
    }
    return userClaims && userClaims.length > 0 && showPayments
      ? userClaims.findIndex(
          (claim) =>
            claim.claimLevelFeatures.findIndex(
              (feature) => feature == 'payments'
            ) > -1
        ) > -1
      : false;

  }  */

  setTabIndex(index: string) {
    this.tabGroup.selectTab(index);
  }

  onClearFilter() {
    this.clearFilter.emit();
  }

  reportClaim() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Confirmation',
    };
    this.dialog.open(ReportNewClaimComponent, dialogConfig);
  }
}
