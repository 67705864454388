import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { BaseControlComponent } from './base-control.component';

@Pipe({
  name: 'display',
})
export class DisplayLabelPipe implements PipeTransform {
  transform(value: any, displayProperty: string): string {
    try {
      if (!value) {
        return null;
      }
      return displayProperty ? value[displayProperty] : value;
    } catch (error) {
      console.error(error);
      return '';
    }

    // return value.filter(Boolean).filter(Boolean).join(seperator);
  }
}

@Component({
  selector: 'app-autocomplete-control',
  template: `
    <div class="app-pr1 app-pb1">
      <mat-form-field [hintLabel]="hintLabel" [formGroup]="formGroup">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          #input
          [placeholder]="placeHolder"
          [formControlName]="formControlName"
          autocomplete="off"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayLabel.bind(this)"
        >
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
          >
            {{ option | display: displayProperty }}
          </mat-option>
        </mat-autocomplete>
        <button
          mat-icon-button
          matSuffix
          type="button"
          class="mat-datepicker-toggle"
          (click)="clearValue()"
        >
          <mat-icon>close</mat-icon>
        </button>

      <mat-hint align="start" *ngIf="textHint != null"
      >{{ textHint }}</mat-hint
    >
        <mat-error *ngIf="formControl.invalid">{{ errorMessage }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styles: [``],
})
export class AutocompleteControlComponent
  extends BaseControlComponent
  implements OnInit {
  @Input()
  options: any[];

  @Input()
  displayProperty: string;

  filteredOptions: Observable<any[]>;

  @Input()
  textHint: string;

  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  clearValue() {
    this.formControl.setValue(null);
  }

  displayLabel(value: any): string {
    if (typeof value === 'string') {
      return value;
    }

    if (!value) {
      return value;
    }

    const result = this.displayProperty ? value[this.displayProperty] : value;
    return result;
  }

  private _filter(value: any): any[] {

    if (!value) {
      return [];
    }

    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      if (!filterValue) {
        return [];
      }

      var self = this;
      return this.options?.filter((option) =>
        self.displayLabel(option).toString().toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }
}
