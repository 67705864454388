import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Observable,
  TimeoutError,
  catchError,
  finalize,
  lastValueFrom,
  mergeMap,
  of,
  shareReplay,
  tap,
  throwError,
  timeout,
  timer,
} from 'rxjs';
import { Md5 } from 'ts-md5';
import { MemoryCache, SnackbarService } from '..';

@Injectable({
  providedIn: 'root',
})
export class Http {
  private readonly defaultTimeout = 1000 * 60 * 10;

  private resultCache = new MemoryCache();

  private obsCache = new MemoryCache();

  constructor(
    private http: HttpClient,
    private snackBarService: SnackbarService,
    public dialog: MatDialog
  ) {}

  /**
   * Performs GET request. Results are cached for 15 minutes.
   * To avoid caching, set options.cache to false.
   * @param url
   * @param options
   * @returns
   */
  get<T>(
    url: string,
    options: HttpOptions = HttpOptions.getDefault
  ): Promise<T> {
    return this.request(url, 'GET', null, options);
  }

  /**
   * Performs POST request. Results are never cached for POSTs by this class.
   * If caching is needed, the caller has to provide it.
   * @param url
   * @param body
   * @returns
   */
  post<T>(
    url: string,
    body: any,
    options: HttpOptions = HttpOptions.postDefault
  ): Promise<T> {
    return this.request(url, 'POST', body, options);
  }

  private request<T>(
    url: string,
    method: HttpMethod,
    body: any,
    options: HttpOptions
  ): Promise<T> {
    options = Object.assign(new HttpOptions(), options);

    const cacheKey = this.getCacheKey(url, body, options);
    const result: T = this.getCacheEntry(cacheKey, options);
    let request$: Observable<T>;

    // cache hit - return cached object
    if (result !== null && typeof result !== 'undefined') {
      request$ = of(result);
    } else {
      // cache hit - return cached observable
      request$ = this.obsCache.get(cacheKey)?.value;
      if (!request$) {
        // cache miss - create http request
        request$ = this.createRequest(url, method, cacheKey, body, options);

        // cache the observable for 1 minute to prevent multiple parallel requests
        // to same resource before cache is populated.
        this.obsCache.set(cacheKey, request$, 60000);
      }
    }

    return lastValueFrom(request$);
  }

  /**
   * Deletes cache entry for url. Set options.queryParams if url
   * supports query parameters.
   */
  deleteCacheEntry(url: string, options: HttpOptions = HttpOptions.getDefault) {
    const cacheKey = this.getCacheKey(url, null, options);
    this.resultCache.delete(cacheKey);
  }

  /**
   * Clears all cached entries from Http service.
   */
  clearCache() {
    this.resultCache.clear();
  }

  /**
   * Deletes all cache entries where key starts with input value.
   */
  deleteCacheRange(startsWith: string) {
    this.resultCache.deleteRange(startsWith);
  }

  private getCacheKey(url: string, body: any, options: HttpOptions): string {
    const part1 = url;
    const part2 = options.queryParams
      ? new URLSearchParams(options.queryParams).toString()
      : '';
    const part3 = body ? Md5.hashStr(JSON.stringify(body)) : '';
    return `${part1}|${part2}|${part3}`;
  }

  private getCacheEntry<T>(cacheKey: string, options: HttpOptions): T {
    if (options.refreshCache) {
      this.resultCache.delete(cacheKey);
    }

    const cached = options.cache ? this.resultCache.get(cacheKey) : null;
    return cached?.value;
  }

  private setCacheEntry<T>(
    cacheKey: string,
    result: T,
    options: HttpOptions
  ): void {
    if (options.cache) {
      this.resultCache.set(cacheKey, result, 60000 * options.cacheMinutes);
    }
  }

  private getTimeOutMs(options: HttpOptions): number {
    return !options.timeout || options.timeout <= 0
      ? this.defaultTimeout
      : options.timeout;
  }

  private createRequest<T>(
    url: string,
    method: HttpMethod,
    cacheKey: string,
    body: any,
    options: HttpOptions
  ): Observable<T> {
    const timeoutValue = this.getTimeOutMs(options);
    const ops = {
      responseType: options.responseType as 'json',
      params: options.queryParams,
    };

    return timer(options.delay).pipe(
      mergeMap(() =>
        method === 'POST'
          ? this.http.post<T>(url, body, ops)
          : this.http.get<T>(url, ops)
      ),
      timeout(timeoutValue),
      shareReplay(1),
      tap((result) => this.setCacheEntry(cacheKey, result, options)),
      catchError((error) => this.handleError(error, url, timeoutValue)),
      finalize(() => this.obsCache.delete(cacheKey))
    );
  }

  private handleError(error: any, url: string, timeoutValue: number) {
    if (error instanceof TimeoutError) {
      const temp = new HttpErrorResponse({
        status: 0,
        statusText: error.message + ` (${timeoutValue}ms)`,
        url: url,
        error: {
          message: 'Client disconnected',
          type: '',
        },
      });

      // reassigning error to HttpErrorResponse
      // to work with snackbarService.showError
      // which handles HttpErrorResponse.
      error = temp;
    }

    return throwError(() => error);
  }
}

export class HttpOptions {
  static getDefault = new HttpOptions();

  static postDefault = new HttpOptions(false);

  /**
   * Cache GET requests if true. Never cache POST requests. 15 minute absolute expiration is default.
   * Override expiration time by setting cacheMinutes.
   */
  cache?: boolean = true;

  /**
   * Sets absolute expiration for cache entry. Default is 15 minutes.
   */
  cacheMinutes?: number = 15;

  /**
   * Delete cache entry before making the request. If cache=true, then this request is cached.
   */
  refreshCache?: boolean = false;

  /**
   * Client timeout in milliseconds. Leave as zero or null for default timeout of 10 minutes.
   */
  timeout?: number = 0;

  /**
   * Query parameters
   */
  queryParams?: any;

  /**
   * Response type. Default is json.
   */
  responseType?: ResponseType = 'json';

  /**
   * Delay request in milliseconds. Default is zero.
   */
  delay?: number = 0;

  constructor(cache = true) {
    this.cache = cache;
  }
}

export type ResponseType = 'json' | 'text' | 'blob';

export type HttpMethod = 'GET' | 'POST';
