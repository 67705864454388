import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  BaseComponent,
  ClaimDataService,
  ConfigurationSetting,
  DialogComponent,
  MyClaim,
  OverlayMode,
  ProgressService,
  SearchResult,
} from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import { UserActionService } from '../../../shared';
import { UserActions } from '../../../shared/models/user';
import { RestrictionTable } from '../../models/Restrictions';
import { RTWResponseStatus } from '../../models/rtw-response';
import { ActivityService } from '../../services/activity.service';
import { ConfigurationService } from '../../services/configuration.service';
import { emulator } from '../../services/emulator.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { LogService } from '../../services/log.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';
import { RestrictionsComponent } from './restrictions.component';

@Component({
  selector: 'app-return-to-work-page',
  template: `
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb class="tw-w-[90%]"></app-claim-breadcrumb>
      </div>

      <app-panel
        [largeHeader]="true"
        title="Report return to work"
        class="tw-w-full app-pb4"
        [showTitle]="true"
        [canExpand]="false"
      >
        <app-loading-panel
          [state]="loading || claimLoading ? 'Loading' : 'Loaded'"
        >
          <ng-template #content>
            <div
              class="app-pb2 tw-w-full"
              #rtweditdialog
              *ngIf="isRTW === 'submit'"
            >
              <form [formGroup]="formGroup">
                <div id="rtweditpnl">
                  <div class="tw-flex tw-flex-wrap">
                    <div class="tw-w-[50%] ">
                      <app-claimant-label
                        value
                        [claimant]="claim"
                        [showNameLabel]="true"
                        [disabled]="!userRole.manager || claim.userOwns"
                        [nameClass]="'headingbold'"
                      >
                      </app-claimant-label>
                    </div>

                    <div class="app-pb2 tw-w-[50%]">
                      <app-claim-label
                        value
                        [claim]="claim"
                        layout="row"
                        [showNameLabel]="true"
                        [hideWatchList]="
                          !(userRole.manager || userRole.controller)
                        "
                      ></app-claim-label>
                    </div>

                    <div
                      class="tw-w-full tw-flex tw-flex-wrap"
                      *ngIf="!isDisplaySuccess"
                    >
                      <div class="tw-w-full md:tw-w-[40%]">
                        <app-date-control
                          label="Return to Work Date *"
                          ngDefaultControl
                          [formGroup]="formGroup"
                          formControlName="rtwDate"
                        ></app-date-control>

                        @if(showRtwType){
                        <div class="tw-w-full">
                          <app-container-control
                            ngDefaultControl
                            [formGroup]="formGroup"
                            formControlName="duty"
                            class="app-pb3 headingbold"
                            [label]="
                              !isManagerRole
                                ? 'RETURN TO WORK TYPE *'
                                : 'Confirm Return to Work Date *'
                            "
                          >
                            <mat-radio-group
                              aria-label="Full Duty or restricted duty"
                              role="radiogroup"
                            >
                              <mat-radio-button value="Full Duty"
                                >Full Duty</mat-radio-button
                              >
                              <span class="app-pl2"></span>
                              <mat-radio-button value="Restricted Duty"
                                >Restricted Duty</mat-radio-button
                              >
                            </mat-radio-group>
                            <mat-error *ngIf="IsValidDuty" class="errormsg"
                              ><span class="rtwcustomerror">{{
                                getErrorMessage()
                              }}</span></mat-error
                            >
                          </app-container-control>
                        </div>
                        }

                        <div class="tw-w-full">
                          <app-text-area-control
                            label="Addtional Comments"
                            ngDefaultControl
                            [formGroup]="formGroup"
                            formControlName="comments"
                            [maxLength]="500"
                            [rows]="3"
                          ></app-text-area-control>
                        </div>
                      </div>
                      <div class="tw-w-full app-pt4">
                        <app-action-row>
                          <app-button
                            type="submit"
                            right2
                            autofocus
                            emphasis="High"
                            mat-raised-button
                            (click)="submitreturntowork()"
                          >
                            Submit
                          </app-button>
                          <app-button
                            right1
                            emphasis="Low"
                            (click)="clearForm()"
                            >Clear</app-button
                          >
                        </app-action-row>
                      </div>
                    </div>
                    <div *ngIf="isDisplaySuccess">
                      <br />
                      <app-toast type="Success">
                        {{ afterRtwSuccessMessage }}
                      </app-toast>
                      <app-action-link
                        imageUrl="./assets/images/upload.png"
                        title="Upload Documents"
                        helpText=""
                        [routerLink]=""
                        [imageWidth]="60"
                        [imageHeight]="53"
                        routerLink="/upload-documents-in-documentstab"
                        [queryParams]="{
                          focus: 'documents',
                          claimId: claim.claimId,
                          source: claim.source,
                          showDialog: true
                        }"
                      ></app-action-link>
                      <ng-template #uploadTemplate>
                        <app-dialog
                          title="Upload Documents"
                          ariaLabel="Upload documents dialog"
                          confirmLabel="Upload"
                          cancelLabel="Close"
                          [showActions]="true"
                          [showConfirm]="false"
                          [width]="700"
                          #dialog
                        >
                          <app-upload
                            [dialogRef]="dialog.dialogRef"
                            [claim]="claim"
                            [claimId]="claim.claimId"
                            [source]="claim.source"
                          ></app-upload>
                        </app-dialog>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="app-pb2 tw-w-full"
              #rtweditdialog
              *ngIf="isRTW === 'confirm'"
            >
              <form [formGroup]="formGroup">
                <div id="rtweditpnl">
                  <div class="tw-flex tw-flex-wrap">
                    <div class="tw-w-[50%] ">
                      <app-claimant-label
                        value
                        [claimant]="claim"
                        [showNameLabel]="true"
                        [nameClass]="'headingbold'"
                        [disabled]="!userRole.manager"
                      >
                      </app-claimant-label>
                    </div>

                    <div class="app-pb2 tw-w-[50%]">
                      <app-claim-label
                        value
                        [claim]="claim"
                        layout="row"
                        [noLink]="false"
                        [hideWatchList]="
                          !(userRole.manager || userRole.controller)
                        "
                      ></app-claim-label>
                    </div>
                    <div class="tw-w-full tw-flex tw-flex-wrap">
                      <div
                        class="tw-w-full md:tw-w-[40%]"
                        *ngIf="!isHideButtons"
                      >
                        <div class="tw-w-full">
                          <app-container-control
                            ngDefaultControl
                            [formGroup]="formGroup"
                            formControlName="duty"
                            class="app-pb3 headingbold"
                            label="Confirm {{
                              rtwTypeFromNotif
                            }} return to work date *"
                          >
                            <div *ngIf="rtwDateFromNotif">
                              <mat-label
                                >{{ rtwDateFromNotif | format : 'date' }}
                              </mat-label>
                            </div>
                            <mat-radio-group
                              aria-label="Confirm return to work"
                              role="radiogroup"
                            >
                              <mat-radio-button
                                (change)="toggleMethod(1)"
                                value="Yes"
                                >Yes</mat-radio-button
                              >
                              <span class="app-pl2"></span>
                              <mat-radio-button
                                (change)="toggleMethod(2)"
                                value="No"
                                >No</mat-radio-button
                              >
                            </mat-radio-group>
                          </app-container-control>
                        </div>

                        <div class="tw-w-full" *ngIf="showCRTWDate">
                          <mat-label class="headingbold"
                            >Change my {{ rtwTypeFromNotif }} Return to Work
                            Date to *</mat-label
                          >
                          <app-date-control
                            [label]="
                              isManagerRole
                                ? 'Return to Work Date *'
                                : 'Return to Work Date'
                            "
                            ngDefaultControl
                            [formGroup]="formGroup"
                            formControlName="rtwDate"
                            class="app-pt1"
                          ></app-date-control>
                        </div>
                      </div>
                      <div class="tw-w-full app-pt4" *ngIf="!isHideButtons">
                        <app-action-row>
                          <button
                            type="submit"
                            right2
                            autofocus
                            emphasis="High"
                            mat-raised-button
                            tabindex="0"
                            [ngClass]="
                              btnDisabled || loading
                                ? 'btn-info-disable'
                                : 'btn-info'
                            "
                            [disabled]="btnDisabled || loading"
                            (click)="updatereturntoworkDate()"
                          >
                            Submit
                          </button>
                          <app-button
                            right1
                            emphasis="Low"
                            (click)="clearForm()"
                            >Clear</app-button
                          >
                        </app-action-row>
                      </div>
                    </div>
                    <div *ngIf="isDisplaySuccess">
                      <br />
                      <app-toast type="Success">
                        {{ afterRtwSuccessMessage }}
                      </app-toast>
                      <app-action-link
                        imageUrl="./assets/images/upload.png"
                        title="Upload Documents"
                        helpText=""
                        [routerLink]=""
                        [imageWidth]="60"
                        [imageHeight]="53"
                        routerLink="/upload-documents-in-documentstab"
                        [queryParams]="{
                          focus: 'documents',
                          claimId: claim.claimId,
                          source: claim.source,
                          showDialog: true
                        }"
                      ></app-action-link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
        </app-loading-panel>
      </app-panel>
    </app-section>
  `,
  styles: [
    `
      /* #rtweditpnl ::ng-deep .mat-accent {
        background-color: #fff !important;
        color: #3f464d !important;
        margin-left: 3px;
      }
      #rtweditpnl ::ng-deep .mat-accent:hover {
        background-color: #f1f4f7 !important;
      }

      #rtweditpnl ::ng-deep .mat-mdc-unelevated-button {
        background-color: #007dbc !important;
        color: #fff !important;
      }
      #rtweditpnl ::ng-deep .mat-mdc-unelevated-button:hover {
        background-color: #035e81 !important;
      }
*/
      .mat-mdc-radio-button:focus {
        border: 1px solid #007dbc;
      }
      .errormsg {
        color: #c24533;
      }
      @media (max-device-width: 480px) {
        ::ng-deep .mat-mdc-text-field-wrapper {
          /* width : 250%;*/
        }
      }

      .btn-info:hover {
        background-color: #035e81;
      }
      .btn-info {
        font-size: 15px;
        color: #ffffff;
        line-height: 30px;
        border-radius: 40px;
        background: #244578;
        background-color: #007dbc;
        font-weight: bold;
      }

      .btn-info-disable {
        font-size: 15px;
        color: gray;
        line-height: 30px;
        border-radius: 40px;
        background-color: lightgray;
        font-weight: bold;
      }

      .rtwcustomerror {
        font-size: 12px;
        font-weight: 500;
        padding-left: 5%;
      }
    `,
  ],
})
export class ReturnToWorkPageComponent extends BaseComponent implements OnInit {
  claimId: string = '';
  claimConfig: ConfigurationSetting;
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  dialogData: any;

  @Input()
  showDidNotReturn: boolean = true;

  claim: any;
  isManagerRole: boolean = false;
  restrictionTable: RestrictionTable[];
  isDisplaySuccess: boolean = false;
  afterRtwSuccessMessage: string;
  allowFileUpload: boolean = false;
  //alertMasterUid: string = null;
  btnDisabled: boolean = true;
  showCRTWDate: boolean = false;
  isHideButtons: boolean = false;
  examinerLabel: string;
  isRTW: string = null;
  rtwTypeFromNotif: string = '';
  rtwDateFromNotif: string = '';
  mgrAction: UserActions | null;
  loading: boolean = true;
  claimLoading: boolean = true;
  alertMasterId: string;
  showRtwType: boolean = true;

  @Input()
  LOB: string;

  @Input()
  hideHeader: boolean = false;

  @Input()
  claimsHistory: any;

  isController: boolean = false;

  @Output()
  AfterSuccess: EventEmitter<RTWResponseStatus> =
    new EventEmitter<RTWResponseStatus>();

  get Duty() {
    return this.formGroup.get('duty');
  }

  IsValidDuty: boolean = false;
  formGroup: FormGroup = this.fb.group({
    comments: [],
    noReturn: [],
  });

  @ViewChild('restrictionChild') restrictionChild: RestrictionsComponent;
  @ViewChild('rtweditdialog') rtwdialoag: DialogComponent;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    public session: Session,
    private activityService: ActivityService,
    public claimDataService: ClaimDataService,
    public inpageAliasService: InpageAliasService,
    private configurationService: ConfigurationService,
    public overlayService: ProgressService,
    private enumalatorService: emulator,
    public logService: LogService,
    public userRole: UserRoleService,
    public dialog: MatDialog,
    private userActions: UserActionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.dialogData = data != null ? data : null;
    this.allowFileUpload = this.dialogData.uploadFile;
  }

  createManagementNotificationForm() {
    this.formGroup.addControl('rtwDate', new FormControl(''));
    this.formGroup.addControl('duty', new FormControl(''));
  }

  async ngOnInit() {
    this.isManagerRole = this.userRole.supervisor;
    this.isController = this.userRole.controller;
    if (this.isManagerRole) {
      this.mgrAction = this.userActions.getAction();
    }
    this.createManagementNotificationForm();
    if (this.configurationService.GetConfig.configFeatures) {
      this.examinerLabel = await this.inpageAliasService.replaceAliases(
        'examinerSingular',
        this.configurationService.GetConfig.configFeatures.aliases
      );
    }
    this.subs.sink = this.route.queryParams.subscribe((p) =>
      this.loadClaimFromUrl()
    );
  }

  async navigateToClaim() {
    if (this.claimsHistory != null) {
      this.router.navigate(['claims'], {
        queryParams: {
          focus: 'documents',
          claimId: this.claimsHistory.claimId,
          source: this.claimsHistory.source,
          lob: this.claimsHistory.lob,
        },
      });
    } else {
      let myClaim: MyClaim;
      myClaim = { ...myClaim, ...this.claim };
      this.claimDataService.setMyClaim(myClaim);
      this.router.navigate(['claims'], {
        queryParams: {
          focus: 'documents',
          claimId: this.claim.claimId,
          source: this.claim.source,
          lob: this.claim.lob,
        },
      });
    }
  }

  private async loadClaimFromUrl() {
    this.isRTW = this.route.snapshot.queryParamMap.get('rtw');
    this.rtwTypeFromNotif = this.route.snapshot.queryParamMap.get('rtwType');
    this.alertMasterId =
      this.route.snapshot.queryParamMap.get('alertMasterUid');
    const claimId = this.route.snapshot.queryParamMap.get('claimId');
    const source = this.route.snapshot.queryParamMap.get('source');
    if (claimId) {
      const claim = await lastValueFrom(
        this.http.get<SearchResult>(
          `/api/claim/${source}/${claimId}/${'NA'}/summary`
        )
      );
      this.setClaim(claim);
      this.claimLoading = false;
    }
    const rtwDateFromUrl = this.route.snapshot.queryParamMap.get('rtwDate');
    if (rtwDateFromUrl != null && rtwDateFromUrl != undefined) {
      const valueReplace = rtwDateFromUrl.replaceAll('%2F', '/');
      this.rtwDateFromNotif = valueReplace;
    }
  }

  private async setClaim(claim: SearchResult) {
    if (claim === null) {
      return;
    }
    this.claimId = claim.claimId;
    this.claimInfo$.next(claim);
    this.claim = claim;
    this.showRtwType = this.claim?.lob !== 'LV';
    this.claimConfig = await this.configurationService.getClaimConfiguration(
      claim
    );
    this.loading = false;
  }

  IsErrorMessage() {
    return this.IsValidDuty;
  }
  getErrorMessage() {
    return 'Error : Return to Work Type is required.';
  }

  toggleMethod(val1: number) {
    if (val1 == 1) {
      this.showCRTWDate = false;
      this.btnDisabled = false;
      this.formGroup.get('rtwDate').clearValidators();
      this.formGroup.get('rtwDate').updateValueAndValidity();
    }
    if (val1 == 2) {
      this.showCRTWDate = true;
      this.btnDisabled = false;
    }
  }

  async submitreturntowork() {
    this.afterRtwSuccessMessage = '';
    if (
      this.formGroup.get('rtwDate').value == '' ||
      this.formGroup.get('rtwDate').value == undefined
    ) {
      this.formGroup
        .get('rtwDate')
        .setErrors({ 'Error : Return to Work Date is required.': true });
      this.formGroup.get('rtwDate').markAsTouched();
    }
    if (
      this.showRtwType &&
      (this.formGroup.get('duty').value == '' ||
        this.formGroup.get('duty').value == undefined)
    ) {
      this.formGroup
        .get('duty')
        .setErrors({ 'Error : Return to Work Type is required.': true });
      this.IsValidDuty = true;
    }
    if (this.formGroup.valid) {
      this.enumalatorService.showAuditorInfo();
      this.overlayService.show(OverlayMode.Spinner, 'Please wait..');
      const rtwData: any = {
        RtwDate:
          this.isRTW === 'confirm'
            ? this.rtwDateFromNotif
            : this.formGroup.get('rtwDate')?.value !== null
            ? new Date(this.formGroup.get('rtwDate').value)
            : null,
        RtwType:
          this.isRTW === 'confirm'
            ? this.rtwTypeFromNotif
            : this.formGroup.get('duty').value,
        Message: this.formGroup.get('comments').value ?? '',
        EmpUnum: this.claim.empUnum,
        LineOfBusiness: this.claim.lob,
        UniqueId: this.claim.claimId,
        ClaimNumber: this.claim.claimNumber,
        ClientNumber: this.claim.client,
        UserClaim: this.claim.userOwns,
        ExaminerName: this.claim.examinerName,
        ExaminerEmail:
          this.claim.examinerEmail != undefined ? this.claim.examinerEmail : '',
        LossDate: this.claim.lossDate,
        Name: this.claim.name,
        Type: this.claim.type,
        Status: this.claim.status,
        SubStatus:
          this.claim.subStatus != undefined ? this.claim.subStatus : '',
      };

      this.http.post<any>('/api/returntowork', rtwData).subscribe(
        (result) => {
          this.overlayService.hide();

          this.formGroup.reset();

          const isLiability = this.LOB != 'DS' && this.LOB != 'LV';

          this.isDisplaySuccess = true;
          if (
            !(
              result.requestValid &&
              result.jurisReqStatus &&
              result.tamsReqStatus
            ) &&
            !isLiability
          ) {
            this.afterRtwSuccessMessage =
              'The return to work date was previously reported. Thank you for ensuring we have your most recent return to work status.';
          } else if (
            (result.requestValid &&
              result.jurisReqStatus &&
              result.tamsReqStatus) ||
            isLiability
          ) {
            this.afterRtwSuccessMessage =
              'Your return to work date has been submitted.  The ' +
              this.examinerLabel +
              ' will contact you if they have any further questions.  To provide any supporting documents for the return to work status, click the Upload Documents link below.';
          }
          if (this.alertMasterId != null) {
            const alertType = this.getAlertType();
            this.geterateTriggerKeyAndSession(
              alertType,
              this.claim.claimNumber,
              this.claim.source,
              this.alertMasterId
            );

            this.activityService.DismissNotificationAlert(
              alertType,
              this.claim.claimNumber,
              this.claim.claimId,
              this.claim.source,
              this.claim.userOwns
            );
          }
        },
        (error) => {
          console.log(error);
          this.overlayService.hide();
        }
      );
    }
  }

  clearForm() {
    this.formGroup.reset();
    this.isDisplaySuccess = false;
    this.afterRtwSuccessMessage = '';
  }

  getAlertType(): string {
    const alertType =
      this.userRole.manager &&
      (this.mgrAction == UserActions.ViewOwnMgrDb ||
        this.mgrAction == UserActions.MgrViewEmpDb)
        ? 'RTW_CONFIRMATION_RM'
        : 'RTW_CONFIRM_NEEDED_A'; // not to overdo the queryparms length

    return alertType;
  }

  geterateTriggerKeyAndSession(
    alertType: string,
    claimNumber: string,
    source: string,
    alertMasterId: string
  ) {
    const triggerKey = `${alertType}_${claimNumber}_${source}`;
    sessionStorage.setItem('triggerKey', triggerKey);
    sessionStorage.setItem('alertMasterId', alertMasterId);
  }

  async updatereturntoworkDate() {
    this.afterRtwSuccessMessage = '';
    if (this.showCRTWDate == true) {
      if (
        this.formGroup.get('rtwDate').value == '' ||
        this.formGroup.get('rtwDate').value == undefined
      ) {
        if (this.isManagerRole) {
          this.formGroup.get('rtwDate').setErrors({
            'Error : New Return to Work Date is required.': true,
          });
        } else if (!this.isManagerRole) {
          this.formGroup.get('rtwDate').setErrors({
            'Error : Change my Return to Work Date to is required.': true,
          });
        }
        this.formGroup.get('rtwDate').markAsTouched();
      }
    }

    if (this.formGroup.valid) {
      this.enumalatorService.showAuditorInfo();
      this.overlayService.show(OverlayMode.Spinner, 'Please wait..');

      const request = {
        ClaimUid:
          this.claim.lob == 'LV' ? this.claim.claimNumber : this.claim.claimId,
        UniqueId:
          this.claim.lob == 'LV' ? this.claim.claimNumber : this.claim.claimId,
        FileNumber: this.claim.claimNumber,
        LineOfBusiness: this.claim.lob,
        examinerName: this.claim.examinerId,
        NewRtwDate: this.showCRTWDate
          ? this.formGroup.get('rtwDate').value != undefined
            ? this.formGroup.get('rtwDate').value.toLocaleDateString()
            : new Date(
                this.claim.anticipatedReturnToWorkDate
              ).toLocaleDateString()
          : new Date(this.rtwDateFromNotif).toLocaleDateString(),
        RtwType: this.rtwTypeFromNotif
          ? this.rtwTypeFromNotif
          : this.formGroup.get('duty').value,
        Message: this.formGroup.get('comments').value ?? '',
        UnknownRtw: false, // setting this to false as we always have a date for RTW unlike 4.0
        EmpUnum: this.claim.empUnum,
        SourceSystem: this.claim.source,
        AlertMasterUid: this.alertMasterId,
      };

      this.http.post<any>('/api/returntowork/NewRTWDate', request).subscribe(
        (result) => {
          this.overlayService.hide();
          this.isDisplaySuccess = true;
          const CRTWRBval = this.formGroup.get('duty').value;
          if (result.jurisReqStatus) {
            if (CRTWRBval == 'Yes') {
              this.afterRtwSuccessMessage =
                'Thank you.  Your Return to Work Date has been successfully confirmed.';
            } else {
              this.afterRtwSuccessMessage =
                'Thank you.  Your Return to Work Date has been successfully updated.';
            }
            this.isHideButtons = true;
            const alertType = this.getAlertType();

            this.geterateTriggerKeyAndSession(
              alertType,
              this.claim.claimNumber,
              this.claim.source,
              this.alertMasterId
            );

            this.activityService.DismissNotificationAlert(
              alertType,
              this.claim.claimNumber,
              this.claim.claimId,
              this.claim.source,
              this.claim.userOwns,
              'Rtw'
            );
          }
        },
        (error) => {
          console.log(error);
          this.overlayService.hide();
        }
      );
    }
  }
}
