import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbsenceService, Employee, IncidentalAbsence } from '../..';

@Component({
  selector: 'app-absence-page',
  template: `
    <app-section>
      <app-absence-summary
        class="tw-w-full"
        [absences]="absences"
        [sentenceCase]="false"
        title="Absences for {{ firstName }} {{ lastName }} - {{ empId }}"
      ></app-absence-summary>
      <!-- <app-absence-calendar
        class="tw-w-full"
        [events]="events"
      ></app-absence-calendar> -->
    </app-section>
  `,
  styles: [],
})
export class AbsencePageComponent implements OnInit {
  absences: IncidentalAbsence[] = [];

  firstName: any;
  lastName: any;
  empId: any;
  constructor(
    private absenceService: AbsenceService,
    private route: ActivatedRoute
  ) {}

  nameCase(input: string) {
    let output = '';
    for (let i = 0; i < input.length; i++) {
      if (i == 0) {
        output += input.charAt(i).toUpperCase();
      } else {
        output += input.charAt(i).toLowerCase();
      }
    }
    return output;
  }

  async ngOnInit() {
    var empunum = this.route.snapshot.queryParamMap.get('empunum');
    if (empunum != null && empunum != undefined && empunum.length > 2) {
      this.firstName = this.nameCase(sessionStorage.getItem('firstName'));
      this.lastName = this.nameCase(sessionStorage.getItem('lastName'));
      this.empId = sessionStorage.getItem('empId');
      var listAbsenceOffWork = await this.absenceService.getEmployeeAbsences(
        empunum
      );
      this.absences = this.absenceService.mergeDateToString(listAbsenceOffWork);
    }
  }
}
