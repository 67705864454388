import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProgressService, SnackbarService, SnackbarType } from 'src/app/shared';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackBarService: SnackbarService,
    private overlayService: ProgressService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.overlayService.showing) {
          this.overlayService.hide();
        }

        if (error.status === 400) {
          this.snackBarService.show(
            'Invalid request. Correct form data before submitting.',
            SnackbarType.Error,
            3000
          );
        } else if (error.status === 401) {
          this.snackBarService.show(
            'Your session has expired. Log in to continue.',
            SnackbarType.Info,
            3000
          );
        } else if (
          (error.status >= 300 || error.status === 0) &&
          error.status < 600
        ) {
          this.snackBarService.show(error.message, SnackbarType.Error, 3000);
        }

        return throwError(() => error);
      })
    );
  }
}
