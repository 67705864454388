import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ActDelegatePageComponent,
  ActionComponent,
  ActionLinkComponent,
  ActionsComponent,
  ActivityComponent,
  ActivityPageComponent,
  BenefitsComponent,
  CardChartComponent,
  ChangeAuthenticationPageComponent,
  ClaimDetailComponent,
  ClaimHeaderComponent,
  ClaimHistoryComponent,
  ClaimHistoryPageComponent,
  ClaimLabelComponent,
  ClaimStatusPipe,
  ClaimSummaryComponent,
  ClaimsPageComponent,
  CommCenter2ClaimsComponent,
  CommunicationPrefPageComponent,
  ContactListComponent,
  ContactPreferencesComponent,
  CreateDirective,
  CustomMessageComponent,
  DashboardPageComponent,
  DesignPageComponent,
  DetailSearchFormComponent,
  DismissDialogComponent,
  DocumentListComponent,
  EmployeeLabelComponent,
  EmptyPageComponent,
  FocusDirective,
  HelpPageComponent,
  LayoutComponent,
  LearningCenterComponent,
  LeaveBalanceComponent,
  ManageDelegatePageComponent,
  ManagerDashboardComponent,
  MileageReceiptUploadComponent,
  MileageReimbursementDetailsComponent,
  MileageReimbursementExpenseComponent,
  MileageReimbursementPageComponent,
  MyClaimsComponent,
  MyWorkSchedulePageComponent,
  NewClaimComponent,
  NewClaimsComponent,
  NewClaimsPageComponent,
  NotFoundPageComponent,
  NotificationComponent,
  NotificationsComponent,
  NotificationsListComponent,
  OffWorkComponent,
  OffWorkPageComponent,
  OpenClaimsPageComponent,
  PaymentComponent,
  PreferencesPageComponent,
  PreviewModeDirective,
  QuickSearchComponent,
  RemindersComponent,
  RestrictionDetailComponent,
  RestrictionsComponent,
  ReturnToWorkComponent,
  ReturnToWorkEditComponent,
  ReturnToWorkListPageComponent,
  SearchPageComponent,
  SearchResultsComponent,
  SecurityQuestionsPageComponent,
  UnauthorizedPageComponent,
  UploadComponent,
  VideoDialogComponent,
  VisitLearningCenterComponent,
  WatchListComponent,
  WatchListPageComponent,
  WelcomeComponent,
  WorkScheduleComponent,
  SiteMapPageComponent,
} from 'src/app/my-sedgwick';
import { PreviewModeHeaderComponent } from 'src/app/my-sedgwick/components/common/preview-mode-header.component';
import { PreviewModeComponent } from 'src/app/my-sedgwick/components/misc/preview-mode.component';
import { AnnouncementsComponent } from '../my-sedgwick/components/announcements/announcements.component';
import { EnduserLicenseagreementComponent } from '../my-sedgwick/components/enduser-licenseagreement/enduser-licenseagreement.component';
import { ToggleComponent } from '../shared/components/controls/toggle.component';
import { SharedModule } from '../shared/shared.module';
import { AbsenceCalendarComponent } from './components/absence/absence-calendar.component';
import { AbsenceCaseSearchComponent } from './components/absence/absence-case-search.component';
import { AbsenceDashboardPageComponent } from './components/absence/absence-dashboard-page.component';
import { AbsenceGridComponent } from './components/absence/absence-grid.component';
import { AbsencePageComponent } from './components/absence/absence-page.component';
import { AbsenceSearchComponent } from './components/absence/absence-search.component';
import { AbsenceSummaryComponent } from './components/absence/absence-summary.component';
import { EditAbsenceDialogComponent } from './components/absence/edit-Absence-dialog.component';
import { MyAbsenceGridComponent } from './components/absence/my-absence-grid.component';
import { MyAbsenceComponent } from './components/absence/my-absence.component';
import { NewAbsencesComponent } from './components/absence/new-absence.component';
import { RemoveAbsenceDialogComponent } from './components/absence/remove-Absence-dialog.component';
import { AccommodationListComponent } from './components/accommodation/accommodation-list.component';
import { AccommodationPageComponent } from './components/accommodation/accommodation-page.component';
import { ApprovedJobFunctionComponent } from './components/accommodation/approved-job-function.component';
import { ApprovedJobFunctionsPanelComponent } from './components/accommodation/approved-job-functions-panel.component';
import { PendingAccommodationsPageComponent } from './components/accommodation/pending-accommodations-page.component';
import { UnapprovedAccommodationsPanelComponent } from './components/accommodation/unapproved-accommodations-panel.component';
import { UnapprovedJobFunctionsPanelComponent } from './components/accommodation/unapproved-job-functions-panel.component';
import { ConfirmReturnToWorkComponent } from './components/activity/confirm-return-to-work.component';
import { MyClaimsPanelComponent } from './components/activity/myclaimspanel.component';
import { NewClaimsEditComponent } from './components/activity/new-claims-edit.component';
import { PendingAccomodationsComponent } from './components/activity/pending-accomodations.component';
import { RestrictionsDialogComponent } from './components/activity/restrictions-dialog.component';
import { ReturnToWorkPageComponent } from './components/activity/return-to-work-page.component';
import { SubmitReturnToWorkDialogComponent } from './components/activity/submit-return-to-work-dialog.component';
import { OffWorkCalendarComponent } from './components/calendar/off-work-calendar.component';
import { OffWorkExportComponent } from './components/calendar/off-work-export.component';
import { CertificationsComponent } from './components/certifications/certifications.component';
import { ClaimListReportAbsenceComponent } from './components/claim-list-report-absence/claim-list-report-absence.component';
import { ActionTilesComponent } from './components/claim/action-tiles.component';
import { ClaimBreadcrumbComponent } from './components/claim/claim-breadcrumb.component';
import { ClaimTimelineComponent } from './components/claim/claim-timeline.component';
import { DirectDepositPageComponent } from './components/claim/direct-deposit-page.component';
import { LeaveComponent } from './components/claim/leave.component';
import { NotificationsPanelComponent } from './components/claim/notifications-panel.component';
import { PharmacyCardPageComponent } from './components/claim/pharmacy-card-page.component';
import { ReportAnAbsencePageComponent } from './components/claim/report-an-absence-page.component';
import { WorkStatusComponent } from './components/claim/workstatus.component';
import { CommCenter2CardComponent } from './components/comm-center2/comm-center2-card.component';
import { CommCenter2MessagesCardComponent } from './components/comm-center2/comm-center2-messages-card.component';
import { CommCenter2MessagesComponent } from './components/comm-center2/comm-center2-messages.component';
import { CommCenter2PageComponent } from './components/comm-center2/comm-center2-page.component';
import { CommCenter2SearchComponent } from './components/comm-center2/comm-center2-search.component';
import { NotificationsHeaderComponent } from './components/common/app-notifications-header';
import { EmulateUserHeaderComponent } from './components/common/emulate-user-header.component';
import { HelpButtonComponent } from './components/common/help-button.component';
import { PageHeaderComponent } from './components/common/page-header.component';
import { TimeoutDialogComponent } from './components/common/timeout-dialog.component';
import { WatchListButtonComponent } from './components/common/watch-list-button.component';
import { ContactPreferencesEditComponent } from './components/contacts/contact-preferences-edit.component';
import { ManagerSelectorComponent } from './components/controls/manager-selector.component';
import { ControllerDashboardPageComponent } from './components/dashboard/controller-dashboard-page.component';
import { ManagerDashboardPageComponent } from './components/dashboard/manager-dashboard-page.component';
import { SummaryDashboardComponent } from './components/dashboard/summary-dashboard.component';
import { AuditorDashboardPageComponent } from './components/dashboard/auditor-dashboard-page.component';
import { EndClaimantPreviewComponent } from './components/end-claimant-preview/end-claimant-preview.component';
import { FormPageComponent } from './components/form/form-page.component';
import { ClaimantLabelComponent } from './components/labels/claimant-label.component';
import { HeaderLabelComponent } from './components/labels/header-label.component';
import { HeaderComponent } from './components/layout/header.component';
import { FooterComponent } from './components/layout/footer.component';
import { SidenavComponent } from './components/layout/sidenav.component';
import { LearningCenterAdaComponent } from './components/learning-center/learning-center-ada.component';
import { LearningCenterAuComponent } from './components/learning-center/learning-center-au.component';
import { LearningCenterDsComponent } from './components/learning-center/learning-center-ds.component';
import { LearningCenterGlComponent } from './components/learning-center/learning-center-gl.component';
import { LearningCenterHeaderComponent } from './components/learning-center/learning-center-header.component';
import { LearningCenterLiabilityBaseComponent } from './components/learning-center/learning-center-liability-base.component';
import { LearningCenterLvComponent } from './components/learning-center/learning-center-lv.component';
import { LearningCenterPlComponent } from './components/learning-center/learning-center-pl.component';
import { LearningCenterPrComponent } from './components/learning-center/learning-center-pr.component';
import { LearningCenterSectionComponent } from './components/learning-center/learning-center-section.component';
import { LearningCenterWcComponent } from './components/learning-center/learning-center-wc.component';
import { ManagerRemindersComponent } from './components/manager/manager-reminders.component';
import { ExpenseEditComponent } from './components/mileage/expense-edit.component';
import { MileageEditPageComponent } from './components/mileage/mileage-edit-page.component';
import { MileageReviewPageComponent } from './components/mileage/mileage-review-page.component';
import { CardDashboardComponent } from './components/omni/core/card-dashboard.component';
import { NewClaimsCardComponent } from './components/omni/new-claims/new-claims-card.component';
import { NotificationsCardComponent } from './components/omni/notifications/notifications-card.component';
import { NotificationsPageComponent } from './components/omni/notifications/notifications-page.component';
import { OffWorkCardComponent } from './components/omni/off-work/off-work-card.component';
import { OpenClaimsCardComponent } from './components/omni/open-claims/open-claims-card.component';
import { PendingAccommodationsCardComponent } from './components/omni/pending-accomodations/pending-accomodations-card.component';
import { PendingAccomodationsPageComponent } from './components/omni/pending-accomodations/pending-accomodations-page.component';
import { ReportNewClaimCardComponent } from './components/omni/report-claim/report-claim-card.component';
import { ReturnToWorkCardComponent } from './components/omni/return-to-work/return-to-work-card.component';
import { PopupHeaderComponent } from './components/popup-header/popup-header.component';
import { ChangePasswordPageComponent } from './components/preferences/change-password-page.component';
import { ManagementNotificationPageComponent } from './components/preferences/management-notification.component';
import { PasswordComponent } from './components/preferences/password.component';
import { PtoComponent } from './components/pto/pto.component';
import { ReportNewClaimComponent } from './components/report-new-claim/report-new-claim.component';
import { SearchCountRowComponent } from './components/search/search-count-row.component';
import { SearchEmployeesListComponent } from './components/search/search-employees-list.component';
import { SearchResultsListComponent } from './components/search/search-results-list.component';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { TechnicalDifficultiesComponent } from './components/technical-difficulties/technical-difficulties.component';
import { SendDocumentDialogComponent } from './components/upload/send-document-dialog.component';
import { UploadPageComponent } from './components/upload/upload-page.component';
import { MySedgwickRoutingModule } from './my-sedgwick-routing.module';
import { SkipToMainComponent } from './components/accessibility/skip-to-main';

@NgModule({
  declarations: [
    LayoutComponent,
    DashboardPageComponent,
    ManagerDashboardComponent,
    QuickSearchComponent,
    VisitLearningCenterComponent,
    LearningCenterComponent,
    ClaimsPageComponent,
    MileageReimbursementPageComponent,
    MileageReimbursementDetailsComponent,
    MileageReimbursementExpenseComponent,
    ClaimDetailComponent,
    PaymentComponent,
    NotificationComponent,
    ActionComponent,
    ActionsComponent,
    ActionLinkComponent,
    SearchPageComponent,
    ActivityComponent,
    ActivityPageComponent,
    CommunicationPrefPageComponent,
    SecurityQuestionsPageComponent,
    SearchResultsComponent,
    ContactPreferencesComponent,
    ContactListComponent,
    DocumentListComponent,
    ClaimSummaryComponent,
    CreateDirective,
    FocusDirective,
    PreviewModeDirective,
    OffWorkComponent,
    ReturnToWorkComponent,
    WelcomeComponent,
    NewClaimsComponent,
    MyClaimsComponent,
    NotificationsComponent,
    NewClaimComponent,
    DismissDialogComponent,
    HelpPageComponent,
    VideoDialogComponent,
    LeaveBalanceComponent,
    WatchListComponent,
    ClaimHistoryComponent,
    ClaimHistoryPageComponent,
    WatchListPageComponent,
    SiteMapPageComponent,
    EmptyPageComponent,
    NotFoundPageComponent,
    UnauthorizedPageComponent,
    ReturnToWorkEditComponent,
    DesignPageComponent,
    RestrictionsComponent,
    UploadComponent,
    BenefitsComponent,
    LeaveComponent,
    RestrictionDetailComponent,
    ClaimLabelComponent,
    EmployeeLabelComponent,
    AbsencePageComponent,
    AbsenceSummaryComponent,
    AbsenceCalendarComponent,
    EnduserLicenseagreementComponent,
    AnnouncementsComponent,
    PreferencesPageComponent,
    ChangePasswordPageComponent,
    ChangeAuthenticationPageComponent,
    PasswordComponent,
    ReportNewClaimComponent,
    PasswordComponent,
    ClaimantLabelComponent,
    SubmitReturnToWorkDialogComponent,
    RestrictionsDialogComponent,
    TimeoutDialogComponent,
    TechnicalDifficultiesComponent,
    AbsenceDashboardPageComponent,
    AbsenceSearchComponent,
    AbsenceCaseSearchComponent,
    AbsenceGridComponent,
    EditAbsenceDialogComponent,
    RemoveAbsenceDialogComponent,
    MyAbsenceComponent,
    MyAbsenceGridComponent,
    NewAbsencesComponent,
    ManagementNotificationPageComponent,
    MyClaimsComponent,
    PharmacyCardPageComponent,
    DirectDepositPageComponent,
    ReportAnAbsencePageComponent,
    ClaimBreadcrumbComponent,
    ReturnToWorkPageComponent,
    LearningCenterDsComponent,
    LearningCenterWcComponent,
    LearningCenterGlComponent,
    LearningCenterAuComponent,
    LearningCenterLvComponent,
    LearningCenterPlComponent,
    LearningCenterPrComponent,
    LearningCenterAdaComponent,
    LearningCenterHeaderComponent,
    LearningCenterSectionComponent,
    LearningCenterLiabilityBaseComponent,
    ContactPreferencesEditComponent,
    SendDocumentDialogComponent,
    UploadPageComponent,
    ClaimHeaderComponent,
    CustomMessageComponent,
    ToggleComponent,
    ConfirmReturnToWorkComponent,
    ClaimTimelineComponent,
    FormPageComponent,
    MileageEditPageComponent,
    MileageReviewPageComponent,
    ExpenseEditComponent,
    EndClaimantPreviewComponent,
    MileageReceiptUploadComponent,
    PreviewModeComponent,
    PreviewModeHeaderComponent,
    EmulateUserHeaderComponent,
    HelpButtonComponent,
    MyClaimsPanelComponent,
    OffWorkCalendarComponent,
    NotificationsPanelComponent,
    ActionTilesComponent,
    WorkScheduleComponent,
    RemindersComponent,
    ClaimListReportAbsenceComponent,
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    ManagerSelectorComponent,
    ManagerRemindersComponent,
    ControllerDashboardPageComponent,
    WorkStatusComponent,
    CertificationsComponent,
    NewClaimsEditComponent,
    SupportDialogComponent,
    WatchListButtonComponent,
    OffWorkExportComponent,
    SummaryDashboardComponent,
    AuditorDashboardPageComponent,
    NewClaimsCardComponent,
    DetailSearchFormComponent,
    OffWorkPageComponent,
    OpenClaimsPageComponent,
    ReturnToWorkListPageComponent,
    ReportNewClaimCardComponent,
    NewClaimsPageComponent,
    CardDashboardComponent,
    OffWorkCardComponent,
    ReturnToWorkCardComponent,
    NewClaimsCardComponent,
    CardChartComponent,
    OpenClaimsCardComponent,
    PendingAccommodationsCardComponent,
    PendingAccomodationsPageComponent,
    NotificationsPageComponent,
    NotificationsListComponent,
    PendingAccomodationsComponent,
    NotificationsCardComponent,
    SearchResultsListComponent,
    ManagerDashboardPageComponent,
    NotificationsHeaderComponent,
    SearchCountRowComponent,
    ActDelegatePageComponent,
    ManageDelegatePageComponent,
    SearchEmployeesListComponent,
    MyWorkSchedulePageComponent,
    PtoComponent,
    MyWorkSchedulePageComponent,
    CommCenter2PageComponent,
    CommCenter2SearchComponent,
    CommCenter2ClaimsComponent,
    CommCenter2MessagesComponent,
    CommCenter2CardComponent,
    CommCenter2MessagesCardComponent,
    ApprovedJobFunctionsPanelComponent,
    UnapprovedJobFunctionsPanelComponent,
    UnapprovedAccommodationsPanelComponent,
    AccommodationPageComponent,
    AccommodationListComponent,
    ApprovedJobFunctionComponent,
    HeaderLabelComponent,
    PopupHeaderComponent,
    PageHeaderComponent,
    PendingAccommodationsPageComponent,
    ClaimStatusPipe,
    SkipToMainComponent,
  ],
  imports: [CommonModule, SharedModule, MySedgwickRoutingModule],
  exports: [SubmitReturnToWorkDialogComponent, TimeoutDialogComponent],
  providers: [],
})
export class MySedgwickModule {}
