import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NoteRequest } from 'src/app/my-sedgwick/models/NoteRequest';
import {
  BaseComponent,
  ConfigurationSetting,
  ProgressService,
  Random,
  RouterService,
  SearchResult,
  getUrlParams,
} from 'src/app/shared';
import { ClaimInfo } from 'src/app/shared/models/search-result';
import {
  MileageDetail,
  UserMileageReimbursement,
  UserMileageReimbursementDetail,
} from '../../models/Mileages';
import { HistoryInfo } from '../../models/historyinfo';
import { SubmitMileageRequest } from '../../models/mileage-request';
import { ActivityService } from '../../services/activity.service';
import { ClaimService } from '../../services/claim.service';
import { ConfigurationService } from '../../services/configuration.service';
import { emulator } from '../../services/emulator.service';
import { InpageAliasService } from '../../services/inpage-alias.service';
import { MileageService } from '../../services/mileage.service';
import { Session } from '../../services/session.service';
import { UserDetails } from '../../services/userdetails.service';
import { MileageReimbursementDetailsComponent } from './mileage-details.component';
import { MileageReviewPageComponent } from './mileage-review-page.component';

@Component({
  selector: 'app-mileage-page',
  template: `
    <div>
      <app-section class="tw-w-full">
        <div class="app-pl2 tw-w-full">
          <app-claim-breadcrumb></app-claim-breadcrumb>
        </div>

        <app-panel
          title="Mileage reimbursement"
          id="mileagePnl"
          class="tw-w-full"
          [largeHeader]="true"
          [canExpand]="false"
        >
          <div class="panelFix">
            <app-claim-header
              [summary]="claim"
              class="tw-w-full"
            ></app-claim-header>

            <div *ngIf="showIntro">
              <br />
              <span class="app-header-2">
                Before we begin you need the following information ready:
              </span>
              <li>All receipts for this request</li>
              <li>
                All addresses from travel start to finish, including city,
                state, and zip code
              </li>
              <br />
              <div *ngIf="mileageData && mileageData.stateMessage">
                <div class="app-header-3">{{ mileageData.stateName }}</div>
                <div>{{ mileageData.stateMessage }}</div>
              </div>

              <app-action-row class="tw-w-full pdt15">
                <button
                  *ngIf="!searching"
                  mat-button
                  right2
                  alt="I'm ready"
                  emphasis="High"
                  (click)="showNext()"
                  class="btn-info roundbtn reportNewClmBtn pdl15 pdr15"
                  mat-raised-button
                  tabindex="0"
                >
                  I'm ready
                </button>
              </app-action-row>
              <div
                class="tw-w-full pdt15 "
                *ngIf="mileageData && mileageData.documentList.length > 0"
              >
                <span class="app-header-2 pdt15">
                  Reimbursements requested
                </span>
                <br />
                <br />

                <app-list
                  [dataSource]="mileageData.documentList"
                  [template]="row"
                  [filterEnabled]="false"
                  [paginatorEnabled]="false"
                  class="app-pt2 pdt15"
                  loadingMessage=""
                  noDataIcon="attachment"
                  [loaded]="true"
                  noDataMessage="<br><strong>No documents available for this claim.</strong>"
                  [tableAriaLabel]="'Reimbursements requested'"
                >
                  <ng-container header>
                    <tr class="tw-flex tw-flex-wrap tw-w-full tw-items-center">
                      <app-list-header
                        name="fileName"
                        label="Document"
                        class="tw-w-full md:tw-w-[30%]"
                      ></app-list-header>
                      <app-list-header
                        name="created"
                        label="Date"
                        readOnly
                        [sortEnabled]="false"
                        class="tw-w-full md:tw-w-[10%]"
                      ></app-list-header>
                      <app-list-header
                        label="Status"
                        readOnly
                        class="tw-w-full md:tw-w-[30%]"
                      ></app-list-header>
                      <app-list-header
                        label="Open"
                        readOnly
                        class="tw-w-full md:tw-w-[30%]"
                      ></app-list-header>
                    </tr>
                  </ng-container>
                </app-list>

                <ng-template #row let-element>
                  <tr
                    class="app-pt2 app-pb2 tw-flex tw-flex-wrap tw-w-full tw-items-center"
                  >
                    <td class="tw-w-full md:tw-w-[30%]">
                      <app-value
                        [tabIndex]="0"
                        label="Document"
                        [value]="element.fileName"
                        layout="row-xs"
                      ></app-value>
                    </td>
                    <td class="tw-w-full md:tw-w-[10%]">
                      <app-value
                        [tabIndex]="0"
                        label="Date"
                        [value]="element.created | format : 'date'"
                        layout="row-xs"
                      ></app-value>
                    </td>
                    <td
                      *ngIf="element.dcn !== ''"
                      class="tw-w-full md:tw-w-[30%]"
                    >
                      <app-value
                        [tabIndex]="0"
                        [value]="'Complete'"
                        label="Status"
                        layout="row-xs"
                      ></app-value>
                    </td>

                    <td
                      *ngIf="element.dcn === ''"
                      class="tw-w-full md:tw-w-[30%]"
                    >
                      <app-value
                        [tabIndex]="0"
                        [value]="'Pending'"
                        label="Status"
                        layout="row-xs"
                      ></app-value>
                    </td>
                    <!-- Normal document link -->
                    <td
                      *ngIf="element.dcn !== '' && !isEmulating"
                      class="tw-w-full md:tw-w-[30%] OpenDocumentLink"
                    >
                      <app-icon-button
                        icon="open_in_new"
                        label="Open"
                        (click)="openfile(element)"
                        role="button"
                        tabindex="0"
                        (keydown.enter)="openfile(element)"
                        [attr.aria-label]="'Open file ' + element.fileName"
                      ></app-icon-button>
                    </td>
                    <!-- mobile document link -->
                    <td
                      *ngIf="element.dcn !== '' && !isEmulating"
                      class="tw-w-full md:tw-w-[30%] OpenDocumentLinkMobile"
                    >
                      <app-icon-button
                        label="OPEN"
                        role="button"
                        tabindex="0"
                        (click)="openfile(element)"
                        (keydown.enter)="openfile(element)"
                        [ariaLabelText]="'Open file ' + element.fileName"
                      ></app-icon-button>
                    </td>
                  </tr>
                </ng-template>
              </div>
              <img
                src="./assets/images/mileage_rmbsmnt_4.webp"
                width="60%"
                alt=""
              />
            </div>
            <div *ngIf="showForm">
              <app-mileage-details
                #editTrip
                [claim]="claim"
                [mileageData]="this.mileageData"
                [reimbursementDetailData]="
                  reimbursementData.Details[currentTripIndex]
                "
                (onShowSummary)="showSummary()"
              ></app-mileage-details>
              <app-action-row>
                <app-button emphasis="High" right2 (click)="validateInput()"
                  >Next</app-button
                >
                <app-button
                  emphasis="Low"
                  right1
                  (click)="dialog.open(cancelTemplate)"
                  >Cancel</app-button
                >
              </app-action-row>
            </div>
            <div *ngIf="showReimbursementSummary">
              <div *ngIf="!submitSuccess">
                <app-mileage-review-page
                  #summary
                  [mileageData]="this.mileageData"
                  [reimbursementData]="reimbursementData"
                  (onAddTrip)="setTripEdit($event[0])"
                  (onDeleteTrip)="removeTrip($event[0])"
                ></app-mileage-review-page>
              </div>
              <div *ngIf="submitSuccess">
                <app-toast type="Success">
                  {{ getSuccessMsg() }}
                </app-toast>
              </div>

              <app-action-row *ngIf="!submitSuccess">
                <app-button
                  emphasis="High"
                  [loading]="loading"
                  right2
                  (click)="submitReimbursement()"
                  *ngIf="reimbursementData.Details.length > 0"
                  >Submit</app-button
                >
                <app-button
                  emphasis="Low"
                  right1
                  (click)="dialog.open(cancelTemplate)"
                  >Cancel</app-button
                >
              </app-action-row>
            </div>
            <ng-template #cancelTemplate>
              <app-dialog
                [title]="'Confirmation'"
                ariaLabel="Confirmation dialog"
                [confirmLabel]="'Confirm'"
                [cancelLabel]="'Cancel'"
                [closeOnConfirm]="true"
                (confirmEvent)="cancelClosed($event)"
              >
                <div class="app-header-2">Confirmation</div>

                <div>
                  Press Confirm to cancel this mileage reimbursement request.
                </div>
              </app-dialog>
            </ng-template>
            <app-loading-panel [state]="searching ? 'Loading' : 'Loaded'">
            </app-loading-panel>
            <div *ngIf="!searching"></div>
          </div>
        </app-panel>
      </app-section>
    </div>
  `,
  styles: [
    `
      .OpenDocumentLinkMobile {
        display: block;
      }
      .OpenDocumentLink {
        display: none;
      }
      @media (min-width: 700px) {
        .OpenDocumentLink {
          display: block;
        }
        .OpenDocumentLinkMobile {
          display: none;
        }
      }
      .bold {
        font-weight: bold;
      }
      .pdt15 {
        padding-top: 15px;
      }
      .app-header-3 {
        color: black;
      }
      .prevpage {
        color: #007dbc;
        font-weight: bold;
        font-size: 14pt;
      }
      .prevpage:hover {
        text-decoration: none;
        border-bottom: 1px solid #035e81;
      }
      .currentpage {
        color: #171c20 !important;
        opacity: 1 !important;
      }

      .content {
        overflow-y: scroll;
        background-color: #f0f4f7;
      }
    `,
  ],
  providers: [DecimalPipe],
})
export class MileageReimbursementPageComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('pdfContent') pdfContent: ElementRef;
  @ViewChild('editTrip')
  editTripComponent: MileageReimbursementDetailsComponent;
  @ViewChild('summary', { static: false })
  SummaryComponent: MileageReviewPageComponent;

  claimId: string = '';
  claimInfo$ = new BehaviorSubject<ClaimInfo>(null);
  claims: SearchResult[] = null;
  claim: SearchResult = <SearchResult>{};
  showIntro = true;
  showForm = false;
  showReimbursementSummary = false;
  running = false;
  submitSuccess = false;
  previousUrlLink: string;
  previousUrlLabel: string;
  claimConfig: ConfigurationSetting;
  hasReturnToWork: boolean;
  isClaimant: boolean = this.session.user.role === 'HRM' ? true : false;
  showContactPrefs: boolean = this.isClaimant ? true : false;
  hasUploadDocs: boolean;
  historyinfo: HistoryInfo = <HistoryInfo>{};
  notificationCount: string;
  countLoaded: boolean = false;
  unreadConversationMessagesCount: number = 0;
  mileageData: MileageDetail;
  maxBadgeCount = 5;
  searching: boolean = true;
  billingAddressName: string;
  billingAddressLine1: string;
  billingAddressCSZ: string;
  examinerLabel: string;
  confirmationNumber: string = '';
  reimbursementData: UserMileageReimbursement = <UserMileageReimbursement>{
    Details: [],
  };
  isEmulating: boolean = false;
  loading = false;

  getSuccessMsg() {
    const aftersubmitSuccessMessage =
      'Thank you for submitting your request. We have forwarded this to your ' +
      this.examinerLabel +
      ' to review and process according to any state-specific guidelines. ';
    const cNum =
      this.confirmationNumber !== ''
        ? 'Confirmation Number: [' + this.confirmationNumber + ']'
        : '';
    return aftersubmitSuccessMessage + cNum;
  }
  currentTripIndex = 0;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public mileageService: MileageService,
    public progressService: ProgressService,
    public claimService: ClaimService,
    public session: Session,
    public routerService: RouterService,
    private configurationService: ConfigurationService,
    public userdetails: UserDetails,
    public alertService: ActivityService,
    private cd: ChangeDetectorRef,
    public claimConfigService: ConfigurationService,
    public random: Random,
    public inpageAliasService: InpageAliasService,
    private decimalPipe: DecimalPipe,
    private enumalatorService: emulator
  ) {
    super();
  }

  async ngOnInit() {
    this.subs.sink = this.route.queryParams.subscribe((p) =>
      this.loadClaimFromUrl()
    );
    this.subs.sink = this.alertService.notificationCount.subscribe(
      (count: string) => {
        this.notificationCount = count;
        this.countLoaded = true;
        this.cd.detectChanges();
      }
    );

    this.searching = true;
    this.examinerLabel = await this.inpageAliasService.replaceAliases(
      'examinerSingular',
      this.configurationService.GetConfig.configFeatures.aliases
    );
    if (
      this.session.user?.emulatorContext != null &&
      this.session.user?.emulatorContext != undefined
    ) {
      this.isEmulating = true;
    }
  }

  async submitReimbursement() {
    this.loading = true;

    if (!this.SummaryComponent.validateData()) {
      return;
    }
    this.enumalatorService.showAuditorInfo();

    const noteRequest = new NoteRequest();
    noteRequest.contractNumber = this.claim.client;
    noteRequest.claimUid = this.claim.claimId;
    noteRequest.uid = this.claim.claimId;
    noteRequest.examiner = this.claim.examinerName;
    noteRequest.createdBy = '';
    noteRequest.auditNoteFlag = true;
    noteRequest.user_ID = '';
    noteRequest.user_Name = '';
    noteRequest.needDiary = false;
    noteRequest.fileNumber = this.claim.claimNumber;
    noteRequest.noteMessage = '';
    noteRequest.noteType = '';
    noteRequest.noteLevel = '';

    const submitMileageRequest = new SubmitMileageRequest();
    submitMileageRequest.jurisNoteRequest = noteRequest;
    submitMileageRequest.lob = this.claim.lob;
    submitMileageRequest.source = this.claim.source;
    submitMileageRequest.vendorId = this.claim.vendorId;
    submitMileageRequest.procUnit = this.claim.procUnit;
    submitMileageRequest.mileageReimbursementDetail = this.mileageData;
    submitMileageRequest.mileageReimbursementDetail.roundTrip = 'Y';
    submitMileageRequest.userMileageReimbursement = this.reimbursementData;
    submitMileageRequest.signature = this.SummaryComponent.getSignature();

    this.confirmationNumber = await (
      await this.mileageService.submitMileage(submitMileageRequest)
    ).confirmationNumber;
    this.progressService.hide();
    this.submitSuccess = true;
    this.loading = false;
  }

  convertToCents(rate: string): string {
    const cents = parseFloat(rate) * 100;
    return this.decimalPipe.transform(cents, '1.2-2');
  }

  showNext() {
    this.getNewReimbursementDetail();
    if (this.editTripComponent) {
      this.editTripComponent.reimbursementDetailData =
        this.getReimbursementDetail();
    }
    this.showIntro = false;
    this.showForm = true;
  }
  validateInput() {
    this.editTripComponent.populateReimbursementData();
  }
  getReimbursementDetail = (): UserMileageReimbursementDetail => {
    return this.reimbursementData.Details[this.currentTripIndex];
  };

  showSummary() {
    this.showReimbursementSummary = true;
    this.showForm = false;
  }

  getNewReimbursementDetail() {
    this.reimbursementData.Details.push(<UserMileageReimbursementDetail>{
      startAddr1: this.mileageData.claimAddr1,
      startAddr2: this.mileageData.claimAddr2,
      startCity: this.mileageData.claimCity,
      startState: this.mileageData.claimState,
      startZip: this.mileageData.claimZip,
      Expenses: [],
    });

    this.currentTripIndex = this.reimbursementData.Details.length - 1;
  }

  private async loadClaimFromUrl() {
    const claimId = this.route.snapshot.queryParamMap.get('claimId');
    this.historyinfo.claimId = claimId;
    const source = this.route.snapshot.queryParamMap.get('source');
    if (claimId) {
      const claim = await this.claimService.getSummary(source, claimId);

      this.setClaim(claim);

      await this.GetMileageData();
      const defaultAddress = await this.claimConfigService.GetImportContacts(
        this.claim
      );
      this.billingAddressName = this.mileageData.billingAddressName
        ? this.mileageData.billingAddressName
        : 'Sedgwick';
      this.billingAddressLine1 = this.mileageData.billingAddressName
        ? this.mileageData.billingAddressName
        : defaultAddress.listExaminer[0].address.street1.split(',')[0];
      this.billingAddressCSZ = this.mileageData.billingAddressName
        ? this.mileageData.billingAddressName
        : defaultAddress.listExaminer[0].address.street1.split(',')[1];
      this.searching = false;
    }
  }

  private async setClaim(claim: SearchResult) {
    if (claim === null) {
      return;
    }
    this.claimId = claim.claimId;
    this.claimInfo$.next(claim);
    this.claim = claim;
    this.claimConfig = await this.configurationService.getClaimConfiguration(
      claim
    );
  }

  async navigateToClaim() {
    this.router.navigate(['claims'], {
      queryParams: {
        claimId: this.claim.claimId,
        source: this.claim.source,
      },
    });
  }

  private setBreadcrumb(prevUrl: string) {
    this.previousUrlLink = prevUrl;
    this.previousUrlLabel =
      prevUrl === '/search'
        ? 'Search'
        : prevUrl === '/history'
        ? 'History'
        : prevUrl === '/watchlist'
        ? 'Watch List'
        : 'Home';

    if (this.previousUrlLabel === 'Home') {
      this.previousUrlLink = '';
    }
  }

  GetMileageData = async () => {
    if (this.claim) {
      const data = await this.mileageService.getMileageData(
        this.claim.source,
        this.claim.claimId,
        this.claim.lob
      );
      this.mileageData = data;
    }
  };

  openfile(element: any) {
    window.open(`/api/auth/Saml/${element.dcn}/ViewSirDocument`, '_blank');
  }

  getFormattedDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return year + month + day;
  }

  removeTrip = (index: any) => {
    this.reimbursementData.Details.splice(index, 1);
    this.SummaryComponent.reimbursementData = this.reimbursementData;
    this.SummaryComponent.ngOnInit();
  };

  setTripEdit = (index: any) => {
    if (index === -1) {
      this.reimbursementData.Details.push(<UserMileageReimbursementDetail>{
        startAddr1: this.mileageData.claimAddr1,
        startAddr2: this.mileageData.claimAddr2,
        startCity: this.mileageData.claimCity,
        startState: this.mileageData.claimState,
        startZip: this.mileageData.claimZip,
        Expenses: [],
      });
      this.currentTripIndex = this.reimbursementData.Details.length - 1;
    } else {
      this.currentTripIndex = index;
    }
    this.showReimbursementSummary = false;
    this.showForm = true;
  };

  cancelClosed(event: any) {
    const params = getUrlParams();
    if (event === 'confirm') {
      this.router.navigate(['/claims'], {
        queryParams: {
          source: params.source,
          claimId: params.claimId,
        },
      });
    }
  }
}
