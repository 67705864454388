import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public show(
    message: string,
    type: SnackbarType = SnackbarType.Info,
    durationMs: number = 5000
  ): void {
    let style: string[];
    switch (type) {
      case SnackbarType.Error:
        style = ['snackbar-error'];
        break;
      case SnackbarType.Success:
        style = ['snackbar-success'];
        break;
      case SnackbarType.Warning:
        style = ['snackbar-warning'];
        break;
      case SnackbarType.Info:
        style = ['snackbar-info'];
        break;
      default:
        style = [];
        break;
    }

    this.snackBar.open(message, null, {
      duration: durationMs,
      panelClass: style,
    });
  }
}

export enum SnackbarType {
  Info,
  Error,
  Success,
  Warning,
}
